<script lang="ts">
    import { onMount, onDestroy } from 'svelte';
    import { ClassName } from '../types';
    import SearchFilter from './SearchFilter.svelte';

    export let serviceProviderProps;
    export let className: ClassName;
	export let subEmployees;

    const { resource, translations, kategorieID } = serviceProviderProps;
    const { Name: employee, ZugehoerigkeitID: zugehoerigkeitID, Zugehoerigkeit: zugehoerigkeit } = resource;

	let searchFilterProps: any;
    
	$: searchFilterProps = {
        zugehoerigkeitID,
        zugehoerigkeit,
        kategorieID,
        className,
        translations,
		subEmployees
    };

    let displaySearchFilter = false;
    let searchFilterElement: any;


    function toggleSearchFilterDisplay(event: Event) {
        event.stopPropagation();

        if (subEmployees.length > 0) {
            displaySearchFilter = true;
        }
    };

    function handleClickOutside(event: Event) {
        if (searchFilterElement && !searchFilterElement.contains(event.target)) {
            displaySearchFilter = false;
        }
    };

    onMount(() => {
        document.addEventListener('click', handleClickOutside);
    });

    onDestroy(() => {
        document.removeEventListener('click', handleClickOutside);
    });

    let underlineClass: string;
    let pointerClass: string;

    $: {
        underlineClass = subEmployees.length > 0 ? 'hover-underline' : '';
    	pointerClass = subEmployees.length > 0 ? 'dienstleister-pointer': '';
    };

</script>

{#if !displaySearchFilter}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div on:click={toggleSearchFilterDisplay} class="status-holder status-holder--text {pointerClass}">
        <span class={className + `text nowrap ${underlineClass}`}>{employee}</span>
    </div>
{:else}
    <SearchFilter {searchFilterProps} />
{/if}