<script>
	import { isSelectAllBtnDisabledStore } from '../TenderEmail/stores/stores';
	import debounce from 'debounce';
	import Edit from 'icon:edit';
	import Filter from 'icon:filter';
	import Plus from 'icon:plus';
	import Save from 'icon:save';
	import Star from 'icon:star';
	import TimesCircle from 'icon:times-circle';
	import jquery from 'jquery';
	import { createEventDispatcher } from 'svelte';
	import { cubicInOut } from 'svelte/easing';
	import { slide } from 'svelte/transition';
	import translate from '../../../i18n';
	import { preventSubmit } from '../../actions';
	import Button from '../../components/Button';
	import Checkbox from '../../components/Checkbox';
	import IconButton from '../../components/IconButton';
	import DeleteButton from './DeleteButton.svelte';
	import { addFilterComponent } from './helpers';

	export let opened;
	export let filters;
	export let postParams;
	export let translations;
	export let tender;

	let favoriteBubble;
	let favoriteName;
	let favoriteNameInput;
	let favoriteNameState;

	let { availableFilters, defaultFilters, favoriteFilters } = filters;
	let extraFilters = filters.extraFilters.map(addFilterComponent);
	const dispatch = createEventDispatcher();

	export const setFilters = async ({ default: dFilters, extra: eFilters }) => {
		extraFilters = [];

		updateFilters({
			data: { command: postParams.command.reset },
			updateResults: false,
		});
		updateFilters.flush();

		if (dFilters) {
			defaultFilters = defaultFilters.map(filter => {
				filter.checked = dFilters.includes(filter.name);
				return filter;
			});
		}

		if (eFilters) {
			await Promise.all(eFilters.map(({ type, props }) => addFilter(type, props)));
		}

		updateFilters({ updateFilters: true });
		updateFilters.flush();
	};

	export const getFilters = () => ({
		default: defaultFilters,
		extra: extraFilters,
	});

	const addFilter = async (type, props) => {
		const { filter, success } = await jquery.post(location.href, {
			silent: true,
			package: 'TerminEmployeeRequest',
			ajax: 'addFilter',
			type,
		});

		if (success) {
			if (props) {
				Object.entries(props).forEach(([key, value]) => {
					filter[key] = { ...filter[key], ...value };
				});
			}

			extraFilters = [...extraFilters, addFilterComponent(filter)];
		}
	};

	const updateFilters = debounce(
		async ({
			data = {},
			updateFilters: needToUpdateFilters = false,
			updateResults: needToUpdateResults = true,
		} = {}) => {
			for (const { name, checked } of defaultFilters) {
				if (checked) {
					data[name] = 1;
				}
			}

			const uids = [];
			const names = [];
			for (const { instance } of extraFilters) {
				if (instance) {
					const { uid, name, values } = instance.getData();
					if (values.length) {
						uids.push(uid);
						names.push(name);

						for (const [name, value] of values) {
							data[uid + name] = value;
						}
					}
				}
			}

			const { filters, success } = await jquery.post(location.href, {
				silent: true,
				package: 'TerminEmployeeRequest',
				ajax: 'updateFilters',
				[postParams.uids]: uids,
				[postParams.filters]: names,
				...data,
			});

			if (success) {
				if (needToUpdateFilters) {
					({ availableFilters, defaultFilters, favoriteFilters } = filters);
					extraFilters = filters.extraFilters.map(addFilterComponent);

					if (tender) {
						defaultFilters = defaultFilters.filter(filter => filter.label !== 'Fahrzeuge' && filter.label !== 'Räume');
					}
				} else if (!defaultFilters.some(({ checked }) => checked)) {
					defaultFilters[0].checked = true;
				}

				if (needToUpdateResults) {
					dispatch('update');
				}
			}
		},
		100
	);

	const removeFilter =  ({ instance }) => {
		if(tender) {
			isSelectAllBtnDisabledStore.set(true);
		}
		extraFilters = extraFilters.filter(filter => filter.instance !== instance);
		updateFilters();
		updateFilters.flush();
	};

	const useFavorite = id => {
		updateFilters({
			data: { [postParams.filterFavorite]: id },
			updateFilters: true,
		});
		updateFilters.flush();
	};

	const updateFavorite = () => {
		if (favoriteNameState === 'edit') {
			if (!(favoriteName = favoriteName.trim())) {
				if (favoriteNameInput && favoriteNameInput.reportValidity) {
					setTimeout(() => {
						favoriteNameInput.reportValidity();
					}, 0);
				}

				return;
			}

			favoriteFilters = favoriteFilters.map(filter => (filter.active ? { ...filter, name: favoriteName } : filter));
		}

		if (favoriteBubble && favoriteBubble.bubble) {
			favoriteBubble.bubble.close();
		}

		updateFilters({
			data: {
				command: postParams.command.save,
				[postParams.filterFavoriteSave]: postParams.filterFavoriteID,
				[postParams.filterFavoriteFunction]: '',
				[postParams.filterFavoriteMode]: postParams.filterFavoriteModeRequest,
				[postParams.filterFavoriteName]: favoriteFilters.find(({ active }) => active).name,
			},
			updateResults: false,
		});
		updateFilters.flush();
	};

	const deleteFavorite = () => {
		updateFilters({
			data: {
				command: postParams.command.delete,
				[postParams.filterFavoriteDelete]: postParams.filterFavoriteID,
				[postParams.filterFavoriteFunction]: '',
				[postParams.filterFavoriteMode]: postParams.filterFavoriteModeRequest,
			},
			updateFilters: true,
		});
		updateFilters.flush();
	};

	const saveAsFavorite = event => {
		if (!(favoriteName = favoriteName.trim())) {
			if (favoriteNameInput && favoriteNameInput.reportValidity) {
				favoriteNameInput.reportValidity();
			}

			return;
		}

		if (favoriteBubble && favoriteBubble.bubble) {
			favoriteBubble.bubble.close();
		}

		updateFilters({
			data: {
				command: postParams.command.save,
				[postParams.filterFavoriteSave]: postParams.filterFavoriteID,
				[postParams.filterFavoriteFunction]: '',
				[postParams.filterFavoriteMode]: postParams.filterFavoriteModeRequest,
				[postParams.filterFavoriteName]: favoriteName,
				[postParams.filterFavoriteSaveType]: postParams.filterFavoriteSaveTypeNew,
			},
			updateFilters: true,
			updateResults: false,
		});
	};

	const resetFilters = () => {
		updateFilters({
			data: { command: postParams.command.reset },
			updateFilters: true,
		});
	};
</script>

<div class="bubble menu" id="employee-request__add-filter-bubble">
	<ul>
		<li class="headline">{translations.filter}</li>
		{#each availableFilters.filters as { type, name }}
			<li>
				<Button
					class="close"
					link
					on:click={() => {
						addFilter(type);
					}}
				>
					{name}
				</Button>
			</li>
		{/each}

		<li class="headline">{translations.sorter}</li>
		{#each availableFilters.sorters as { type, name }}
			<li>
				<Button
					class="close"
					link
					on:click={() => {
						addFilter(type);
					}}
				>
					{name}
				</Button>
			</li>
		{/each}
	</ul>
</div>

<div
	class="bubble menu"
	id="employee-request__favorites-bubble"
	bind:this={favoriteBubble}
	on:bubble-close={() => {
		favoriteNameState = undefined;
	}}
>
	<ul>
		{#each favoriteFilters as { active, id, name, editable }}
			<li class:active>
				{#if active && favoriteNameState === 'edit'}
					<!-- svelte-ignore a11y-autofocus -->
					<input
						autofocus
						class="text-field"
						required
						type="text"
						bind:this={favoriteNameInput}
						bind:value={favoriteName}
						use:preventSubmit={updateFavorite}
					/>

					<IconButton
						icon={TimesCircle}
						small
						title={translate('cancel')}
						on:click={() => {
							favoriteNameState = undefined;
						}}
					/>
				{:else}
					<Button
						class="close"
						link
						on:click={() => {
							useFavorite(id);
						}}
					>
						{name}
					</Button>
				{/if}

				{#if active && editable}
					{#if favoriteNameState !== 'edit'}
						<IconButton
							icon={Edit}
							small
							title={translations.favoriteEdit}
							on:click={() => {
								favoriteName = name;
								favoriteNameState = 'edit';
							}}
						/>
					{/if}

					<IconButton icon={Save} small title={translate('save')} on:click={updateFavorite} />
					<DeleteButton class="close" on:click={deleteFavorite} />
				{/if}
			</li>
		{:else}
			<li class="headline">{translations.favoriteNo}</li>
		{/each}

		<li>
			{#if favoriteNameState === 'add'}
				<!-- svelte-ignore a11y-autofocus -->
				<input
					autofocus
					class="text-field"
					required
					type="text"
					bind:this={favoriteNameInput}
					bind:value={favoriteName}
					use:preventSubmit={saveAsFavorite}
				/>

				<IconButton
					icon={TimesCircle}
					small
					title={translate('cancel')}
					on:click={() => {
						favoriteNameState = undefined;
					}}
				/>

				<IconButton icon={Save} small title={translate('save')} on:click={saveAsFavorite} />
			{:else}
				<Button
					link
					on:click={() => {
						favoriteName = '';
						favoriteNameState = 'add';
					}}
				>
					{translations.favoriteSaveAs}
				</Button>
			{/if}
		</li>
	</ul>
</div>

{#if opened}
	<div class="filters" transition:slide|global={{ duration: 200, easing: cubicInOut }}>
		<div class="actions">
			<IconButton icon={TimesCircle} small title={translations.reset} on:click={resetFilters} />

			<IconButton
				data-bubble="employee-request__favorites-bubble"
				icon={Star}
				small
				title={translations.filterFavorites}
			/>

			<IconButton
				class="employee-request__add-filter"
				data-bubble="employee-request__add-filter-bubble"
				icon={Filter}
				small
				title={translations.filterAdd}
			>
				<Plus />
			</IconButton>
		</div>

		{#each defaultFilters as filter}
			<Checkbox bind:checked={filter.checked} label={filter.label} on:change={updateFilters} />
		{/each}

		{#each extraFilters as filter (filter.props.filter.uid)}
			<svelte:component
				this={filter.component}
				bind:this={filter.instance}
				{...filter.props}
				on:remove={() => removeFilter(filter)}
				on:update={updateFilters}
			/>
		{/each}
	</div>
{/if}
