<script>
	import { createEventDispatcher } from 'svelte';
	import { cubicInOut } from 'svelte/easing';
	import { slide } from 'svelte/transition';
	import Select from '../../../components/Select';
	import Checkbox from '../../../components/Checkbox';
	import DeleteButton from '../DeleteButton.svelte';

	export let filter;
	export let name;
	export let department;
	export let subdepartments;

	export const getData = () => ({
		...filter,
		values:
			department.selected == 0
				? []
				: [
						[department.name, department.selected],
						[subdepartments.name, subdepartments.checked, subdepartments.label],
				  ],
	});

	const dispatch = createEventDispatcher();

	const update = () => dispatch('update');
	const remove = () => dispatch('remove');
</script>

<div class="filter filter--department" transition:slide={{ duration: 200, easing: cubicInOut }}>
	<div>
		<strong>{name}:</strong>

		<Select bind:value={department.selected} options={department.options} on:change={update} />

		<DeleteButton on:click={remove} />
	</div>
	<div>
		<Checkbox bind:checked={subdepartments.checked} label={subdepartments.label} on:change={update} />
	</div>
</div>
