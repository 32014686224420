import { sendFormData } from '../legacy';
import { onInit } from '../utils/subscriptions';

const INTERVAL = 60_000;

onInit(() => {
	const updateAllBadges = () => {
		setTimeout(updateAllBadges, INTERVAL);

		const formData = new FormData();
		formData.set('ajax', 'true');
		formData.set('package', 'Badge');
		formData.set('action', 'getAll');
		sendFormData(null, null, '/', 'POST', formData);
	};

	setTimeout(updateAllBadges, INTERVAL);
});
