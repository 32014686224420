import { backendData, requestStatus2Action } from "./utilsStaffplan";
import { jobResourcesJS } from "../stores/stores";
import { BackendObject, JobResources, Job , NewObjectType} from "../types";

let jobs: any;
let kategorieid: any;
let terminid;
let anfragenvariante: string;
let jobResources: any;


jobResourcesJS.subscribe(value => {
        jobs = value;
});

const getJobResources = (terminid: number) => {
    
    jobs.forEach((job: Job) => {
        if (job.ID === terminid) {
            jobResources = job.Resources;
        }
    });
};

const getRowCategoryId = (row: HTMLTableRowElement) => {
    let previousElement = row.previousElementSibling;

    while (previousElement) {
        if (previousElement.classList.contains('headline')) {
            kategorieid = previousElement.id;
            kategorieid = Math.floor(kategorieid);
            break;
        } else {
            kategorieid = null;
        }
        previousElement = previousElement.previousElementSibling;
    }
};

const addToBackendData = (
    terminid: number,
    zugehoerigkeit: string,
    zugehoerigkeitid: number,
    kategorieid: number,
    check: boolean,
    jobResources: JobResources[],
    data: BackendObject[],
    freierMitarbeiter: string | null,
    subEmployeeId: number | null
) => {
    // Still needs to be edited for service providers and freelancers
    const existingIndex = data.findIndex(
        (item: any) => item.zugehoerigkeitid === zugehoerigkeitid && item.terminid === terminid && item.zugehoerigkeit === zugehoerigkeit
    );

    if (existingIndex !== -1) {
        data.splice(existingIndex, 1); // If same resource already exists in data, remove it
    }
    const resourceWithID = jobResources.find(
        (resource: any) => resource.ZugehoerigkeitID === zugehoerigkeitid && resource.Zugehoerigkeit === zugehoerigkeit && resource.hasOwnProperty('ID')
    );
    const temaid = resourceWithID ? resourceWithID.ID : 'neu';

     data.forEach((obj: any) => {
        if (obj.zugehoerigkeitid === zugehoerigkeitid && obj.zugehoerigkeit === zugehoerigkeit && obj.kategorieid != kategorieid) {
          obj.kategorieid = kategorieid;
        }
      });

    anfragenvariante = requestStatus2Action(anfragenvariante);

    const newObject: NewObjectType = {
        zugehoerigkeit,
        zugehoerigkeitid,
        anfragenvariante,
        terminid,
        kategorieid,
        check,
        temaid,
        SubEmployeeID: subEmployeeId,
    };

    if (freierMitarbeiter !== null) {
        newObject.freiermitarbeiter = freierMitarbeiter;
    }
    if (subEmployeeId !== null) {
        newObject.SubEmployeeID = subEmployeeId;
    }
    data.push(newObject);
};


// Logic for : dragging resource into another category with the dragndrop icon
export function moveResourceToNewCategory (row: any) {
    let zugehoerigkeit = row.dataset.zugehoerigkeit;
    let zugehoerigkeitid = row.dataset.zugehoerigkeitid;
    zugehoerigkeitid = Math.floor(zugehoerigkeitid);
    let check = true;
    let freierMitarbeiter = row.dataset.FreierMitarbeiter;
    let subEmployeeId = row.dataset.SubEmployeeID;


    backendData.forEach((obj: any) => {
        if (obj.zugehoerigkeitid == zugehoerigkeitid && obj.zugehoerigkeit == zugehoerigkeit) {
            getRowCategoryId(row);
          obj.kategorieid = kategorieid;
        }
      });
  

    Array.from(row.children).forEach((child) => {
        if ((child as HTMLElement).hasAttribute('data-anfragenvariante')) {
            const childElement = child as HTMLElement;
            terminid = childElement.dataset.terminid;
            terminid = Math.floor(Number(terminid));
            anfragenvariante = childElement.dataset.anfragenvariante;
            getJobResources(terminid);
            getRowCategoryId(row);
        
            addToBackendData(
                terminid,
                zugehoerigkeit,
                zugehoerigkeitid,
                kategorieid,
                check,
                jobResources,
                backendData,
                freierMitarbeiter,
                subEmployeeId
            );
        }
    });
}