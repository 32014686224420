import { onInit } from '../utils/subscriptions';
import supportsPassiveEventListener from '../utils/supports-passive-event-listener';

const CLASS = 'main-header';
const CLASS_SHADOW = 'main-header--shadow';

const { documentElement: scrollContainer } = document;

let classList: DOMTokenList;
let shadow = false;

const checkShadow = () => {
	if (scrollContainer.scrollTop) {
		if (!shadow) {
			shadow = true;
			classList.add(CLASS_SHADOW);
		}
	} else if (shadow) {
		shadow = false;
		classList.remove(CLASS_SHADOW);
	}
};

onInit(() => {
	({ classList } = document.querySelector(`.${CLASS}`)!);

	checkShadow();
	document.addEventListener('scroll', checkShadow, supportsPassiveEventListener ? { passive: true } : false);
});
