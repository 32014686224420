import { onNavigate } from '../utils/subscriptions';

const CLASS = 'tabview';
const CLASS_ACTIVE = 'active';

onNavigate(({ ajax, pack, mode }) => {
	if ( pack !== null && pack !== undefined )
		pack = pack.replace(/["\\]/g, '\\$&');
	if (ajax && pack) {
		let element = document.querySelector(`div.${CLASS} *[data-ajax="${ajax}"][data-package="${pack}"][data-mode="${mode}"]`);
		if (!element) {
			element = document.querySelector(`div.${CLASS} *[data-ajax="${ajax}"][data-package="${pack}"]`);
		}
		if (element) {
			for (const item of element.closest(`div.${CLASS}`)!.querySelectorAll(`.${CLASS_ACTIVE}`)) {
				item.classList.remove(CLASS_ACTIVE);
			}

			element.classList.add(CLASS_ACTIVE);
		}
	}
});
