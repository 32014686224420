<svelte:options immutable />

<script>
	import { createResourceChip } from '../TenderEmail/utils/chipElementCreator';
	import CertificateMissing from 'icon:certificate-missing';
	import NoAccess from 'icon:no-access';
	import { createEventDispatcher } from 'svelte';
	import Button from '../../components/Button';
	import DeleteButton from './DeleteButton.svelte';
	import { onMount } from 'svelte';
	const dispatch = createEventDispatcher();

	export let free = false;

	export let blocked = undefined;
	export let holiday = undefined;
	export const requestedHoliday = undefined;
	export let id = 0;
	export let image;
	export let missingCertificates = undefined;
	export let name;
	export let overlapping = undefined;
	export let personnelleasing = undefined;
	export let expiringContract = undefined;
    export let shortTermEmployment = undefined;
	export let requestable = false;
	export let employeePhone = undefined;
	export let vehicleSeats = undefined;
	export let vehiclePayload = undefined;
	export let roomNumberPeople = undefined;
	export let skills = undefined;
	export let statementDefInfo = undefined;
	export let type;
	export let translations;
	export let showPhoneNumber;
	export let subcontractorNameDiv = undefined;

	export let tender;
	export let employeesWithTenderReceivedArray = [];

	function onDragStart(event) {
		const data = { name, id };
        if (overlapping?.icon) {
            data.icon = overlapping.icon;
        };
        if (overlapping?.type) {
            data.type = overlapping.type;
        };

        event.dataTransfer.setData('text/plain', JSON.stringify(data));
	}
    function onClick(event) {
        createResourceChip(name, parseInt(id), employeesWithTenderReceivedArray, overlapping?.icon || null, 
        overlapping?.type || null);
    }
</script>

<div
    class={ tender ? 'employee-drag' : 'result employee-drag' }
    draggable={ tender ? true : (requestable || undefined) }
    on:dragstart={tender ? onDragStart : null}
    on:click={tender ? onClick : null}
    on:keypress={tender ? onClick : null}
    role="button"
    tabindex="0"
    disabled={!requestable || undefined}
    data-ressourceid={id}
    data-ressourcetype={type}
    data-ressourcefree={free || undefined}
    data-ressourcename={name}
    data-ressourceemployeephone={free ? employeePhone : undefined}
    data-ressourcevehicleseats={free ? vehicleSeats : undefined}
    data-ressourcevehiclepayload={free ? vehiclePayload : undefined}
    data-ressourceroomnumberpeople={free ? roomNumberPeople : undefined}
    data-ressourceoverlapping={overlapping ? true : false}
    data-overlap-icon={overlapping && overlapping.icon ? overlapping.icon : undefined}
    data-overlap-type={overlapping && overlapping.type ? overlapping.type : undefined}
>
    <div class="info">
        <div class="image" style="background-image: url({image})" />

        {#if subcontractorNameDiv}
            {@html subcontractorNameDiv}
        {:else if !employeePhone || !showPhoneNumber}
            <strong>{name}</strong>
        {:else}
            <strong>{name} ({employeePhone})</strong>
        {/if}

        {#if blocked}
            <NoAccess aria-label={blocked} data-hovertitle={blocked} />
        {/if}

        {#if overlapping}
            {@html overlapping.button}
            {@html overlapping.bubble}
        {/if}

        {#if personnelleasing}
            {@html personnelleasing.icon}
        {/if}

        {#if expiringContract}
            {@html expiringContract.icon}
        {/if}

        {#if shortTermEmployment}
            {@html shortTermEmployment.icon}
        {/if}

        {#if free}
            <DeleteButton on:click={() => dispatch('delete')} />
        {/if}

        {#if missingCertificates}
            <CertificateMissing
                aria-label={missingCertificates.title}
                data-hover-bubble={missingCertificates.bubbleId}
                data-hover-time="100"
            />

            <div class="bubble menu" data-no-trap="true" id={missingCertificates.bubbleId}>
                <ul>
                    <li class="headline">{missingCertificates.title}</li>

                    {#each missingCertificates.certificates as certificate}
                        <li>
                            <p>{certificate}</p>
                        </li>
                    {/each}
                </ul>
            </div>
        {/if}

        {#if holiday}
            {@html holiday}
        {/if}

        {#if statementDefInfo}
            {@html statementDefInfo.button}
            {@html statementDefInfo.bubble}
        {/if}
    </div>

    <table>
        {#if vehicleSeats}
            <tr>
                <td>{translations.vehicleSeats}</td>
                <td>{vehicleSeats} ({translations.inclDriver})</td>
            </tr>
        {/if}

        {#if vehiclePayload}
            <tr>
                <td>{translations.vehiclePayload}</td>
                <td>{translations.maxTons.replace('{0}', vehiclePayload)}</td>
            </tr>
        {/if}

        {#if roomNumberPeople}
            <tr>
                <td>{translations.roomNumberPeople}</td>
                <td
                    >{parseInt(roomNumberPeople) == 1
                        ? translations.numPeopleOne.replace('{0}', roomNumberPeople)
                        : translations.numPeopleMore.replace('{0}', roomNumberPeople)}</td
                >
            </tr>
        {/if}

        {#if skills}
            {#each skills[1] as skill, i}
                <tr class={i > 2 ? `employee-${id}-hidden-skills hidden` : ''}>
                    <td>
                        {#if i === 0}
                            {skills[0]}
                        {:else if i === 2 && skills[1].length > 3}
                            <Button link class="more-or-less" data-toggleclass="employee-{id}-hidden-skills">
                                <span class="employee-{id}-hidden-skills">{translations.more}</span>
                                <span class="employee-{id}-hidden-skills hidden">{translations.less}</span>
                            </Button>
                        {/if}
                    </td>

                    <td>
                        <span class="skill-name">{skill.name}</span>

                        {#if skill.level}
                            {@html '&lowast;'.repeat(skill.level)}
                        {/if}
                    </td>

                    {#if skill.wage}
                        <td>
                            {@html skill.wage}
                        </td>
                    {/if}
                </tr>
            {/each}
        {/if}
    </table>
</div>