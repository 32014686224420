import $ from 'jquery';
import { addRequestableModifier } from '../EmployeeRequest';
import { EmployeeType } from '../types';
import type { Event, Events } from './types';

const request = (action: string, params: Record<string, any>) =>
	$.post(location.href, {
		silent: true,
		package: 'TerminSeiteUntertermine',
		ajax: action,
		...params,
	});

export const getEvent = async (eventId: number): Promise<Event> => (await request('getEvent', { eventId })).event;

export const getPastSubEvents = async ({
	eventId,
	start,
}: {
	eventId?: number;
	start?: number;
}): Promise<{ events: Events; pastEvents: number }> => request('getPastSubEvents', { eventId, start });

export const getSubEvents = async ({
	eventId,
	start,
}: {
	eventId?: number;
	start?: number;
}): Promise<{ events: Events; moreEvents: number; pastEvents: number; loadedPast: number }> =>
	request('getSubEvents', { eventId, start });

export const addToPath = <T extends Record<string, any> & { path?: number[] }>({ path, ...props }: T, id: number) => ({
	...props,
	path: path ? [id, ...path] : [id],
});

export const mergeEvent = (events: Events, eventId: number, path: number[], updater: (event: Event) => Event) => {
	let temp = events;
	if (path) {
		for (const id of path) {
			const child = temp.find(event => event.id === id);
			if (!child || !child.subEvents) {
				return events;
			}

			temp = child.subEvents;
		}
	}

	const index = temp.findIndex(({ id }) => id === eventId);
	if (index !== -1) {
		temp[index] = updater(temp[index]);
	}

	return events;
};

type Employee = { type: EmployeeType; id: number; name: string };

const getAllEmployees = (events: Events) => {
	const result: Map<string, Employee> = new Map();

	for (const { categories, subEvents } of events) {
		if (categories) {
			for (const { employees } of categories) {
				for (const { entityType, entityId, name } of employees) {
					result.set(`${entityType}-${entityId}-${name}`, { type: entityType, id: entityId, name });
				}
			}
		}

		if (subEvents) {
			for (const [key, value] of getAllEmployees(subEvents)) {
				result.set(key, value);
			}
		}
	}

	return result;
};

const isEmployeeInEvent = ({ categories }: Event, { type, id, name }: Employee) => {
	if ( !categories )
		return false;
		
	for (const { employees } of categories) {
		for (const { entityType, entityId, name: entityName } of employees) {
			if (id === 0) {
				if (entityId === 0 && entityName === name) {
					return true;
				}
			} else if (entityType === type && entityId === id) {
				return true;
			}
		}
	}

	return false;
};

const isEmployeeInAllEvents = (events: Events, employee: Employee): boolean => {
	for (const event of events) {
		if (!isEmployeeInEvent(event, employee)) {
			return false;
		}

		if (event.subEvents && !isEmployeeInAllEvents(event.subEvents, employee)) {
			return false;
		}
	}

	return true;
};

let requestableModifier: { updated(): void; delete(): void };
let employees: Employee[] = [];

export const updateRequestableModifier = (events: Events) => {
	const temp = getAllEmployees(events);
	for (const [key, employee] of temp) {
		if (employee.type == 'D' || !isEmployeeInAllEvents(events, employee)) {
			temp.delete(key);
		}
	}

	employees = Array.from(temp.values());

	if (requestableModifier) {
		requestableModifier.updated();
	} else {
		requestableModifier = addRequestableModifier(
			({ id, type, name }) =>
				employees.findIndex(
					id === 0
						? employee => employee.id === 0 && employee.name === name
						: employee => employee.id === id && employee.type === type
				) === -1
		);
	}
};

export const deleteRequestableModifier = () => {
	employees = [];
	if (requestableModifier) {
		requestableModifier.delete();
		requestableModifier = undefined;
	}
};

export const unique = (value: any, index: any, self: any) => {
	return self.indexOf(value) === index
}