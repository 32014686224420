<script>
	let className = undefined;
	export { className as class };
	export let title = undefined;

	$: rest = $$restProps;
</script>

<svg class="icon icon--no-access{className ? ` ${className}`: ''}" focusable="false" role="img" {...rest} viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">{#if title}<title>{title}</title>{/if}
<circle id="Oval" cx="125" cy="125" r="125"></circle>
<rect id="Rectangle" stroke-width="1" fill="#FFFFFF" x="35.5" y="105.5" width="180" height="39"></rect>
</svg>