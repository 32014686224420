import { Translations } from './types';

const translations: Translations =
	typeof window.__CREW_BRAIN__ !== 'undefined' ? window.__CREW_BRAIN__.translations : {};

const translate = (key: string, ...args): string => {
	const message = translations[key];
	if (message === undefined) {
		throw new Error(`Der JS-Text für "${key}" konnte nicht ermittelt werden.`);
	}

	if (args.length) {
		return message.replace(/{[0-9]}/, index => args[Number(index.slice(1, -1))]);
	}

	return message;
};

export const updateTranslation = (key: string, value: string) => {
	translations[key] = value;
};

export default translate;
