<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import translate from '../../../i18n';
	import { preventSubmit, selectOnFocus } from '../../actions';
	import Button from '../../components/Button';
	import Dialog from '../../components/Dialog';

	export let translations: Record<string, string>;

	let amount: number = 1;

	const dispatch = createEventDispatcher();

	const resolve = () => dispatch('resolve', amount);
	const reject = () => dispatch('reject');

	$: if (typeof amount !== 'number' || isNaN(amount) || amount < 1) {
		amount = 1;
	} else if (amount > 80) {
		amount = 80;
	}
</script>

<Dialog class="worker-amount-prompt" dark onClose={reject}>
	<label class="content">
		<p>{translations.howManyWorkers}</p>
		<input
			class="text-field"
			type="number"
			min="1"
			max="80"
			bind:value={amount}
			use:selectOnFocus
			use:preventSubmit={resolve}
		/>
	</label>

	<div class="footer">
		<Button on:click={reject}>{translate('cancel')}</Button>
		<Button primary on:click={resolve}>{translate('save')}</Button>
	</div>
</Dialog>
