import config from '../config';
import logError from './log-error';

const toFixedFix = (number: number, prec: number): string => {
	const k = Math.pow(10, prec);
	return '' + Math.round(number * k) / k;
};

export const dateFormat = (format => {
	switch (format) {
		case 'DE':
			return 'd.m.Y';
		case 'GB':
			return 'd/m/Y';
		case 'US':
			return 'm/d/Y';
		case 'INT':
			return 'Y-m-d';
		case 'BE':
			return 'd-m-Y';
	}

	logError('Datumsformat JS nicht definiert (format.ts).');
	return '';
})(config.dateFormat);

export const formatNumber = (number: number, decimals: number, dec_point?: string, thousands_sep?: string) => {
	number = +(number + '').replace(/[^0-9+\-Ee.]/g, '');
	number = !isFinite(number) ? 0 : number;

	const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
	const sep = thousands_sep === undefined ? ',' : thousands_sep;
	const dec = dec_point === undefined ? '.' : dec_point;

	let strings = (prec ? toFixedFix(number, prec) : '' + Math.round(number)).split('.');
	if (strings[0].length > 3) {
		strings[0] = strings[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}

	if ((strings[1] || '').length < prec) {
		strings[1] = strings[1] || '';
		strings[1] += new Array(prec - strings[1].length + 1).join('0');
	}
	return strings.join(dec);
};

export const numberFormat = (format => {
	if (format === undefined) {
		logError('Zahlenformat JS nicht definiert (format.ts).');
	}

	return format;
})(config.numberFormat);

export const formatNumber2User = (number: number, decimals?: number) => {
	if (numberFormat === undefined) {
		return number;
	}

	if (numberFormat === 'DE') {
		return formatNumber(number, decimals === undefined ? 2 : decimals, ',', '.');
	}

	return formatNumber(number, decimals === undefined ? 2 : decimals, '.', ',');
};

export const currency = (currency => {
	if (currency === undefined) {
		logError('Währung JS nicht definiert (format.ts).');
	}

	return currency;
})(config.currency);

export const formatCurrency2User = (number: number) => {
	if (currency === undefined) {
		return number;
	}

	if (currency === 'CHF') {
		return formatNumber(number, 2, '.', "'");
	}

	return formatNumber2User(number);
};
