<script>
	import { createEventDispatcher } from 'svelte';
	import { cubicInOut } from 'svelte/easing';
	import { slide } from 'svelte/transition';
	import Select from '../../../components/Select';
	import DeleteButton from '../DeleteButton.svelte';

	export let filter;
	export let name;
	export let language;
	export let level;

	export const getData = () => ({
		...filter,
		values:
			language.selected == 0
				? []
				: [
						[language.name, language.selected],
						[level.name, level.selected],
				  ],
	});

	const dispatch = createEventDispatcher();

	const update = () => dispatch('update');
	const remove = () => dispatch('remove');
</script>

<div class="filter filter--language" transition:slide={{ duration: 200, easing: cubicInOut }}>
	<strong>{name}:</strong>

	<Select bind:value={language.selected} options={language.options} on:change={update} />
	<Select bind:value={level.selected} options={level.options} on:change={update} />

	<DeleteButton on:click={remove} />
</div>
