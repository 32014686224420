<script>
	let className = undefined;
	export { className as class };
	export let title = undefined;

	$: rest = $$restProps;
</script>

<svg class="icon icon--certificate-missing{className ? ` ${className}`: ''}" focusable="false" role="img" {...rest} viewBox="0 0 258 256" xmlns="http://www.w3.org/2000/svg">{#if title}<title>{title}</title>{/if}
<rect id="Rectangle" x="7.5" y="7.5" width="177" height="212" rx="10" fill-opacity="0" stroke-width="15"></rect>
<path d="M40.5,53.5 L148.5,53.5" id="Line" stroke-linecap="square" stroke-width="15"></path>
<path d="M40.5,95.5 L126.5,95.5" id="Line" stroke-linecap="square" stroke-width="15"></path>
<circle id="Oval" cx="64.5" cy="158.5" r="23" stroke-width="15"></circle>
<path d="M40,195 L51.1029073,199.300781 C55.4000384,201.176432 59.5324027,202.114258 63.5,202.114258 C67.4675973,202.114258 72.3473788,201.176432 78.1393443,199.300781 L87,195 L87,248 L78.1393443,238.858995 C72.1301336,230.952998 67.5836855,227 64.5,227 C61.4163145,227 56.9506169,230.952998 51.1029073,238.858995 L40,248 L40,195 Z" id="Path" fill-rule="evenodd" stroke-linejoin="round" stroke-width="15"></path>
<polygon id="Path-2" fill-rule="evenodd" stroke-linejoin="round" points="226 10 250 10 238 176" stroke-width="15"></polygon>
<circle id="Oval" fill-rule="evenodd" cx="238" cy="228" r="12.5" stroke-width="15"></circle>
</svg>