let supportsOnceEventListener = false;

try {
	window.addEventListener(
		'test',
		null,
		Object.defineProperty({}, 'once', {
			get: function () {
				supportsOnceEventListener = true;
			},
		})
	);
} catch (e) {}

export default supportsOnceEventListener;
