<script lang="ts">
	import { Job, Category, SourceNode } from '../types';
	import { sourceNodeStore, isOnlyFutureJobsViewStore } from '../stores/stores';
	export let jobs: Job[];
	export let category: Category;

	let isProject: boolean;
	let mainjobOrProjectId;
	let currentDate = new Date();

	function getCategoryCount(job: Job) {
		for (let key in job.Kategorien) {
			let jobCategory = job.Kategorien[key];
			if (jobCategory.KategorieID === category.ID) {
				return jobCategory.Anzahl;
			}
		}
		return 0;
	}

	sourceNodeStore.subscribe((sourceNode: SourceNode) => {
		mainjobOrProjectId = sourceNode.ID;
		isProject = sourceNode.Subtyp === 'P';
	})
</script>

{#each jobs as job, index}
	{#if !$isOnlyFutureJobsViewStore || (new Date(job.DatumBis.split(".").reverse().join("-")) > currentDate)}
		{#if index === 0}
			<td class="space" />
			<td class="center">{getCategoryCount(job)}</td>

			{#if !isProject}
				<td class="space" />
				<td class="space black" />
				<th class="space" />
			{/if}
		{:else if !jobs.slice(index + 1).some(j => j.DatumVon === job.DatumVon)}
			<td class="center">{getCategoryCount(job)}</td>
			<td class="space black" />
		{:else}
			<td class="center">{getCategoryCount(job)}</td>
		{/if}
	{/if}
{/each}
