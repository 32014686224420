<script lang="ts">
	import ProgressBar from '../../../components/ProgressBar/ProgressBar.svelte';
	import NewButton from '../../../components/NewButton/NewButton.svelte';
	import Headline from '../components/Headline.svelte';
	import { Translations } from '../types';
	import AlertError from '../../../components/AlertError/AlertError.svelte';
	import { selectedCategoriesStore, selectedJobsStore, reviewStore, attachInformationStore, displayAlertInfo, isProjectStore } from '../stores/stores';
	import { sendEmail } from '../utils/apis';
	import logError from '../../../../utils/log-error';
	
	export let translations: Translations;
	export let mainJobId: number;

	let EmployeeIDs = $reviewStore.recipients.map(recipient => recipient.id);
	let error: boolean = false;
	let isLoading: boolean = false;
	let tenderWasSent: boolean = false;
    let isProject: boolean;

    isProjectStore.subscribe((value)=>{
        isProject = value;
    });

	$: {
		EmployeeIDs = $reviewStore.recipients.map(recipient => recipient.id);
	}

	let JobIDs = $selectedJobsStore.map(job => job.jobId);
	let CategoryIDs = $selectedCategoriesStore.map(category => category.ID);


	const batchSize = 50;
	let progress = 0;

	async function handleClick() {
		isLoading = true;

		const totalBatches = Math.ceil(EmployeeIDs.length / batchSize);
		let currentBatch = 0;
		try {
			   for (let i = 0; i < EmployeeIDs.length; i += batchSize) {
                const batchEmployeeIDs = EmployeeIDs.slice(i, i + batchSize);
                await sendEmail(mainJobId, $reviewStore.TenderText, $attachInformationStore, batchEmployeeIDs, JobIDs, CategoryIDs, isProject);
                currentBatch++;
				progress = Math.round((currentBatch / totalBatches) * 100);
            }

			
			    if (progress === 100) {
					setTimeout(() => {
					isLoading = false;
            		tenderWasSent = true;
            		displayAlertInfo.set(true);
            		const element = document.querySelector('.tendermail__content');
            		if (element && element.parentNode) {
            		    const parentElement = element.parentNode as HTMLElement;
						setTimeout(() => {
							parentElement.style.transition = "opacity 1s ease-out";
    						parentElement.style.opacity = "0";
						
							setTimeout(() => {
								parentElement.remove();
							}, 1000);
						}, 1000);
            		}
					}, 1000);	
        	  }
		} catch (err) {
			logError(`Error sending tendermail, mainJobId: ${mainJobId}, employeeIDs: ${EmployeeIDs.toString()}, jobIds: ${JobIDs.toString()}, categoryIds: ${CategoryIDs.toString()}`, err.stack);
			isLoading = false;
			error = true;
		} 
	}


	let jobTitles = $selectedJobsStore.sort((jobA,jobB) => jobA.jobId - jobB.jobId ).map(job => job.title).join(', ');

	let categoryTitles = $selectedCategoriesStore.map(category => category.Description).join(', ');

	let recipientsNames: string;

	$: if ($reviewStore.recipients) {
    	recipientsNames = $reviewStore.recipients.map(recipient => recipient.name).join('; ');
	};

</script>




{#if error}
	<AlertError message={translations.notFoundResource} type="error"/>
{:else if tenderWasSent}
	<AlertError message={translations.tenderSent} type="info"/>
{:else}
	<Headline text={translations.overview}/>
	{#if isLoading}
	<div style="margin-top: 14%;">
		<h2 style="text-align: center; margin-bottom: 20px">{translations.tenderAreSending}...</h2>
		<ProgressBar {progress}/>
	</div>
	{:else}
		<div class="tender-review-section">
			{#if $reviewStore.TenderText}
				<div>
					<p class="bold-tender">{translations.freeText}:</p>
					<p>{@html $reviewStore.TenderText}</p>
				</div>
			{/if}
			<div>
				{#if $selectedJobsStore.length > 0}
				<p class="bold-tender">{translations.subjobs} ({$selectedJobsStore.length}):</p>
				<p>
					<span data-testid="review-jobtitles">{jobTitles}</span>
				</p>
				{/if}
			</div>
			<div>
				{#if $selectedCategoriesStore.length > 0}
				<p class="bold-tender">{translations.categories} ({$selectedCategoriesStore.length}):</p>
				<p>
					<span data-testid="review-categorytitles">{categoryTitles}</span>
				</p>
				{/if}
			</div>
			<div>
				{#if $reviewStore.recipients.length > 0}
				<p class="bold-tender">{translations.recipient} ({$reviewStore.recipients.length}):</p>
				<p>
					<span data-testid="review-recipientnames">{recipientsNames} </span>
				</p>
				{/if}
			</div>
		</div>
		<NewButton type={"primary"} text={translations.send} buttonClass={"tender-bottom-right-btn"}  on:click={handleClick}/>
	{/if}
{/if}