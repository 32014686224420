import $ from 'jquery';
import config from '../config';
import translate from '../i18n';

const DIALOG_TIMEOUT = 43_200_000; // 12 hours
const AUTO_RELOAD_TIMEOUT = 300; // 5 minutes (in seconds)

const checkResponse = (_: any, xhr: JQuery.jqXHR) => {
	const jsVersion = xhr.getResponseHeader('X-Js-Version');
	if (jsVersion && jsVersion !== config.jsVersion) {
		startTimeout();
		return;
	}

	const cssVersion = xhr.getResponseHeader('X-Css-Version-' + config.currentTheme);
	if (cssVersion && cssVersion !== config.themes[config.currentTheme]) {
		startTimeout();
	}
};

const startTimeout = () => {
	$(document).off('ajaxComplete', checkResponse);

	setTimeout(() => {
		const dialog = $(`
			<div class="hinweis hinweis--update">
				<div class="hinweis__surface">
					<div class="header">${translate('newVersionTitle')}</div>
					<div class="content">
						<p>${translate('newVersionContent')}</p>
						<p>${translate('newVersionAutoReload').replace('{0}', '<span></span>')}</p>
					</div>
					<div class="footer">
						<button type="button" class="button button--primary">${translate('newVersionReload')}</button>
					</div>
				</div>
			</div>
		`);

		const span = dialog.find('span');

		let timeout = AUTO_RELOAD_TIMEOUT;
		const autoReload = () => {
			if (timeout--) {
				setTimeout(autoReload, 1000);
				span.text(timeout);
			} else {
				location.reload();
			}
		};

		autoReload();

		dialog.on('click', 'button', () => {
			location.reload();
		});

		$(document.body).append(dialog);
		dialog.fadeIn();
	}, DIALOG_TIMEOUT);
};

$(document).ajaxComplete(checkResponse);
