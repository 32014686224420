<script lang="ts">
	import Checkbox from '../../../components/Checkbox/Checkbox.svelte';
	import NewButton from '../../../components/NewButton/NewButton.svelte';
	import { Translations } from '../types';
	import { createResourceChip } from '../utils/chipElementCreator';
	import { attachInformationStore, loadAllStore, isSelectAllBtnDisabledStore } from '../stores/stores';
	export let translations: Translations;
	export let toggleTextArea: () => void;
	export let employeesWithTenderReceivedArray: number[] = [];

	let runLoadAll : boolean;
    let selectAllCalled = false;

	loadAllStore.subscribe(value => {
		runLoadAll = value;
	});

	function selectAll() {
		isSelectAllBtnDisabledStore.set(true);
		loadAllStore.set(true);
		selectAllCalled = true;
	}

	$: if (!runLoadAll && selectAllCalled) {
        const resources = document.querySelectorAll('.employee-drag');
        Array.from(resources).map((resource): ReturnType<typeof createResourceChip> => {
            if (!resource.classList.contains('disabled-tender')) {
                let name = resource.getAttribute('data-ressourcename');
                let id = resource.getAttribute('data-ressourceid');

				let overlapIcon = undefined;
				let overlapType = undefined;

				if(resource.hasAttribute('data-overlap-icon')) {
					overlapIcon = resource.getAttribute('data-overlap-icon')
				};

				if(resource.hasAttribute('data-overlap-type')) {
					overlapType = resource.getAttribute('data-overlap-type')
				};
				
                createResourceChip(name, parseInt(id), employeesWithTenderReceivedArray, overlapIcon, overlapType);
            }
        });
        selectAllCalled = false; 
		isSelectAllBtnDisabledStore.set(false);
    }

	let toggleAttachInformation = true;

	$: {
		attachInformationStore.set(toggleAttachInformation);
	}
</script>


<div class="footer-recipient">
	<div>
		<Checkbox bind:checked={toggleAttachInformation} label={translations.attachInformation} />
		<Checkbox on:click={toggleTextArea} label={translations.freeTextEmail}/>
	</div>
	
	<NewButton type={"secondary"} text={translations.takeAll} on:click={selectAll} disabled={$isSelectAllBtnDisabledStore}/>
</div>
