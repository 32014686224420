<script lang="ts">
	import ChevronDown from 'icon:chevron-down';
	import cx from '../../../utils/class-names';
	import dataAttributes from '../../../utils/data-attributes';

	let className: string = '';
	export { className as class };
	export let autoSubmit: boolean = false;
	export let inputClass: string = '';
	export let name: string = undefined;
	export let value: string | number = undefined;
	export let data: Record<string, string> = undefined;
	export let options: {
		label: string;
		value?: string | number;
		disabled?: boolean;
		data?: Record<string, string>;
	}[] = [];

	const noNumberRegex = /[^0-9]/;
	$: if (typeof value === 'string' && value.length && !noNumberRegex.test(value)) {
		value = Number(value);
	}
</script>

<svelte:options immutable />

<div class={cx('select-wrapper', className)}>
	<!-- svelte-ignore a11y-no-onchange -->
	<select {...dataAttributes(data)} {...$$restProps} class:autosubmit={autoSubmit} class={inputClass} {name} bind:value on:change>
		{#each options as { disabled, label, value, data }}
			<option {...dataAttributes(data)} {disabled} value={value || value == 0 ? value : undefined}>{label}</option>
		{/each}
	</select>

	<ChevronDown />
</div>
