import $ from 'jquery';
import { onAfterNavigate, onInit } from '../utils/subscriptions';

const timeouts = new Map<Element, number>();
const MAX_TIMEOUT = 0x7fffffff;

const init = () => {
	for (const element of document.querySelectorAll('[data-click-in]')) {
		if (timeouts.has(element)) {
			return;
		}

		const remaining = Number(element.getAttribute('data-click-in'));
		if (isNaN(remaining)) {
			return;
		}

		addTimeout(remaining * 1000, element);
	}
};

const addTimeout = (ms: number, element: Element) => {
	if (ms > MAX_TIMEOUT) {
		timeouts.set(
			element,
			window.setTimeout(() => {
				addTimeout(ms - MAX_TIMEOUT, element);
			}, MAX_TIMEOUT)
		);
	} else {
		timeouts.set(
			element,
			window.setTimeout(() => {
				$(element).trigger('click');
			}, ms)
		);
	}
};

onInit(init);

onAfterNavigate(({ target }) => {
	if (target !== 'content') {
		return;
	}

	for (const [element, timeout] of timeouts.entries()) {
		if (!document.body.contains(element)) {
			timeouts.delete(element);
			clearTimeout(timeout);
		}
	}

	init();
});
