import debounce from 'debounce';
import translate from '../../i18n';
import { sendFormData } from '../../legacy';
import { EmployeeType } from '../../svelte';
import { addRequestableModifier, onDragEmployee } from '../../svelte/Event/EmployeeRequest';
import { View } from '../../utils/element-router';
import listen from '../../utils/listen';
import { getCategoryId, getRefRow, getEmployees, employeeFinder } from './helpers';
import type { Employee } from './helpers';
import { promptDialog } from '../../utils/components';
import WorkerAmountPrompt from '../../svelte/Event/SubEventOverview/WorkerAmountPrompt.svelte';
import OverlapPrompt from '../../svelte/Event/SubEventOverview/OverlapPrompt.svelte';
import jquery from 'jquery';


type AddEmployeeAction = 'request' | 'enter' | 'book';

let filters;
let postParams;
let translations;
let appointments;

const getPreferences = async () => {
	({ filters, postParams, translations } = await jquery.post(location.href, {
		silent: true,
		package: 'TerminEmployeeRequest',
		ajax: 'initialize',
	}));
	return postParams.preferences;
};

const getOverlappingAppointments = async (terminID:Number, z:String, zid:Number ) => {
	({ appointments } = await jquery.post(location.href, {
		silent: true,
		package: 'TerminEmployeeRequest',
		ajax: 'getOverlappingAppointments',
		terminid: terminID,
		ressourceid: zid,
		ressourcetype: z,
	}));
	return appointments;
};

const EmployeesView: View = ({ container }) => {
	const employees: Employee[] = getEmployees(container);

	const isRequestable = ({ id, type, name }: Employee) =>
		type === EmployeeType.ServiceProvider || employees.findIndex(employeeFinder({ id, type, name })) === -1;

	const destroyers = [
		addRequestableModifier(isRequestable).delete,

		onDragEmployee(({ id, type, free, name, employeePhone, vehicleSeats, vehiclePayload, roomNumberPeople, isOverlapping }) => {
			if (!isRequestable({ id, type, name })) {
				return false;
			}

			const target = container.querySelector('.event-details__content') as HTMLDivElement;
			target.classList.add('employee-drop--active');

			let action: AddEmployeeAction = 'request';
			let dropTarget: HTMLDivElement;
			let noResults: HTMLDivElement;
			let tbody: HTMLTableSectionElement;
			let row: HTMLTableRowElement;
			let prevRefRow: HTMLTableRowElement;
			let listeners: (() => void)[] = [];

			const leave = () => {
				for (const listener of listeners) {
					listener();
				}

				listeners = [];

				if (dropTarget) {
					if (noResults) {
						dropTarget.parentElement && noResults.removeChild(dropTarget);
					} else {
						row.parentElement && tbody.removeChild(row);
					}
				}

				target.classList.remove('employee-drop--dragover');
			};

			const reset = debounce(() => {
				action = 'request';
				dropTarget.setAttribute('data-action', 'request');
			}, 60);

			return {
				target,
				leave,

				destroy() {
					leave();
					target.classList.remove('employee-drop--active');
				},

				enter() {
					target.classList.add('employee-drop--dragover');

					if (!dropTarget) {
						dropTarget = document.createElement('div');
						dropTarget.setAttribute('data-action', 'request');
						dropTarget.className = 'employee-drop__target';
						dropTarget.innerHTML = `
							<div class="employee-drop__target__request">${translate('employeeRequest')}</div>
							<div class="employee-drop__target__enter">${translate('employeeEnter')}</div>
							<div class="employee-drop__target__book">${translate('employeeBook')}</div>
						`;

						tbody = target.querySelector('tbody');
						if (tbody) {
							const cell = document.createElement('td');
							cell.setAttribute('colspan', '42');
							cell.appendChild(dropTarget);

							row = document.createElement('tr');
							row.className = 'employee-drop__target';
							row.appendChild(cell);
						} else {
							noResults = target.querySelector('.event-details__noresult');
						}
					}

					if (noResults) {
						noResults.appendChild(dropTarget);
					} else {
						tbody.appendChild(row);
						listeners.push(
							listen(target, 'dragover', event => {
								const refRow = getRefRow(tbody, row, prevRefRow, event);
								if (prevRefRow !== refRow) {
									prevRefRow = refRow;
									if ( refRow != undefined && refRow != null ) {
										if ( refRow.parentNode.isEqualNode(tbody) ) {
											tbody.insertBefore(row, refRow);
										} else {
											throw 'refRow is not child of tbody: ' + refRow.outerHTML;
										}
									}
								}
							})
						);
					}

					listeners.push(
						listen(dropTarget, 'dragover', ({ target }) => {
							reset.clear();
							const { className } = target as HTMLDivElement;
							if (className) {
								const dropAction = className.replace('employee-drop__target__', '') as AddEmployeeAction;
								if (dropAction !== action) {
									action = dropAction;
									dropTarget.setAttribute('data-action', action);
								}
							}
						}),

						listen(dropTarget, 'dragleave', reset)
					);
				},

				async drop() {

					let category = noResults ? '0' : String(getCategoryId(row));

					let translations = {'howManyWorkers': translate('howManyWorkers')}
					// dialog prompt 
					let amount = undefined;
					if (type === EmployeeType.ServiceProvider) {
						try {
							amount = await promptDialog<number>({
								component: WorkerAmountPrompt,
								props:  { translations },
							});
						} catch (e) {
							return;
						}
					}
					if ( type === null || type === undefined ) {
						alert( translate('errorResourceType') );
					}
					let translationsOverlap = {
						'overlappingAppointmentOne': translate('overlappingAppointmentOne'),
						'overlappingAppointmentMore': translate('overlappingAppointmentMore'),
						'insertAnyway': translate('insertAnyway'),
						'cancelOther': translate('cancelOther'),
					};
					let terminID = parseInt(document.querySelector('input[name="terminid"]').getAttribute('value'));
					let overlappingAppointments = await getOverlappingAppointments(terminID, type, id);

					let cancelterminids:Array<number>;
					const isOverlapDialog = (await getPreferences()).overlapDialog === '1';

					if ( isOverlapDialog && isOverlapping ) {
						try {
							cancelterminids = await promptDialog<Array<number>>({
								component: OverlapPrompt,
								props:  { translationsOverlap, id, overlappingAppointments },
							});
						} catch (e) {
							return;
						}
					}

					const formDataRessource = new FormData();
					formDataRessource.set('ajax', 'true');
					formDataRessource.set('action', 'addEmployeeToJob');
					formDataRessource.set('package', 'TerminEmployeeRequest');
					formDataRessource.set('ressourceid', String(id));
					formDataRessource.set('ressourcetype', type);
					formDataRessource.set('categoryid', category);
					formDataRessource.set('add-action', action);
					formDataRessource.set('free', free ? 'true' : 'false');
					formDataRessource.set('name', name);
					formDataRessource.set('employeePhone', employeePhone);
					formDataRessource.set('vehicleSeats', vehicleSeats);
					formDataRessource.set('vehiclePayload', vehiclePayload);
					formDataRessource.set('roomNumberPeople', roomNumberPeople);
					formDataRessource.set('amount', String(amount));
					formDataRessource.set('cancelterminids', JSON.stringify(cancelterminids));

					const formDataJob = new FormData(container.closest('form'));
					formDataJob.set('ajax', 'true');
					sendFormData(
						container.closest('form'), // form
						target.querySelector('table'), // submit
						location.href, // url
						'POST', // method
						formDataJob, // formData
						undefined, // loading
						undefined,  // ajax
						undefined, // package
						undefined, // mode
						undefined, // target
						undefined, // header
						'fade', // transition
						false, // remove
						'false', // bubbleclose
						true, // preventurlupdate
						function() {
							sendFormData(
								container.closest('form'), // form
								target.querySelector('table'), // submit
								location.href, // url
								'POST', // method
								formDataRessource, // formData
								undefined, // loading
								'printHtml',  // ajax
								'TerminSeiteMitarbeiter', // package
								undefined, // mode
								'termindetails', // target
								undefined, // header
								'fade', // transition
								undefined, // remove
								'false', // bubbleclose
								true, // preventurlupdate
								undefined,// callback
								undefined,// additionalData
								true// scroll
							);		
						},
						undefined, // additionalData
						true// scroll
					);
				},
			};
		}),
	];

	return () => {
		for (const destroy of destroyers) {
			destroy();
		}
	};
};

export default EmployeesView;
