<script lang="ts">
	import PersonnelRequirements from './PersonnelRequirements.svelte';
	import TablerowContent from './TablerowContent.svelte';
	import { Category, Job, ResourcesEntries, Detail, Translations } from '../types';
	import { removeResources } from '../utils/utilsStaffplan';
	export let categories: Category[];

	export let jobs: Job[];

	export let addToBackendData;
	export let backendData: any;
	export let check;
	export let translations: Translations;
	export let resourcesEntries: ResourcesEntries[];
	export let timeoffcategories;

	let tablerowContentProps = {
		addToBackendData,
		backendData,
		jobs,
		check,
		translations,
		timeoffcategories,
	};

	function handleRemove(event: CustomEvent<Detail>) {
		const { keyToRemove, zugehoerigkeit, zugehoerigkeitID, kategorieID, freierMitarbeiter, subEmployeeId } =
			event.detail;

		const tableRowElement = document.querySelector(`tr[data-key='${keyToRemove}'][data-kategorieid='${kategorieID}']`);

		setTimeout(() => {
			let deleteButtonCollection = document.getElementsByClassName('personnelplan-delete');
			let deleteButton = deleteButtonCollection[0] as HTMLElement;

			deleteButton.onclick = e => {
				if (tableRowElement) {
					tableRowElement.remove();
					removeResources(
						tableRowElement as HTMLElement,
						zugehoerigkeit,
						zugehoerigkeitID,
						'anfragen',
						kategorieID,
						freierMitarbeiter,
						subEmployeeId
					);
				}
			};
		}, 200);
	}
	// if there is a resource with "Zugehoerigkeit" === "F" & vehicle has no category.
	// Add vehicles to "Categories".
	resourcesEntries.some(resource => {
		let resourceValue = resource[1];
		if (resourceValue.Zugehoerigkeit === 'F' && resourceValue.Kategorien[0] === 0) {
			console.log('resource', resource);
			categories.push({ ID: 0, Bezeichnung: translations.vehicles });
			return true;
		}
		return false;
	});
</script>

{#each categories as category}
	<tr class="headline nodrag" id={category.ID.toString()} data-kategorie={category.ID.toString()}>
		<td>{category.Bezeichnung}</td>
		<PersonnelRequirements {jobs} {category} />
	</tr>

	{#if category.Bezeichnung === translations.vehicles}
		{#each resourcesEntries as [key, resource] (key)}
			{#if resource.Zugehoerigkeit === 'F' && resource.Kategorien[0] === 0}
				<tr
					data-zugehoerigkeit={resource.Zugehoerigkeit}
					data-zugehoerigkeitID={resource.ZugehoerigkeitID}
					data-freierMitarbeiter={resource.FreierMitarbeiter}
					data-subEmployeeId={resource.SubEmployeeID}
					data-key={key}
					data-kategorieid={category.ID}
				>
					<TablerowContent
						on:remove={handleRemove}
						{...tablerowContentProps}
						{resource}
						kategorieID={category.ID}
						{key}
						hasCategory={true}
					/>
				</tr>
			{/if}
		{/each}
	{/if}

	{#each resourcesEntries as [key, resource] (key)}
		{#if resource.Kategorien.filter(categoryItem => categoryItem.KategorieID === category.ID && categoryItem.Bezeichnung === category.Bezeichnung).length > 0}
			<tr
				data-zugehoerigkeit={resource.Zugehoerigkeit}
				data-zugehoerigkeitID={resource.ZugehoerigkeitID}
				data-freierMitarbeiter={resource.FreierMitarbeiter}
				data-subEmployeeId={resource.SubEmployeeID}
				data-key={key}
				data-kategorieid={category.ID}
			>
				<TablerowContent
					on:remove={handleRemove}
					{...tablerowContentProps}
					{resource}
					kategorieID={category.ID}
					{key}
					hasCategory={true}
				/>
			</tr>
		{/if}
	{/each}
{/each}
