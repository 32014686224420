import { SvelteComponent } from 'svelte';
import { check_outros, group_outros, transition_out } from 'svelte/internal';
import { SubEventOverview } from '../svelte/Event';
import { TenderEmail } from '../svelte/Event';
import { Staffplan } from '../svelte/Event';
import {AlertInfo} from "../svelte/Event";
import { ServiceProviderQuantity } from '../svelte/Event';

const components: [Element, SvelteComponent][] = [];

const getComponent = (component: string) => {
	switch (component) {
		case 'SubEventOverview':
			return SubEventOverview;
		case 'TenderEmail':
			return TenderEmail;
		case 'Staffplan': 
			return Staffplan;
		case 'AlertInfo': 
			return AlertInfo;
		case 'ServiceProviderQuantity': 
			return ServiceProviderQuantity;
	}

	throw new Error(`Unknown component "${component}"`);
};

// Fix for https://github.com/sveltejs/svelte/issues/4056
const outroAndDestroy = (instance: SvelteComponent) => {
	if (instance.$$.fragment && instance.$$.fragment.o) {
		group_outros();
		transition_out(instance.$$.fragment, 0, 0, () => {
			instance.$destroy();
		});
		check_outros();
	} else {
		instance.$destroy();
	}
};

export const render = ({
	component,
	target,
	props,
}: {
	component: typeof SvelteComponent | string;
	target: Element | string;
	props?: Record<string, any>;
}) => {
	if (!component) {
		throw new Error('Property "component" is required');
	}

	if (!target) {
		throw new Error('Property "target" is required');
	}

	if (typeof target === 'string') {
		target = document.querySelector(target);
		if (!target) {
			throw new Error(`Target "${target}" not found`);
		}
	}

	if (typeof component === 'string') {
		component = getComponent(component);
	}

	const instance = new component({ target, props, intro: true });
	components.push([target, instance]);
	return instance;
};

export const promptDialog = <T = unknown>({
	component,
	props,
}: {
	component: typeof SvelteComponent | string;
	props?: Record<string, any>;
}) =>
	new Promise<T>((resolve, reject) => {
		const instance = render({ component, props, target: document.body });
		instance.$on('resolve', ({ detail }) => {
			destroy(instance);
			resolve(detail);
		});
		instance.$on('reject', () => {
			destroy(instance);
			reject();
		});
	});

export const destroy = (instance: SvelteComponent) => {
	const index = components.findIndex(([target, component]) => component === instance);
	if (index !== -1) {
		components.splice(index, 1);
		outroAndDestroy(instance);
	}
};

export const destroyWithin = (container: Element) => {
	if (container) {
		components.forEach(([target, component], index) => {
			if (container.contains(target)) {
				components.splice(index, 1);
				outroAndDestroy(component);
			}
		});
	}
};

if (Array.isArray(window.__CREW_BRAIN__RENDER__)) {
	for (const item of window.__CREW_BRAIN__RENDER__) {
		render(item);
	}
}

window.__CREW_BRAIN__RENDER__ = { push: render };
