import debounce from 'debounce';
import createFocusTrap from 'focus-trap';
import { ajaxRequest } from '../legacy';
import { onInit, onNavigate } from '../utils/subscriptions';

const CLASS = 'main-navigation';
const CLASS_ITEM = 'main-navigation__item';
const CLASS_ITEM_ACTIVE = 'main-navigation__item--active';
const CLASS_ITEM_MORE = 'main-navigation__item--more';
const CLASS_ITEM_MORE_ACTIVE = 'main-navigation__item--more-active';
const CLASS_ITEM_MORE_VISIBLE = 'main-navigation__item--more-visible';
const CLASS_LINK = 'main-navigation__link';
const CLASS_LIST_TOP = 'main-navigation__list--top';
const CLASS_MORE_CONTAINER = 'main-navigation__more';

const ITEM_HEIGHT = 40;

const SUBNAV = '#subnav';
const SUBNAV_LIST = '.sub-navigation__list';
const SUBNAV_ITEM = '.sub-navigation__item';
const SUBNAV_SEARCH = '#sub-navigation__search';

let navigation;
let listTop;
let items;
let moreItem;
let moreButton;
let moreContainer;
let minHeight;

let activeItem;
let hiddenItems = [];
let moreVisible = false;
let moreActive = false;
let moreListenersAttached = false;
let focusTrap;
let navListener;

onInit(() => {
	navigation = document.querySelector(`.${CLASS}`);

	listTop = navigation.querySelector(`.${CLASS_LIST_TOP}`);
	items = Array.from(listTop.querySelectorAll(`.${CLASS_ITEM}:not(.${CLASS_ITEM_MORE})`)).reverse();
	moreItem = listTop.querySelector(`.${CLASS_ITEM_MORE}`);
	moreButton = moreItem.querySelector(`.${CLASS_LINK}`);
	moreContainer = moreItem.querySelector(`.${CLASS_MORE_CONTAINER}`);
	minHeight = items.length * ITEM_HEIGHT;
	activeItem = navigation.querySelector(`.${CLASS_ITEM_ACTIVE}`);

	checkHeight();
	window.addEventListener('resize', checkHeight);

	navigation.addEventListener('click', event => goto(event, event.target.closest(`.${CLASS_LINK}`)));
	document.querySelector(SUBNAV).addEventListener('click', event => goto(event, event.target.closest('a'), true));
});

onNavigate(({ url }) => {
	if (!url || !navigation) {
		return;
	}

	let href;
	if (url[0] === '/') {
		href = url.split('/')[1];
	} else if (url.indexOf('http') === 0) {
		href = new URL(url).pathname.split('/')[1];
	} else {
		href = url;
	}

	let link;
	if (['mitarbeiter', 'freelancer', 'dienstleister', 'fahrzeuge', 'kunden', 'locations'].includes(href)) {
		link = navigation.querySelector(`.${CLASS_LINK}[href="stammdaten"]`);
	}

	link = link || navigation.querySelector(`.${CLASS_LINK}[href="${href}"]`);
	const item = link && link.closest(`.${CLASS_ITEM}`);
	if (activeItem !== item) {
		if (activeItem) {
			activeItem.classList.remove(CLASS_ITEM_ACTIVE);
		}

		if ((activeItem = item)) {
			item.classList.add(CLASS_ITEM_ACTIVE);
		}
	}
});

const goto = (event, link, isSubNavClick) => {
	if (!link) {
		return;
	}

	if (link.hasAttribute('data-ajax')) {
		return;
	}

	const href = link.getAttribute('href');
	if (!href || href === 'logout') {
		return;
	}

	event.preventDefault();
	if (!link.hasAttribute('data-formname')) {
		event.stopPropagation();
	}

	let subNavCallback;
	let isSubNavSearch = false;
	if (isSubNavClick) {
		const subNavListScrollTop = document.querySelector(`${SUBNAV} ${SUBNAV_LIST}`).scrollTop;
		subNavCallback = () => {
			if ( document.querySelector(`${SUBNAV} ${SUBNAV_LIST}`) == null )
				return;
				
			document.querySelector(`${SUBNAV} ${SUBNAV_LIST}`).scrollTop = subNavListScrollTop;
		};
		isSubNavSearch = document.querySelector(`${SUBNAV_SEARCH}`) != undefined;
		document.querySelectorAll(`${SUBNAV_ITEM}`).forEach(item => { item.classList.remove('sub-navigation__item--active') });
		link.parentNode.classList.add('sub-navigation__item--active');
	}

	ajaxRequest(link, href, 'printHead', 'header');
	if ( !isSubNavClick || !isSubNavSearch ) {
		ajaxRequest(
			link,
			href,
			'printSplitviewNavigation',
			'subnav',
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			subNavCallback
		);
	}
	ajaxRequest(link, href, 'printContent', 'content');
};

const checkHeight = debounce(() => {
	const height = listTop.clientHeight;
	if (minHeight > height) {
		moreVisible = true;
		moreItem.setAttribute('aria-hidden', 'false');
		moreItem.classList.add(CLASS_ITEM_MORE_VISIBLE);

		let remainingHeight = minHeight;
		let newHiddenItems = [];
		items.some(item => {
			const stop = remainingHeight < height;
			remainingHeight -= ITEM_HEIGHT;
			newHiddenItems.push(item);
			if (hiddenItems.indexOf(item) === -1) {
				moreContainer.insertBefore(item, moreContainer.firstChild);
			}

			return stop;
		});

		hiddenItems.reverse().forEach(item => {
			if (newHiddenItems.indexOf(item) === -1) {
				listTop.insertBefore(item, moreItem);
			}
		});

		hiddenItems = newHiddenItems;

		attachMoreListeners();
	} else if (moreVisible) {
		moreVisible = false;
		moreItem.setAttribute('aria-hidden', 'true');
		moreItem.classList.remove(CLASS_ITEM_MORE_VISIBLE);

		hiddenItems.reverse().forEach(item => {
			listTop.insertBefore(item, moreItem);
		});

		hiddenItems = [];

		deactivateMore();
		if (focusTrap) {
			focusTrap.deactivate();
		}
	}
}, 200);

const attachMoreListeners = () => {
	if (moreListenersAttached) {
		return;
	}

	moreListenersAttached = true;

	moreButton.addEventListener('click', () => {
		if (!moreActive) {
			activateMore();

			requestAnimationFrame(() => {
				document.addEventListener('click', deactivateMore, { once: true });
			});
		}
	});

	moreButton.addEventListener('keydown', ({ key }) => {
		if (!moreActive && key === 'Enter') {
			activateMore();

			if (!focusTrap) {
				focusTrap = createFocusTrap(moreContainer, {
					onDeactivate: deactivateMore,
					clickOutsideDeactivates: true,
				});
			}

			focusTrap.activate();
		}
	});
};

const activateMore = () => {
	navListener = onNavigate(() => {
		if (focusTrap) {
			focusTrap.deactivate();
		} else {
			deactivateMore();
		}
	});

	moreActive = true;
	moreContainer.setAttribute('aria-hidden', 'false');
	moreButton.setAttribute('aria-expanded', 'true');
	moreItem.classList.add(CLASS_ITEM_MORE_ACTIVE);
};

const deactivateMore = () => {
	if (navListener) {
		navListener();
		navListener = undefined;
	}

	moreActive = false;
	moreContainer.setAttribute('aria-hidden', 'true');
	moreButton.setAttribute('aria-expanded', 'false');
	moreItem.classList.remove(CLASS_ITEM_MORE_ACTIVE);
};
