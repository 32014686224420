<script lang="ts">
	import filesize from 'filesize';
	import ExclamationTriangle from 'icon:exclamation-triangle';
	import Times from 'icon:times';
	import { createEventDispatcher } from 'svelte';
	import { cubicInOut } from 'svelte/easing';
	import { slide } from 'svelte/transition';
	import translate from '../../../i18n';

	import IconButton from "../IconButton";

	export let file: File;
	export let error: string | undefined = undefined;

	const dispatch = createEventDispatcher();

	const getObjectUrl = () => {
		const objectUrl = URL.createObjectURL(file);
		setTimeout(() => {
			URL.revokeObjectURL(objectUrl);
		}, 0);

		return objectUrl;
	};
</script>

<svelte:options immutable />

<li
	class={error ? 'file-upload__list__item--error' : undefined}
	transition:slide|global={{ duration: 200, easing: cubicInOut }}
>
	{#if file.type.indexOf('image/') === 0}
		<figure>
			<figcaption>{file.name}</figcaption>
			<img src={getObjectUrl()} alt={file.name} />
		</figure>
	{:else}
		<span>{file.name}</span>
	{/if}

	<small>{filesize(file.size)}</small>
	<IconButton small icon={Times} title={translate('delete')} on:click={() => dispatch('delete')} />

	{#if error}
		<p>
			<ExclamationTriangle />
			<span>{error}</span>
		</p>
	{/if}
</li>
