<script lang="ts">
    import Checkbox from '../../../components/Checkbox/Checkbox.svelte';
    import RadioBtn from '../../../components/RadioBtn/RadioBtn.svelte';
	import { Translations, RequestStatus } from '../types';
    import { isCategoriesCheckedStore, isOnlyFutureJobsViewStore } from '../stores/stores';
	import { status } from '../stores/stores';
    import { get } from 'svelte/store'

    export let translations: Translations;

    const changeStatus = (event:Event, newStatus:string) => {
        if ( get(status) === newStatus ) { // prevent unchecking radio input
            event.stopPropagation();
        } else {
            status.set(newStatus);
        }
    }; 

    function toggleFutureJobsView() {
        isOnlyFutureJobsViewStore.update(value => !value);

		let tabViewStaffplanBtn = document.querySelector('button[value="untertermin-personalplan"]');
		(tabViewStaffplanBtn as HTMLButtonElement).click();	
    }

    function toggleCategories() {
		isCategoriesCheckedStore.update(value => !value);

		let tabViewStaffplanBtn = document.querySelector('button[value="untertermin-personalplan"]');
		(tabViewStaffplanBtn as HTMLButtonElement).click();	
	};

</script>

<th>
	<strong>{translations.newEmployees}:</strong>
    <RadioBtn span={translations.requested} name="anfragevariante" on:click={(e) => changeStatus(e, RequestStatus.REQUESTED)} checked={true}/>
    <RadioBtn span={translations.available} name="anfragevariante" on:click={(e) => changeStatus(e, RequestStatus.AVAILABLE)}/>
    <RadioBtn span={translations.booked} name="anfragevariante" on:click={(e) => changeStatus(e, RequestStatus.BOOKED)}/>
    <RadioBtn span={translations.rejected} name="anfragevariante" on:click={(e) => changeStatus(e, RequestStatus.REJECTED)}/>
    <RadioBtn className="space-bottom" span={translations.absent}/{translations.holiday} name="anfragevariante" on:click={(e) => changeStatus(e, RequestStatus.NV)}/>
    <Checkbox label={translations.categories} checked={$isCategoriesCheckedStore} on:click={toggleCategories} />
    <Checkbox label={"nur künftige Termine"} name="personalplan-nurkuenftige" checked={$isOnlyFutureJobsViewStore} on:click={toggleFutureJobsView} />
</th>
