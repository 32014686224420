export enum EventType {
	Event = 'T',
	Project = 'PR',
}

export enum EmployeeType {
	Employee = 'M',
	Freelancer = 'E',
	ServiceProvider = 'D',
	Vehicle = 'F',
	Room = 'RO',
}
