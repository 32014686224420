const createCustomEvent = (typeArg: string, eventInitDict?: CustomEventInit) => {
	try {
		return new CustomEvent(typeArg, eventInitDict);
	} catch (error) {
		// IE11 does not support using CustomEvent as a constructor directly:
		const event = document.createEvent('CustomEvent');
		eventInitDict = eventInitDict || {};
		event.initCustomEvent(
			typeArg,
			eventInitDict.bubbles || false,
			eventInitDict.cancelable || false,
			eventInitDict.detail || {}
		);
		return event;
	}
};

export default createCustomEvent;
