import { onInit } from '../utils/subscriptions';

const DELAY = 150;
const DURATION_FINISH = 250;

const CLASS = 'loading-bar';
const CLASS_RUNNING = 'loading-bar--running';
const CLASS_FINISHED = 'loading-bar--finished';

const classList = (() => {
	const loadingBar = document.createElement('div');
	const { classList } = loadingBar;

	loadingBar.setAttribute('role', 'presentation');
	classList.add(CLASS);
	document.body.appendChild(loadingBar);

	onInit(() => {
		document.body.appendChild(loadingBar);
	});

	return classList;
})();

let timeout;
let running = false;
let finished = false;

const reset = () => {
	timeout = undefined;
	running = false;
	finished = false;
	classList.remove(CLASS_RUNNING);
	classList.remove(CLASS_FINISHED);
};

export const activate = () => {
	if (timeout) {
		clearTimeout(timeout);
		timeout = undefined;
	}

	if (finished) {
		finished = false;
		classList.remove(CLASS_FINISHED);
	} else if (!running) {
		timeout = setTimeout(() => {
			timeout = undefined;
			running = true;
			classList.add(CLASS_RUNNING);
		}, DELAY);
	}
};

export const deactivate = () => {
	if (timeout) {
		clearTimeout(timeout);
		reset();
	} else if (running) {
		timeout = setTimeout(reset, DURATION_FINISH);
		finished = true;
		classList.add(CLASS_FINISHED);
	}
};
