<script lang="ts">
    import Spinner from '../../../components/Spinner/Spinner.svelte';
    import { selectedJobsStore, checkedSubjobsStore, showSubjobs } from '../stores/stores';
    import {  Translations, JobData} from '../types';
    import Checkbox from '../../../components/Checkbox/Checkbox.svelte';
    import Headline from '../components/Headline.svelte';
    import AlertError from '../../../components/AlertError/AlertError.svelte';
    import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
    import {faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

    export let translations: Translations;
    export let jobsData: JobData[] = [];
    export let dataLoaded: boolean;

    let allSubjobsChecked = false;
    let error: boolean = false;

    $: allSubjobsChecked = $checkedSubjobsStore.length === calculateTotalSubjobsLength(jobsData);
    $: checkedSubjobsCount = $checkedSubjobsStore.length; 
   
    $: {
        let selectedJobs : JobData[] = [];
        const addJobIfIdMatches = (job: JobData, id : number) => {
          if (id === job.jobId) {
            selectedJobs.push({ jobId: job.jobId, title: job.title, finished: job.finished, dateTimespan:job.dateTimespan });
          }
        };

        const addSubjobsIfIdMatches = (subjobs : JobData[], id : number) => {
          if (subjobs && subjobs.length > 0) {
            subjobs.forEach(subjob => {
              if (id === subjob.jobId) {
                selectedJobs.push(subjob);
              }
            });
          }
        };

        $checkedSubjobsStore.forEach(id => {
          jobsData.forEach(job => {
            addJobIfIdMatches(job, id);
            addSubjobsIfIdMatches(job.subjobData, id);
          });
        });

        selectedJobsStore.set(selectedJobs);
    }
  
    function calculateTotalSubjobsLength(jobsData : JobData[]) {
        return jobsData.reduce((totalLength, job) => {
            totalLength += 1; 
            if (job.subjobData) {
                totalLength += job.subjobData.length; 
            }
            return totalLength;
        }, 0);
    }

    function toggleAllSubjobs(jobArr : JobData[]) {
        allSubjobsChecked = !allSubjobsChecked;

        let oldStoreValue = $checkedSubjobsStore;

        const allJobIds = jobArr.reduce((acc, job) => {
            acc.push(job.jobId);
            if (job.subjobData) {
                acc.push(...job.subjobData.map(subjob => subjob.jobId));
            }
            return acc;
        }, []);

        let combinedArray = [...new Set(oldStoreValue.concat(allJobIds))];
        checkedSubjobsStore.set(allSubjobsChecked ? combinedArray : []);
    }

    function toggleSubjobs(jobId : number) {
        showSubjobs.update(current => {
          const newMap = new Map(current);
          newMap.set(jobId, !newMap.get(jobId));
          return newMap;
        });
    }
    
    function checkAllSubjobs(subJobArr: JobData[], onlyUnfinished:boolean=false) {
        if ( onlyUnfinished ) {
            subJobArr = subJobArr.filter(subjob => !subjob.finished);
        }
        const subJobIds = subJobArr.map(subjob => subjob.jobId);
        const allInStore = subJobIds.every(id => $checkedSubjobsStore.includes(id))

        if(allInStore) {
            const idsToKeep = $checkedSubjobsStore.filter(id => !subJobIds.includes(id));
            checkedSubjobsStore.set(idsToKeep);
        } else {
            const idsToKeep = $checkedSubjobsStore.filter(id => !subJobIds.includes(id));
            const mergedArray = [...idsToKeep, ...subJobIds]
            checkedSubjobsStore.set(mergedArray);
        }
    }

</script>

{#if error}
    <AlertError message={translations.notFoundResource} type="error"/>
{:else}

<Headline text={translations.subjobs}/>

{#if !dataLoaded}
    <Spinner />
{:else}

<div class="view-parent-div">
        <div class="top-div-tender-components">
            <!-- Checkbox um alle Jobs zu checken -->
            <Checkbox 
            mixedState={$checkedSubjobsStore.length > 0 && $checkedSubjobsStore.length < calculateTotalSubjobsLength(jobsData)} 
            checked={allSubjobsChecked} 		
            on:click={() => toggleAllSubjobs(jobsData)} 
            label={translations.chooseAll}
            />
            <p>{checkedSubjobsCount} {translations.subjobs} {translations.selected} </p>
        </div>

        <div class="tender-all-checkboxes">
            {#each jobsData as job }
                <div class="tender-subjob-checkbox-div{ job.finished ? ' green' : ''} ">
                    <!-- Checkbox für Hauptjob / Unterjob -->
                    <Checkbox 
                    checked={false} 
                    bind:group={$checkedSubjobsStore} 
                    label={job.title} 
                    indexProp={job.jobId}
                    subLabel={job.dateTimespan}
                    personnelRequirements={{
                        staffBooked: job.staffBooked,
                        staffEntered: job.staffEntered,
                        staffNeeds: job.staffNeeds,
                        staffRequested: job.staffRequested,
                    }}
                    />
                    
                </div>

                {#if job.subjobData &&  job.subjobData.length > 0}
                    <div class="margin-left-30">
                        <span class="pointer-cursor-tender" 
                            on:click={() => toggleSubjobs(job.jobId)}
                            on:keydown={() => toggleSubjobs(job.jobId)}
                            tabindex="0"
                            role="button"
                        >
                            {#if $showSubjobs.get(job.jobId)}
                              <FontAwesomeIcon icon={faAngleDown} class="new-icon-color"/>
                            {:else}
                              <FontAwesomeIcon icon={faAngleRight} class="new-icon-color"/>
                            {/if}
                        </span>
                        <!-- Checkbox um alle subjobs des Hauptjobs zu checken-->
                        <Checkbox 
                        mixedState={
                            job.subjobData.some(subjob => $checkedSubjobsStore.includes(subjob.jobId)) && 
                            !job.subjobData.every(subjob => $checkedSubjobsStore.includes(subjob.jobId))
                        } 
                        checked={allSubjobsChecked || job.subjobData.every(subjob => $checkedSubjobsStore.includes(subjob.jobId))} 
                        on:click={() => checkAllSubjobs(job.subjobData)} 
                        label={translations.chooseAll}
                        />
                        <Checkbox 
                        mixedState={
                            job.subjobData.filter(subjob => !subjob.finished).some(subjob => $checkedSubjobsStore.includes(subjob.jobId)) && 
                            !job.subjobData.filter(subjob => !subjob.finished).every(subjob => $checkedSubjobsStore.includes(subjob.jobId))
                        } 
                        checked={allSubjobsChecked || job.subjobData.filter(subjob => !subjob.finished).every(subjob => $checkedSubjobsStore.includes(subjob.jobId))} 
                        on:click={() => checkAllSubjobs(job.subjobData, true)} 
                        label={translations.chooseAllUnfinished}
                        />
                    </div>

                    {#if !$showSubjobs.get(job.jobId)}
                        {#each job.subjobData as subJob }
                            <!-- Checkbox für job in Subjobs -->
                            <div class="margin-left-60 tender-subjob-checkbox-div{ subJob.finished ? ' green' : ''}">
                                <Checkbox 
                                checked={false} 
                                bind:group={$checkedSubjobsStore} 
                                label={subJob.title} 
                                subLabel={subJob.dateTimespan}
                                indexProp={subJob.jobId}
                                personnelRequirements={{
                                    staffBooked: subJob.staffBooked,
                                    staffEntered: subJob.staffEntered,
                                    staffNeeds: subJob.staffNeeds,
                                    staffRequested: subJob.staffRequested,
                                }}
                                />
                            </div>
                        {/each}
                    {/if}
                {/if}
            {/each}
        </div>
</div>
{/if}
{/if}
