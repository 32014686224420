<script lang="ts">
  export let message: string;
  export let type = 'info';

  let alertClass = '';

  $: {
    if (type === 'info') {
      alertClass = 'alert-box-info';
    }
    else if (type === 'warning') {
      alertClass = 'alert-box-warning';
    } else if (type === 'error') {
      alertClass = 'alert-box-error';
    }
  }
</script>

<div class="alert-box-overlay">
  <div class="alert-box {alertClass}">
    {message}
  </div>
</div>