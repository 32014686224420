import { writable } from 'svelte/store';
import { CellObject, SourceNode } from '../types';

export function resetStores() {
    status.set('angefragt');
    isMouseDown.set(false);
    selectedCellsStore.set([]);
    displaySelectStatus.set(false);
    cellsArrayStore.set([]);
    serviceProviderQuantityStore.set(null);
}

export const jobResourcesJS = writable([]);
export let status = writable<string>('angefragt');
export let isMouseDown = writable<boolean>(false);
export const selectedCellsStore = writable([]);
export const displaySelectStatus = writable(false);
export const contextMenuVisibleStore = writable();
export const useNewStaffplanStore = writable();
export const sourceNodeStore = writable<SourceNode>();
export const cellsArrayStore = writable<CellObject[]>([]);
export const isCategoriesCheckedStore = writable<boolean>();
export const isOnlyFutureJobsViewStore = writable<boolean>();
export const displayServiceProviderQuantityStore = writable(false);
export const serviceProviderQuantityStore = writable(null);

export let saveButtonClickedStore = writable(false);
export let cancelButtonClickedStore = writable(false);
