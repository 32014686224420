import { EmployeeType } from '../../svelte';

export const getRefRow = (
	tbody: HTMLTableSectionElement,
	row: HTMLTableRowElement,
	prevRefRow: HTMLTableRowElement,
	{ target, pageY }: DragEvent
): HTMLTableRowElement => {
	var dragRow = (target as Element).closest('tr');

	if (prevRefRow && dragRow === row) {
		return prevRefRow;
	}

	if (!dragRow) {
		return;
	}

	if (dragRow.closest('table').classList.contains('kommentar')) {
		dragRow = dragRow.closest('table').closest('tr');
	}

	if (dragRow.parentElement.tagName.toUpperCase() === 'THEAD') {
		return tbody.querySelector('tr');
	}

	const { top, height } = dragRow.getBoundingClientRect();
	if (pageY > top + height / 2) {
		return dragRow.nextElementSibling as HTMLTableRowElement;
	}

	return dragRow;
};

const findPrev = (ref: Element, selector: string) => {
	while ((ref = ref.previousElementSibling)) {
		if (ref.matches(selector)) {
			return ref;
		}
	}
};

export const getCategoryId = (row: HTMLTableRowElement) => {
	const category = findPrev(row, '[data-kategorie]');
	return category ? Number(category.getAttribute('data-kategorie')) || 0 : 0;
};

export type Employee = { id: number; type: EmployeeType; name: string; deleted: boolean };

export type ChangeClassNameAndAddToBackendData = (
	e: Event,
	terminid: number,
	zugehoerigkeit: string,
	zugehoerigkeitid: number,
	kategorien?: any
) => void;

export const getEmployees = (container: Element) => {
	const employees: Employee[] = [];

	for (const row of container.querySelectorAll('tr[data-id]')) {
		employees.push({
			id: Number(row.getAttribute('data-id')),
			type: row.getAttribute('data-type') as EmployeeType,
			name: row.getAttribute('data-name'),
			deleted: Boolean(row.getAttribute('data-deleted')),
		});
	}

	return employees;
};

export const employeeFinder = ({ id, type, name }: Employee) =>
	id === 0
		? (employee: Employee) => employee.id === 0 && employee.name === name && !employee.deleted
		: (employee: Employee) => employee.id === id && employee.type === type && !employee.deleted;
