<script lang="ts">
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  export let text : string = null;
  export let leadingIcon : any = null;
  export let trailingIcon : any = null;
  export let mainIcon : any = null;
  export let buttonClass : string = null;
  export let iconClass : string = null;
  export let disabled : boolean = null;
  export let type : string = null;
  export let accent : boolean = false;
  

  const upperCaseText = text?.toUpperCase();
  $: className = "new-button ";


  $: {
    switch(type) {
      case 'primary':
        if(accent) {
          className += " button-primary-accent ";
        } else {
          className += " button-primary-regular ";
        }
      break;
      case 'secondary':
        if(accent) {
          className += " button-secondary-accent ";
        } else {
          className += " button-secondary-regular ";
        }
      break;
      case 'tertiary':
          className += " button-tertiary-regular ";
      break;
      }
    }
</script>

<button class={className + buttonClass} disabled={disabled}  on:click>
    {#if leadingIcon}
      <FontAwesomeIcon icon={leadingIcon} class={iconClass}  />
    {/if}
  
    {#if mainIcon}
      <FontAwesomeIcon icon={mainIcon} class={iconClass} />
    {:else}
      <span>{upperCaseText}</span>
    {/if}
  
    {#if trailingIcon}
      <FontAwesomeIcon icon={trailingIcon} class={iconClass}  />
    {/if}
</button>

