import Sort from 'icon:sort';
import Times from 'icon:times';
import $ from 'jquery';
import translate from '../../i18n';
import { EmployeeType } from '../../svelte/Event';
import { addRequestableModifier, onDragEmployee } from '../../svelte/Event/EmployeeRequest';
import type { View } from '../../utils/element-router';
import listen from '../../utils/listen';
import {
	Employee,
	employeeFinder,
	getCategoryId,
	getEmployees,
	getRefRow
} from './helpers';
import { useNewStaffplanStore, serviceProviderQuantityStore, displayServiceProviderQuantityStore, saveButtonClickedStore, cancelButtonClickedStore } from '../../svelte/Event/Staffplan/stores/stores';
import { addRowToStaffplanOnDrop, findSubEmployees } from '../../svelte/Event/Staffplan/utils/addRowToStaffplanOnDrop';

let subcontractorSubEmployeeLink: string;

const validTypes = [
	EmployeeType.Employee,
	EmployeeType.Freelancer,
	EmployeeType.ServiceProvider,
	EmployeeType.Vehicle,
	EmployeeType.Room,
];


let useNewStaffplan: boolean;
useNewStaffplanStore.subscribe((value: boolean) => useNewStaffplan = value);

let serviceProviderQuantity: number;
serviceProviderQuantityStore.subscribe((value: number) => serviceProviderQuantity = value);

async function handleSaveAndCancelPromises() {
    return new Promise<void>((resolve, reject) => {

        saveButtonClickedStore.subscribe(value => {
            if (value === true) {
                resolve();
            }
        });

        cancelButtonClickedStore.subscribe(value => {
            if(value === true) {
                reject();
            }
        });
    });
}




const StaffPlanView: View = ({ container }) => {
	const employees: Employee[] = getEmployees(container);

	const getEmployeeIndex = (employee: Employee) => employees.findIndex(employeeFinder(employee));

	const getSubcontractorSubEmployeeLink = async (z: String, zid: Number, categoryId: Number, rowIndex: Number) => {
		({ subcontractorSubEmployeeLink } = await $.post(location.href, {
			silent: true,
			package: 'TerminEmployeeRequest',
			ajax: 'getSubcontractorSubEmployeeLink',
			ressourceid: zid,
			ressourcetype: z,
			categoryid: categoryId,
			rowindex: rowIndex,
		}));
		return subcontractorSubEmployeeLink;
	};

	const isRequestable = ({ id, type, name }: Employee) => validTypes.includes(type);

	const requestableModifier = addRequestableModifier(isRequestable);

	const destroyers = [
		requestableModifier.delete,

		onDragEmployee(({ id, type, free, name }) => {
			if (!isRequestable({ id, type, name })) {
				return false;
			}

			const categoryCheckbox = container.querySelector('input[name="personalplan-kategorien"]') as HTMLInputElement;
			const categories = categoryCheckbox && categoryCheckbox.checked;

			const target = container.querySelector('.event-details__content') as HTMLDivElement;
			target.classList.add('employee-drop--active');

			let tbody: HTMLTableSectionElement;
			let row: HTMLTableRowElement;
			let prevRefRow: HTMLTableRowElement;
			let unlisten: () => void;

			const leave = () => {
				if(useNewStaffplan && type === 'D') {
					unlisten && unlisten();
					target.classList.remove('employee-drop--dragover');
					row.classList.remove('employee-drop__target');				
				} else {
					unlisten && unlisten();
					row && row.parentElement && tbody.removeChild(row);
					target.classList.remove('employee-drop--dragover');
				}
			};

			return {
				target,
				leave,

				destroy() {
					leave();
					target.classList.remove('employee-drop--active');
				},

				enter() {
					target.classList.add('employee-drop--dragover');
					if (!row) {
						tbody = target.querySelector('tbody');
						row = document.createElement('tr');
						row.className = 'employee-drop__target';
						row.innerHTML = '<td colspan="1337"></td>';
					}

					tbody.appendChild(row);

					unlisten = listen(target, 'dragover', event => {
						const refRow = getRefRow(tbody, row, prevRefRow, event);
						if (prevRefRow !== refRow) {
							prevRefRow = refRow;
							try {
								tbody.insertBefore(row, refRow);
							} catch {	
								tbody.insertBefore(row, refRow.nextSibling);
							}
						}
					});
				},
			
				async drop() {
					// Logic for : dropping a resource from the employee-request dialog into the new-staff-plan
					if (useNewStaffplan) {
                        const categoryId = getCategoryId(row);
						if(type === 'D') {
							displayServiceProviderQuantityStore.set(true);
							try {
							// Only execute addRowToStaffplanOnDrop once Save in <ServiceProviderQuantity /> has been pressed
								await handleSaveAndCancelPromises();
								let subEmployees = await findSubEmployees(id);
								let serviceProviderQuantityArray = Array(serviceProviderQuantity).fill(null);
							
								serviceProviderQuantityArray.forEach(() => {
									addRowToStaffplanOnDrop(row,target,id,name,type,tbody,prevRefRow,categoryId, 0, subEmployees);
								});
								
								saveButtonClickedStore.set(false);
								serviceProviderQuantityStore.set(null);

								row && row.parentElement && tbody.removeChild(row);
							} catch(error) {
								cancelButtonClickedStore.set(false);
								row && row.parentElement && tbody.removeChild(row);
							}
						
							 
						} else {
							addRowToStaffplanOnDrop(row,target,id,name,type,tbody,prevRefRow,categoryId, null, []);
						};
						requestableModifier.updated();	
					}
					// use PHP Staffplan here
					else  {
						const categoryId = getCategoryId(row);
						const indexInput = target.querySelector('input[name="personalplan-anzahl"]') as HTMLInputElement;
						let index = Number(indexInput.value);

						const newRow = document.createElement('tr');
						newRow.id = 'personalplan-mitarbeiter-' + index;
						newRow.innerHTML = `
								<td>
									<div class="name-and-icons">
										<span></span>
										<button type="button" class="button icon-button" aria-label="${translate('delete')}">${Times()}</button>
									</div>
								</td>
							`;

						if (categories) {
							const dnd = document.createElement('td');
							dnd.className = 'dragndrop icon';
							dnd.innerHTML = `${Sort({ class: 'blue' })}`;
							newRow.appendChild(dnd);
						}

						destroyers.push(
							listen(newRow, 'click', ({ target }) => {
								if ((target as Element).closest('button')) {
									newRow.parentElement.removeChild(newRow);

									const index = getEmployeeIndex({ id, type, name });
									if (index !== -1) {
										employees.splice(index, 1);
										requestableModifier.updated();
									}
								}
							})
						);

						target
							.querySelector('thead tr')
							.querySelectorAll('th')
							.forEach((cell, i) => {
								if ((categories && i < 2) || (!categories && i < 1)) {
									return;
								}

								if (cell.classList.contains('space')) {
									const td = document.createElement('td');
									if (cell.classList.contains('black')) td.className = 'space black';
									else td.className = 'space';
									newRow.append(td);
									return;
								}

								var freierMitarbeiter;
								if (free) {
									freierMitarbeiter = 'data-freiermitarbeiter="' + name + '"';
								}

								index++;
								const td = document.createElement('td');
								td.className = 'center';
								td.innerHTML = `
										<label>
											<input
												type="checkbox"
												class="hidden"
												name="personalplan-check-${index}"
												data-i="${index}"
												data-temaid="neu"
												data-terminid="${cell.getAttribute('data-terminid')}"
												data-zugehoerigkeit="${type}"
												data-zugehoerigkeitid="${id}"
												data-subemployee="0" 
												data-kategorieid="${categoryId}"
												${freierMitarbeiter}
											/>
										</label>
									`;
								newRow.appendChild(td);

								if (free) {
									const nameInput = document.createElement('input');
									nameInput.type = 'hidden';
									nameInput.name = 'personalplan-freiermitarbeiter-' + index;
									nameInput.value = name;
									td.appendChild(nameInput);
								}
							});

						let subcontractorSubEmployeeLink =
							type == EmployeeType.ServiceProvider
								? await getSubcontractorSubEmployeeLink(type, id, categoryId, index)
								: '';
						indexInput.value = String(index);
						if (subcontractorSubEmployeeLink != '') {
							newRow.querySelector('span').innerHTML = subcontractorSubEmployeeLink;
						} else {
							newRow.querySelector('span').textContent = name;
						}
						try {
							tbody.insertBefore(newRow, prevRefRow);
						} catch {
							tbody.insertBefore(newRow, prevRefRow.nextSibling);
						}
						// @ts-expect-error Property 'tableDnDUpdate' does not exist on type 'JQuery<HTMLTableElement>'.
						$(tbody.closest('table')).tableDnDUpdate();

						employees.push({ id, type, name });
						requestableModifier.updated();
					}
				},
			};
		}),
	];

	return () => {
		for (const destroyer of destroyers) {
			destroyer();
		}
	};
};

export default StaffPlanView;
