import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import createFocusTrap, { FocusTrap } from 'focus-trap';
import $ from 'jquery';
import { afterRequest, sendForm } from '../legacy/index';
import createCustomEvent from '../utils/create-custom-event';
import listen, { once } from '../utils/listen';
import { onInit } from '../utils/subscriptions';
import supportsPassiveEventListener from '../utils/supports-passive-event-listener';
import createSpinner from './spinner';

interface HTMLElementWithBubble extends HTMLElement {
	bubble?: Bubble;
}

export interface LazyOptions {
	url: string;
	data: {
		ajax: string;
		mode?: any;
		package?: string;
	};
}

interface BubbleOptions {
	anchor: HTMLElement;
	closeOnMouseLeave?: boolean;
	element?: HTMLElementWithBubble | null;
	forceReload?: boolean;
	lazy?: LazyOptions;
	//name: string;
	trapFocus?: boolean;
}

const CLASS = 'bubble';
const CLASS_CONTENT = 'content';
const CLASS_CLOSE = 'close';
const CLASS_OPENED = 'opened';
const CLASS_OPENED_ANCHOR = 'bubble-opened';
const CLASS_DATEPICKER = 'Zebra_DatePicker';
const CLASS_TIMEPICKER = 'ui-timepicker-wrapper';
const ID_AJAX_HINT = 'ajax-hinweis';
const ID_FOREIGN_SELECTOR = 'fremdidauswahl';
const DELAY_MOUSE_ENTER = 1000;
const DELAY_MOUSE_LEAVE = 500;
const PADDING_X = 16;
const PADDING_Y = 16;
const SPACING_X = 56;
const SPACING_Y = 56;

// const bubbles: Map<string, Bubble> = new Map();

// export const get = (name: string) => bubbles.get(name);

onInit(doc => {
	doc
		.on('click', '[data-bubble]', function (this: HTMLElementWithBubble, event) {
			if (this instanceof HTMLInputElement && this.type == 'radio') {
				// do not prevent default behavior
			} else {
				event.preventDefault();
			}

			const bubble = this.bubble || (this.bubble = create(this));
			const unsubscribe = bubble.subscribe(({ opened }) => {
				this.classList[opened ? 'add' : 'remove'](CLASS_OPENED_ANCHOR);
				if (opened) {
					if (this.getAttribute('data-bubble') === 'terminanfrage') {
						const category = this.getAttribute('data-kategorie');
						if (category) {
							var skillLevel = this.getAttribute('data-skilllevel') as string;
							$('input[data-field=skilllevel]', bubble.element).val(skillLevel).trigger('change');
							$('select[data-field=kategorie-1]', bubble.element).val(category).trigger('change');
							$('select.kategorie', bubble.element).val(category).trigger('change');
							$('a[data-folder]', bubble.element).attr('data-kategorie', category);
							$('input.mitarbeiteranfragen', bubble.element).prop('checked', false);
						}
					}
				} else {
					unsubscribe();
					const unlisten = listen(this, 'click', event => {
						event.preventDefault();
						event.stopPropagation();
						event.stopImmediatePropagation();
					});
					setTimeout(unlisten, 200);
				}
			});

			bubble.open();
		})

		.on('mouseenter', '[data-hover-bubble]', function (this: HTMLElementWithBubble) {
			const bubble = this.bubble || (this.bubble = create(this, 'data-hover-bubble'));
			const unsubscribe = bubble.subscribe(({ opened }) => {
				unsubscribe();
				this.classList[opened ? 'add' : 'remove'](CLASS_OPENED_ANCHOR);
			});

			once(this, 'mouseleave', () => bubble.close(DELAY_MOUSE_LEAVE));

			bubble.open(((this.getAttribute('data-hover-time') as unknown) as number | null) || DELAY_MOUSE_ENTER);
		})

		.on('mouseenter', '[data-hovertitle]', function (this: HTMLElement) {
			createHoverTitle(this, 'mouseleave');
		})

		.on('focus', '[data-hovertitle]', function (this: HTMLElement) {
			createHoverTitle(this, 'blur');
		});
});

const createHoverTitle = (anchor: HTMLElement, hideEvent: 'mouseleave' | 'blur'): void => {
	const element = document.createElement('div');
	element.setAttribute('data-no-trap', 'true');
	element.className = 'bubble ' + anchor.getAttribute('data-hovertitle-class');
	element.innerHTML = `<p>${anchor.getAttribute('data-hovertitle')}</p>`;
	document.body.appendChild(element);

	const bubble = new Bubble({
		anchor,
		element,
		trapFocus: false,
	});

	once(anchor, hideEvent, () => {
		bubble.close();
		setTimeout(() => {
			document.body.removeChild(element);
		}, 200);
	});

	bubble.open();
};

const create = (anchor: HTMLElement, nameAttribute: string = 'data-bubble'): Bubble => {
	const name = anchor.getAttribute(nameAttribute);
	if (!name) {
		throw new Error('Parameter `name` must be a non-empty string');
	}

	const element = document.getElementById(name);

	let lazy;
	let forceReload = false;
	if (element) {
		const ajax = element.getAttribute('data-ajax');
		if (ajax) {
			let url = element.getAttribute('data-url');
			if (!url) {
				url = location.href;
			} else if (url === 'use-caller-url' && !(url = anchor.getAttribute('href'))) {
				throw new Error('Used `use-caller-url` on element without `href` attribute');
			}

			forceReload = element.getAttribute('data-forcereload') === 'true';
			lazy = {
				url,
				data: {
					ajax,
					package: element.getAttribute('data-package') || undefined,
					mode: anchor.getAttribute('data-mode') || element.getAttribute('data-mode') || undefined,
				},
			};
		}
	}

	return new Bubble({
		anchor,
		closeOnMouseLeave: nameAttribute === 'data-hover-bubble',
		element,
		forceReload,
		lazy,
		//name,
	});
};

export default class Bubble {
	//public readonly name: string;
	public element: HTMLElement;
	private anchor: HTMLElement;
	private trapFocus: boolean = true;
	private closeOnMouseLeave: boolean = false;
	private lazy?: LazyOptions;
	private forceReload: boolean = false;
	private timeout?: number;
	private _opened: boolean = false;
	private loaded: boolean = false;
	//private loading?: JQuery.jqXHR<string>;
	private loading?: Promise<string>;
	private focusTrap?: FocusTrap;
	private listeners: (() => void)[] = [];
	private subscribers: ((state: { opened: boolean }) => any)[] = [];

	private clearTimeout = (): void => {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = undefined;
		}
	};

	private position = (): void => {
		const { anchor, element } = this;
		element.style.cssText = '';

		const { top, height, left, width } = anchor.getBoundingClientRect();
		const { innerHeight, innerWidth } = window;

		const getWidth = (element: HTMLElement) => {
			const width = element.scrollWidth;
			const firstChild = element.firstElementChild;
			if (firstChild) {
				const childWidth = firstChild.scrollWidth;
				if (childWidth > width) {
					return childWidth;
				}
			}

			return width;
		};

		const elementHeight = Array.from(element.children).reduce((acc, element) => acc + element.scrollHeight, PADDING_Y);
		const elementWidth = getWidth(element) + PADDING_X;
		let elementTop = top + height;
		let elementLeft = left + width / 2 - elementWidth / 2;
		let originY;
		let originX;
		let maxHeight;
		let maxWidth;

		const positionTo = element.getAttribute('data-positionto');
		const spaceBelow = innerHeight - elementTop;
		if (spaceBelow < elementHeight + SPACING_Y && top * 0.9 > spaceBelow || positionTo == 'bottom' ) {
			if (top > elementHeight + SPACING_Y) {
				elementTop = top - elementHeight;
			} else {
				elementTop = SPACING_Y;
			}
			originY = 'bottom';
			maxHeight = `calc(100vh - ${elementTop + SPACING_Y}px)`;
		} else {
			originY = 'top';
			maxHeight = `calc(100vh - ${elementTop + SPACING_Y}px)`;
		}

		if (elementLeft < SPACING_X) {
			elementLeft = SPACING_X;
			originX = `${left + width / 2 - elementLeft}px`;
			maxWidth = `calc(100vw - ${SPACING_X * 2}px)`;
		} else if (elementLeft + elementWidth > innerWidth - SPACING_X) {
			elementLeft = innerWidth - elementWidth - SPACING_X;
			originX = `${left + width / 2 - elementLeft}px`;
			maxWidth = `calc(100vw - ${elementLeft + SPACING_X}px)`;
		} else {
			originX = `${left + width / 2 - elementLeft}px`;
			maxWidth = `calc(100vw - ${elementLeft + SPACING_X}px)`;
		}

		let parentBubble = element;
		while (parentBubble.parentElement != null
				&& parentBubble.parentElement != undefined
				&& (parentBubble = parentBubble.parentElement!.closest(`.${CLASS}`) as HTMLElement)) {
			const { top, left } = parentBubble.getBoundingClientRect();
			elementTop -= top;
			elementLeft -= left;
		}

		element.style.cssText = `
      top: ${elementTop}px;
      left: ${elementLeft}px;
      max-height: ${maxHeight};
      max-width: ${maxWidth};
      transform-origin: ${originX} ${originY};
	  z-index: ${$.topZIndex()};
    `;
	};

	private load = async () => {
		if (!this.lazy) {
			return;
		}

		if (this.loaded && !this.forceReload) {
			return;
		}

		if (this.loading) {
			return this.loading;
		}

		const { element } = this;
		element.innerHTML = createSpinner();
		this.position();

		let url = element.getAttribute('data-url');
		if (!url) {
			this.lazy.url = location.href;
		}

		//this.loading = $.post(this.lazy);
		this.loading = new Promise(resolve => {
			const formName = element.getAttribute('data-sendform');
			const form = formName ? document.querySelector(`form[name="${formName}"]`) : null;
			if (form && form.getAttribute('data-submit') !== 'no-ajax') {
				element.setAttribute('data-clicked', '');
				sendForm(form, $(element), () => {
					resolve($.post(this.lazy));
				});
			} else {
				resolve($.post(this.lazy));
			}
		});

		try {
			element.innerHTML = await this.loading;
			afterRequest($(this.element));
		} catch (e) {}

		this.loaded = true;
		this.loading = undefined;
	};

	private _open = async () => {
		if (this._opened) {
			return;
		}

		this._opened = true;

		const load = this.load();
		const { element } = this;

		const listeners = [
			listen(window, 'resize', this.position, supportsPassiveEventListener ? { passive: true } : false),
			listen(
				document,
				'click',
				event => {
					const closeElement = (event.target as Element).closest(`.${CLASS} .${CLASS_CLOSE}`) as HTMLLinkElement;
					const closestBubble = closeElement ? closeElement.closest(`.${CLASS}`) : null;
					if ( closestBubble && element.querySelector('[data-bubble='+closestBubble.getAttribute('id')+']') != null ) {
						return;
					}
					if (
						(closeElement && closestBubble === element) ||
						(!element.contains(event.target as Element) &&
							!(event.target as Element).closest(`.${CLASS_DATEPICKER}`) &&
							!(event.target as Element).closest(`.${CLASS_TIMEPICKER}`) &&
							!(event.target as Element).closest(`#${ID_AJAX_HINT}`) &&
							!(event.target as Element).closest(`#${ID_FOREIGN_SELECTOR}`) &&
							(event.target as Element).firstElementChild?.id !== ID_FOREIGN_SELECTOR)
					) {
						if (closeElement) {
							if (closeElement.href === '#') {
								event.preventDefault();
							}
						}

						this.close();
					}
				},
				true
			),

			listen(
				document,
				'keydown',
				event => {
					if (
						(event as KeyboardEvent).key === 'Escape' ||
						(event as KeyboardEvent).key === 'Esc' ||
						(event as KeyboardEvent).keyCode === 27
					) {
						event.preventDefault();
						event.stopPropagation();
						event.stopImmediatePropagation();
						this.close();
					}
				},
				true
			),
		];

		if (this.closeOnMouseLeave) {
			listeners.push(listen(element, 'mouseenter', this.clearTimeout));
			listeners.push(listen(element, 'mouseleave', () => this.close(DELAY_MOUSE_LEAVE)));
		}

		this.listeners = listeners;

		element.classList.add(CLASS_OPENED);

		await load;
		if (!this.opened) {
			return;
		}

		this.position();

		if (this.trapFocus) {
			if (!this.focusTrap) {
				this.focusTrap = createFocusTrap(element, {
					onDeactivate: this._close,
					escapeDeactivates: false,
					allowOutsideClick: event => true,
				});
			}

			this.focusTrap.activate();
			disableBodyScroll(element, { reserveScrollBarGap: true });
		}

		element.dispatchEvent(createCustomEvent('bubble-open'));
		this.subscribers.forEach(callback => callback({ opened: true }));

		setTimeout(() => {
			if (element) {
				const content = element.querySelector(`.${CLASS_CONTENT}`);
				if (content) {
					content.scrollTop = 0;
				}
			}
		}, 0);
	};

	private _close = (): void => {
		if (this.loading) {
			//this.loading.abort();
			this.loading = undefined;
		}

		if (!this._opened) {
			return;
		}

		const { element } = this;

		this._opened = false;
		this.listeners.forEach(unlisten => unlisten());
		element.classList.remove(CLASS_OPENED);

		if (this.trapFocus) {
			this.focusTrap?.deactivate();
			enableBodyScroll(element);
		}

		element.dispatchEvent(createCustomEvent('bubble-close'));
		this.subscribers.forEach(callback => callback({ opened: false }));
	};

	public constructor({ anchor, closeOnMouseLeave, element, forceReload, lazy /*, name*/ }: BubbleOptions) {
		//this.name = name;
		this.anchor = anchor;
		this.lazy = lazy;

		if (element) {
			this.trapFocus = element.getAttribute('data-no-trap') !== 'true' && ! ['kalender-filtern','untertermine-filtern'].includes(element.id);

			const closestForm = element.closest('form');
			if (closestForm && closestForm !== element) {
				closestForm.appendChild(element);
			}
		} else {
			element = document.createElement('div');
			element.className = CLASS;
			if (lazy && lazy.url) {
				element.setAttribute('data-url', lazy.url);
			}
			document.body.appendChild(element);
			this.element = element;
		}

		element.bubble = this;
		this.element = element;

		if (closeOnMouseLeave) {
			this.closeOnMouseLeave = true;
		}

		if (forceReload) {
			this.forceReload = true;
		}

		//bubbles.set(name, this);
	}

	public get opened() {
		return this._opened;
	}

	public set opened(flag: boolean) {
		this[flag ? '_open' : '_close']();
	}

	public open(delay?: number): void {
		this.clearTimeout();

		if (delay && delay > 0) {
			this.timeout = window.setTimeout(this._open, delay);
		} else {
			this._open();
		}
	}

	public close(delay?: number): void {
		this.clearTimeout();

		if (delay && delay > 0) {
			this.timeout = window.setTimeout(this._close, delay);
		} else {
			this._close();
		}
	}

	public toggle(): void {
		this[this._opened ? 'close' : 'open']();
	}

	public subscribe(callback: (state: { opened: boolean }) => any) {
		if (!this.subscribers.includes(callback)) {
			this.subscribers.push(callback);
		}

		return () => {
			const index = this.subscribers.indexOf(callback);
			if (index) {
				this.subscribers.splice(index, 1);
			}
		};
	}

	public reset(): void {
		if (!this.lazy) {
			return;
		}

		try {
			this.element.innerHTML = '';
		} catch (e) {}

		this.loaded = false;
		this.loading = undefined;
	}
}
