<script lang="ts">
	import {faXmark} from "@fortawesome/free-solid-svg-icons";
	import NewButton from '../../../components/NewButton/NewButton.svelte';
	import Stepper from "../../../components/Stepper/Stepper.svelte";
	import { Translations } from '../types';
	export let translations: Translations;
	export let currentViewIdx: number;
	export let viewsTranslation : Array<string>;
    export let isSingleJob: boolean;

	function closeWindow() {
		const element = document.querySelector('.tendermail__content');
		if (element && element.parentNode) {
			const parentElement = element.parentNode as HTMLElement;

			parentElement.style.transition = "opacity 0.5s ease-out";
    		parentElement.style.opacity = "0";

    		setTimeout(() => {
    		    parentElement.remove();
    		}, 500);
			}
		}

</script>

<div class="top-div">
	<h2 class="tenderHead">{translations.tenderEmail}</h2>
	<NewButton mainIcon={faXmark} type={"tertiary"} on:click={closeWindow} />
</div>

<Stepper stepsArray={viewsTranslation} {isSingleJob} bind:currentViewIdx/>

