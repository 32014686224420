import Fuse, { FuseOptions } from 'fuse.js';
import ChevronLeft from 'icon:chevron-left';
import Times from 'icon:times';
import $ from 'jquery';
import translate from '../i18n';
import listen from '../utils/listen';
import { randomString } from '../utils/random';
import { onAfterNavigate, onInit } from '../utils/subscriptions';

type FuseItem = {
	element: Element;
	text: string;
};

type SearchItem = {
	element: Element;
	isHeadline: boolean;
	isSort?: boolean;
	hidden?: boolean;
};

type Search = {
	unlistenFocus?: () => any;
	unlistenInput?: () => any;
	items?: SearchItem[];
	fuse?: Fuse<FuseItem, FuseOptions<FuseItem>>;
	fuseNumeric?: Fuse<FuseItem, FuseOptions<FuseItem>>;
};

const ID = 'subnav';

const CLASS_HEADLINE = 'sub-navigation__headline';
const CLASS_ITEM = 'sub-navigation__item';
const CLASS_LIST = 'sub-navigation__list';
const CLASS_SEARCH = 'sub-navigation__search';
const CLASS_SORT = 'sub-navigation__sort';

const DROPDOWN_SEARCHABLE_MIN = 10;
const CLASS_SIMPLE_DROPDOWN = 'simple-dropdown';
const CLASS_SIMPLE_DROPDOWN_CONTENT = 'simple-dropdown__content';
const CLASS_SIMPLE_DROPDOWN_SEARCH = 'simple-dropdown__search';
const CLASS_SIMPLE_DROPDOWN_HEADLINE = 'simple-dropdown__headline';

const CLASS_SUBNAV = 'sub-navigation';
const CLASS_TOGGLE = 'subnav-toggle';
const CLASS_COLLAPSED = 'subnav-collapsed';

const LOCAL_STORAGE_KEY = 'sub-navigation';

const FUSE_OPTIONS: FuseOptions<FuseItem> = {
	shouldSort: true,
	threshold: 0.4,
	location: 0,
	distance: 100,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	keys: ['text'],
};
const FUSE_OPTIONS_NUMERIC: FuseOptions<FuseItem> = {
	shouldSort: true,
	threshold: 0.2,
	location: 0,
	distance: 100,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	keys: ['text'],
};

let searches: Search[] = [];

onInit(() => {
	createToggleButton();
	createSearches();
});

onAfterNavigate(({ target }) => {
	if (target !== ID) {
		return;
	}

	for (const { unlistenFocus, unlistenInput } of searches) {
		unlistenFocus && unlistenFocus();
		unlistenInput && unlistenInput();
	}

	searches = [];
	createSearches();
});

const createSearch = (searchField: HTMLInputElement, isSimpleDropdown: boolean = false) => {
	const search: Search = {};

	search.unlistenFocus = listen(searchField, 'focus', () => {
		search.unlistenFocus();
		search.unlistenFocus = undefined;

		const items: SearchItem[] = [];
		const list: FuseItem[] = [];

		let element: Element = searchField.parentElement;
		while ((element = element.nextElementSibling)) {
			if (isSimpleDropdown || element.classList.contains(CLASS_ITEM)) {
				const isHeadline = isSimpleDropdown
					? element.classList.contains(CLASS_SIMPLE_DROPDOWN_HEADLINE)
					: element.firstElementChild?.classList.contains(CLASS_HEADLINE);
				const isSort = !isSimpleDropdown && element.classList.contains(CLASS_SORT);
				items.push({ element, isHeadline, isSort });
				if (!isHeadline && !isSort) {
					if ( element.getAttribute('data-search') !== undefined && element.getAttribute('data-search') !== null ) {
						list.push({ element, text: element.getAttribute('data-search').trim() });
					} else {
						list.push({ element, text: element.textContent.trim() });
					}
				}
			}
		}

		search.items = items;
		search.fuse = new Fuse(list, FUSE_OPTIONS);
		search.fuseNumeric = new Fuse(list, FUSE_OPTIONS_NUMERIC);
	});

	const update = () => {
		const { items, fuse, fuseNumeric } = search;
		if (!items || !fuse) {
			return;
		}

		const filter = searchField.value.trim();
		var result;
		if ( isNaN(parseInt(filter)) ) {
			result = filter && (fuse.search(filter) as FuseItem[]).map(({ element }) => element);
		} else {
			result = filter && (fuseNumeric.search(filter) as FuseItem[]).map(({ element }) => element);
		}
		for (const item of items) {
			if (!filter || (!item.isHeadline && !item.isSort && result.includes(item.element))) {
				if (item.hidden) {
					item.hidden = false;
					item.element.removeAttribute('hidden');
				}
			} else if (!item.hidden) {
				item.hidden = true;
				item.element.setAttribute('hidden', 'true');
			}
		}
	};

	$(searchField).on('change', update);
	search.unlistenInput = listen(searchField, 'input', update);

	searches.push(search);
};

const createSearches = () => {
	const subNav = document.querySelector(`.${CLASS_SUBNAV}`);
	if (!subNav) {
		return;
	}

	const searchField = subNav.querySelector(`.${CLASS_LIST} .${CLASS_SEARCH}`) as HTMLInputElement;
	if (searchField) {
		createSearch(searchField);
	}

	for (const dropdown of subNav.querySelectorAll(`.${CLASS_SIMPLE_DROPDOWN}`)) {
		const content = dropdown.querySelector(`.${CLASS_SIMPLE_DROPDOWN_CONTENT}`);
		if (!content || content.childElementCount < DROPDOWN_SEARCHABLE_MIN) {
			continue;
		}

		const id = 'text-field--' + randomString(16);

		const searchField = document.createElement('input');
		searchField.id = id;
		searchField.type = 'text';
		searchField.className = 'text-field';
		searchField.placeholder = translate('searchPlaceholder');
		searchField.setAttribute('aria-label', translate('search'));

		const clearButton = document.createElement('button');
		clearButton.type = 'button';
		clearButton.className = 'button icon-button inputclear';
		clearButton.innerHTML = Times();
		clearButton.setAttribute('data-clearfor', id);

		const wrapper = document.createElement('div');
		wrapper.className = `${CLASS_SIMPLE_DROPDOWN_SEARCH} text-field--inputclear`;
		wrapper.appendChild(searchField);
		wrapper.appendChild(clearButton);

		content.insertBefore(wrapper, content.firstElementChild);

		createSearch(searchField, true);
	}
};

const createToggleButton = () => {
	const subNav = document.querySelector(`.${CLASS_SUBNAV}`);
	if (!subNav) {
		return;
	}

	let collapsed = false;
	try {
		if ((collapsed = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)!).collapsed)) {
			document.body.classList.add(CLASS_COLLAPSED);
		}
	} catch (e) {}

	const toggleButton = document.createElement('button');
	toggleButton.type = 'button';
	toggleButton.className = 'button icon-button ' + CLASS_TOGGLE;
	toggleButton.innerHTML = ChevronLeft();
	toggleButton.setAttribute('aria-label', translate(collapsed ? 'subnavExpand' : 'subnavCollapse'));

	listen(toggleButton, 'click', () => {
		const collapsed = document.body.classList.toggle(CLASS_COLLAPSED);
		toggleButton.setAttribute('aria-label', translate(collapsed ? 'subnavExpand' : 'subnavCollapse'));

		try {
			localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ collapsed }));
		} catch (e) {}
	});

	subNav.parentElement.insertBefore(toggleButton, subNav);
};
