<script lang="ts">
    import { get } from "svelte/store";
    import { RequestStatus, Translations } from "../types";
    import { status, selectedCellsStore, displaySelectStatus } from "../stores/stores";
    import Button from "../../../components/Button/Button.svelte";
	import { onDestroy } from "svelte";
    export let translations : Translations;

    let oldStatus = get(status);

    onDestroy(() => {
        status.set(oldStatus);
    });

    function clickOnCells() {
        $selectedCellsStore.forEach(element => element.classList.remove("selected-cell"))

        for (const element of $selectedCellsStore as HTMLElement[]) {
            if (!element.classList.contains($status)) { 
            element.click();
            }
        }
        displaySelectStatus.set(false);
    }

    function cancelSelection() {
        $selectedCellsStore.forEach(element => element.classList.remove("selected-cell"));
        displaySelectStatus.set(false);
    }
</script>

<div class="hinweis small" style="z-index: 5000;">
    <div class="hinweis__surface width-350-select-status" >
      <div class="header row">{translations.selectStatus}</div>
      <div class="select-status-inputs-parent">
        <label class="radio" data-testid="angefragt">
            <input on:click={() => status.set(RequestStatus.REQUESTED)} type="radio" name="selectAnfragevariante" checked />
            <div>
                <div class="mark" role="presentation" />
                <span>{translations.requested}</span>
                </div>
        </label>
        <label class="radio" data-testid="verfuegbar">
            <input on:click={() => status.set(RequestStatus.AVAILABLE)} type="radio" name="selectAnfragevariante" />
            <div>
                <div class="mark" role="presentation" />
                <span>{translations.available}</span>
            </div>
        </label>
        <label class="radio" data-testid="gebucht">
            <input on:click={() => status.set(RequestStatus.BOOKED)} type="radio" name="selectAnfragevariante" />
            <div>
                <div class="mark" role="presentation" />
                <span>{translations.booked}</span>
            </div>
        </label>
        <label class="radio" data-testid="abgelehnt">
            <input on:click={() => status.set(RequestStatus.REJECTED)} type="radio" name="selectAnfragevariante" />
            <div>
                <div class="mark" role="presentation" />
                <span>{translations.rejected}</span>
            </div>
        </label>
        <label class="radio space-bottom" data-testid="nv">
            <input on:click={() => status.set(RequestStatus.NV)} type="radio" name="selectAnfragevariante" />
            <div>
                <div class="mark" role="presentation" />
                <span>{translations.absent}/{translations.holiday}</span>
            </div>
        </label>
    </div>
      <div class="footer-select-status">
        <a on:click={cancelSelection} class="close">{translations.cancel}</a> 
        <Button primary={true} on:click={clickOnCells}> {translations.save} </Button>
      </div>
    </div>
  </div>


