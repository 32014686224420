<script>
    import { serviceProviderQuantityStore, displayServiceProviderQuantityStore, saveButtonClickedStore, cancelButtonClickedStore } from "../stores/stores";
    export let content;
    export let save;
    export let close;

    function saveChangesAndRemoveComponentFromDom() {
        saveButtonClickedStore.set(true);
        displayServiceProviderQuantityStore.set(false);

        const element = document.getElementById('service-provider-quantity-component');

        // Remove ServiceProviderQuantity from the "DOM"
        if (element && element.parentNode) {
            element.parentNode.removeChild(element);
        }
    };

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            saveChangesAndRemoveComponentFromDom();
        }
    };
</script>

    <div class="hinweis small ajax-content dark" id="service-provider-quantity-component" style="z-index: 5000;">
        <div class="hinweis__surface">
            <label class="content">
                <p>{content}</p> 
                <!-- svelte-ignore a11y-autofocus -->
                <input class="text-field" type="number" min="1" max="80" autofocus bind:value={$serviceProviderQuantityStore}  on:keydown={handleKeyPress}>
            </label> 
            <div class="footer">
                <div class="footer row">
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <!-- svelte-ignore a11y-no-static-element-interactions -->
                    <!-- svelte-ignore a11y-missing-attribute -->
                    <a class="close" on:click={() => {serviceProviderQuantityStore.set(null); cancelButtonClickedStore.set(true); displayServiceProviderQuantityStore.set(false)}}>{close}</a> 
                  </div>
                <button class="button button--primary" type="button" on:click={saveChangesAndRemoveComponentFromDom}> {save}</button>
            </div>
        </div>
    </div>

