<script lang="ts">
	import debounce from 'debounce';
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import translate from '../../../i18n';
	import listen from '../../../utils/listen';
	import type { AddEmployeeAction } from './types';

	export let action: AddEmployeeAction = 'request';

	const reset = debounce(() => {
		action = 'request';
	}, 60);

	const set = (act: AddEmployeeAction) => () => {
		reset.clear();
		action = act;
	};

	let container: HTMLDivElement;
	let translateY: number = 0;
	onMount(() => {
		const row = container.closest('tr');
		if (!row) {
			return;
		}

		return listen(parent, 'dragover', event => {
			const { height: rowHeight, top: rowTop } = row.getBoundingClientRect();
			const { height } = container.getBoundingClientRect();
			translateY =
				height > rowHeight ? 0 : Math.max(0, Math.min(event.pageY - rowTop - height / 2, rowHeight - height));
		});
	});
</script>

<div
	bind:this={container}
	class="employee-drop__target"
	data-action={action}
	style="transform: translateY({translateY}px)"
	transition:fade|global={{ duration: 100 }}
>
	<div class="employee-drop__target__request">{translate('employeeRequest')}</div>

	<div class="employee-drop__target__enter" on:dragover={set('enter')} on:dragleave={reset}>
		{translate('employeeEnter')}
	</div>

	<div class="employee-drop__target__book" on:dragover={set('book')} on:dragleave={reset}>
		{translate('employeeBook')}
	</div>
</div>
