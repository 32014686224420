<script lang="ts">
	import Spinner from '../../../components/Spinner/Spinner.svelte';
	import Checkbox from '../../../components/Checkbox/Checkbox.svelte';
	import Headline from '../components/Headline.svelte';
	import { Translations, Category } from '../types';
	import { getCategories } from '../utils/apis';
	import { selectedJobsStore, selectedCategoriesStore, checkedCategoriesStore, isProjectStore} from '../stores/stores';
	import AlertError from '../../../components/AlertError/AlertError.svelte';
	import logError from '../../../../utils/log-error';
	export let translations: Translations;
	export let mainJobId: number;
	export let currentViewIdx : number;
	
	let isProject: boolean;
	
	isProjectStore.subscribe(val => {
		isProject = val;
	})

	let isLoading = true;
	let selectedJobIds: number[] = [];
	let categoriesData: Category[] = [];
	let allCategoriesChecked = false;
	let noCategoriesFound = false;

	$: checkedCategoriesCount = $checkedCategoriesStore.length;
	$: allCategoriesChecked = $checkedCategoriesStore.length === categoriesData.length;

	$selectedJobsStore.forEach(job => {
		selectedJobIds.push(job.jobId);
	});
	
	$: {
		if (categoriesData.length > 0) {
			isLoading = false;
		}
	}

	$: {
		selectedCategoriesStore.set($checkedCategoriesStore.map(index => categoriesData[index]));
	}


	getCategories(mainJobId, selectedJobIds, isProject)
	    .then((data) => {
			if (data && data.length > 0) { 
        		categoriesData = isProject ? flattenObjectValues(data) : data;
        	}

        	if (categoriesData.length === 0) {
        	    noCategoriesFound  = true
        	}

	    	isLoading = false;
	    }).catch((err) => {		
			logError(`Error fetching categories, mainJobId: ${mainJobId}, selectedJobIds: ${selectedJobIds}, isProject: ${isProject}`, err.stack);
		});
	  

	
	function flattenObjectValues(data : Array<any>) {
	    return [].concat(...Object.values(data));
	}

	function toggleAllCategories() {
		allCategoriesChecked = !allCategoriesChecked;
		checkedCategoriesStore.set(allCategoriesChecked ? categoriesData.map((_, index) => index) : []);
	}
</script>

<Headline text={translations.categories}/>

{#if noCategoriesFound}
	<AlertError message={translations.tenderErrorNoCategories} type="warning"/>
{:else}
<div class="view-parent-div">
	<div class="top-div-tender-components">
			<div>
				<Checkbox 
				mixedState={$checkedCategoriesStore.length > 0 && $checkedCategoriesStore.length < categoriesData.length} 
				checked={allCategoriesChecked}
				label={translations.chooseAll} 
				on:click={toggleAllCategories} 
				/>
			</div>
		<p>{checkedCategoriesCount} {translations.categories} {translations.selected} </p>
	</div>

	{#if isLoading}
		<Spinner />
	{:else}
		<div class="tender-all-checkboxes">
			{#each categoriesData as category, index}
				<div>
					<Checkbox checked={false} bind:group={$checkedCategoriesStore} label={category.Description} indexProp={index}/>
				</div>
			{/each}
		</div>
	{/if}
</div>
{/if}
