import supportsOnceEventListeners from './supports-once-event-listener';

type CustomEventMap = {
	'before-send-form': CustomEvent<{ formData: FormData }>;
	'dialog-close': CustomEvent;
};

type EventMap<T> = T extends Window
	? WindowEventMap & CustomEventMap
	: T extends Document
	? DocumentEventMap & CustomEventMap
	: T extends HTMLElement
	? HTMLElementEventMap & CustomEventMap
	: GlobalEventHandlersEventMap & CustomEventMap;

const listen = <T extends Window | Document | HTMLElement, K extends keyof EventMap<T>>(
	node: T,
	type: K,
	listener: (this: T, event: EventMap<T>[K]) => any,
	options: boolean | AddEventListenerOptions = false
) => {
	// @ts-expect-error Argument of type 'K' is not assignable to parameter of type 'string'.
	node.addEventListener(type, listener, options);
	// @ts-expect-error Argument of type 'K' is not assignable to parameter of type 'string'.
	return () => node.removeEventListener(type, listener, options);
};

export const once = supportsOnceEventListeners
	? <T extends Window | Document | HTMLElement, K extends keyof EventMap<T>>(
			node: T,
			type: K,
			listener: (this: T, event: EventMap<T>[K]) => any,
			capture: boolean = false
	  ) => listen(node, type, listener, { capture, once: true })
	: <T extends Window | Document | HTMLElement, K extends keyof EventMap<T>>(
			node: T,
			type: K,
			listener: (this: T, event: EventMap<T>[K]) => any,
			capture: boolean = false
	  ) => {
			const unlisten = listen(
				node,
				type,
				function (...args) {
					unlisten();
					listener.apply(this, args);
				},
				capture
			);
			return unlisten;
	  };

export default listen;
