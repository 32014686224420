<script lang="ts">
	import cx from '../../../utils/class-names';

	let className: string = undefined;
	export { className as class };
	export let active: boolean = false;
	export let disabled: boolean = false;
	export let href: string | undefined = undefined;
	export let icon: typeof import('icon:*').default | undefined = undefined;
	export let iconClass: string | undefined = undefined;
	export let inline: boolean = false;
	export let link: boolean = false;
	export let primary: boolean = false;
	export let title: string | undefined = undefined;
	export let type: 'button' | 'submit' | 'reset' = 'button';


	$: classes = cx(
		'button',
		active && 'button--pink',
		primary && 'button--primary',
		link && 'button--link',
		inline && 'inline',
		className
	);
</script>

<svelte:options immutable />

{#if href && !disabled}
	<a aria-label={title} class={classes} {href} {...$$restProps} on:click>
		{#if icon}
			<svelte:component this={icon} class={iconClass} />
		{/if}

		<slot />
	</a>
{:else}
	<button aria-label={title} class={classes} {disabled} {type} {...$$restProps} on:click>
		{#if icon}
			<svelte:component this={icon} class={iconClass} />
		{/if}

		<slot />
	</button>
{/if}
