<script context="module" lang="ts">
	let scrollOnEdgeDrag: () => void;
</script>

<script lang="ts">
	import debounce from 'debounce';
	import Clock from 'icon:clock';
	import Copy from 'icon:copy';
	import Envelope from 'icon:envelope';
	import ExclamationTriangle from 'icon:exclamation-triangle';
	import MinusCircle from 'icon:minus-circle';
	import Print from 'icon:print';
	import Times from 'icon:times';
	import Plus from 'icon:plus';
	import Minus from 'icon:minus';
	import jquery from 'jquery';
	import { onMount } from 'svelte';
	import { escape } from 'svelte/internal';
	import { fade, slide } from 'svelte/transition';
	import translate from '../../../i18n';
	import { promptDialog } from '../../../utils/components';
	import getScrollParent from '../../../utils/get-scroll-parent';
	import listen from '../../../utils/listen';
	import { LazyBubble } from '../../components/Bubble';
	import Button from '../../components/Button';
	import Checkbox from '../../components/Checkbox';
	import IconButton from '../../components/IconButton';
	import Select from '../../components/Select';
	import Spinner from '../../components/Spinner';
	import StatusHolder from "../../components/StatusHolder";
	import { onDragEmployee } from '../EmployeeRequest';
	import { EmployeeType, EventType } from '../types';
	import DropTarget from './DropTarget.svelte';
	import { addToPath } from './helpers';
	import type { AddEmployeeAction, Category, Events, LoadSubEvents, UpdateEvent } from './types';
	import WorkerAmountPrompt from './WorkerAmountPrompt.svelte';

	export let bookedEmployees = undefined;
	export let timezone = undefined;
	export let requiredEmployees = undefined;

	export let canModify: boolean = false;
	export let canDelete: boolean = false;
	export let showNumbers: boolean = false;
	export let translations: Record<string, string>;

	export let update: UpdateEvent;
	export let loadPastSub: LoadSubEvents;
	export let loadMoreSub: LoadSubEvents;

	export let groupByDay: boolean;
	export let expandAll: boolean;
	export let expandedJobIds: number[];
	export let identifier: number;
	export let inset: boolean = false;

	export let canView: boolean = true;

	export let activity: string;
	export let date: string;
	export let time: string;
	export let duration: string;
	export let infos: string[];
	export let dateStart: number;
	export let dateEnd: number;

	export let recentChanges: boolean;
	export let type: EventType;
	export let id: number;
	export let parentId: number;
	export let staffingRequirementsSubjobs: null | string;
	export let formattedId: string;
	export let categoryColor: string;
	export let entered: boolean;
	export let booked: boolean;
	export let bookingStatus: null | { color: string; text: string };
	export let availability: null | 0 | 1;
	export let canChangeAvailability: boolean;

	export let inPast: boolean;
	export let billed: boolean;
	export let canceled: boolean;
	export let done: boolean;
	export let status: number;
	export let title: string;
	export let categories: Category[] = [];
	export let resourceNeeds: string;

	export let subEvents: Events = [];
	export let pastEvents: number;
	export let moreEvents: number;
	export let loadedPast: number = 0;

	let loadingPast: boolean = false;
	let loadingMore: boolean = false;
	$: loadedMore = subEvents.length;
	expandedJobIds = !expandedJobIds ? [] : expandedJobIds;

	const updateSub: UpdateEvent = props => update(addToPath(props, id));
	const loadPastSubSub: LoadSubEvents = props => loadPastSub(addToPath(props, id));
	const loadMoreSubSub: LoadSubEvents = props => loadMoreSub(addToPath(props, id));

	const loadPast = async () => {
		if (!loadingPast && pastEvents) {
			try {
				loadingPast = true;
				await loadPastSub({ eventId: id, start: loadedPast });
			} catch (error) {
				throw error;
			} finally {
				loadingPast = false;
			}
		}
	};

	const loadMore = async () => {
		if (!loadingMore && moreEvents) {
			try {
				loadingMore = true;
				await loadMoreSub({ eventId: id, start: loadedMore });
			} catch (error) {
				throw error;
			} finally {
				loadingMore = false;
			}
		}
	};

	let container: HTMLLIElement;
	let drag: boolean = false;
	let dragAlready: boolean = false;
	let dragName: string;
	let dragType: EmployeeType;
	let dragOver: boolean = false;
	let dragOverAction: AddEmployeeAction;
	let dragOverCategory: number = -1;

	const resetDragOverCategory = debounce(() => {
		dragOverCategory = -1;
	}, 60);

	const setDragOverCategory = (id: number) => () => {
		resetDragOverCategory.clear();
		dragOverCategory = id;
	};

	export const isExpandable = () => {
		return !expandAll && (categories.length > 0 || (!inset && subEvents.length > 0));
	};

	if (canView) {
		onMount(() =>
			onDragEmployee(
				({
					id: employeeId,
					type,
					free,
					name,
					employeePhone,
					vehicleSeats,
					vehiclePayload,
					roomNumberPeople,
					isOverlapping,
				}) => {
					if (
						type !== EmployeeType.ServiceProvider &&
						categories.find(({ employees }) =>
							employees.find(
								employeeId === 0
									? ({ entityId, name: entityName }) => entityId === 0 && entityName === name
									: ({ entityId, entityType }) => entityId === employeeId && entityType === type
							)
						)
					) {
						dragName = name;
						dragAlready = true;
						return {
							destroy() {
								dragAlready = false;
							},
						};
					}

					drag = true;
					dragType = type;

					if (!scrollOnEdgeDrag) {
						const scrollContainer = getScrollParent(container) as HTMLElement;
						const { top, height } = scrollContainer.getBoundingClientRect();
						scrollOnEdgeDrag = listen(scrollContainer, 'dragover', event => {
							const distanceTop = 64 - event.pageY + top;
							if (distanceTop > 0) {
								scrollContainer.scrollTop -= Math.min(distanceTop / 2, 8);
							} else {
								const distanceBottom = 64 - top - height + event.pageY;
								if (distanceBottom > 0) {
									scrollContainer.scrollTop += Math.min(distanceBottom / 2, 8);
								}
							}
						});
					}

					return {
						target: container,

						enter() {
							dragOver = true;
							dragOverCategory = type === EmployeeType.Vehicle ? 0 : -1;
						},

						leave() {
							dragOver = false;
						},

						async drop() {
							let amount = undefined;
							if (type === EmployeeType.ServiceProvider) {
								try {
									amount = await promptDialog<number>({
										component: WorkerAmountPrompt,
										props: { translations },
									});
								} catch (e) {
									return;
								}
							}

							if (type === null || type === undefined) {
								alert(translate('errorResourceType'));
							}

							const response = await jquery.post(location.href, {
								ajax: true,
								package: 'TerminEmployeeRequest',
								action: 'addEmployeeToJob',

								'add-action': dragOverAction,
								categoryid: dragOverCategory === -1 ? 0 : dragOverCategory,
								terminid: id,
								ressourceid: employeeId,
								ressourcetype: type,
								free: free ? 'true' : 'false',
								name,
								employeePhone,
								vehicleSeats,
								vehiclePayload,
								roomNumberPeople,
								amount,
							});

							if (jquery('action[state="true"]', jquery(response)).length) {
								update({ eventId: id });
							}
						},

						destroy() {
							drag = false;
							dragOver = false;

							if (scrollOnEdgeDrag) {
								scrollOnEdgeDrag();
								scrollOnEdgeDrag = undefined;
							}
						},
					};
				}
			)
		);
	}

	$: noUncategorizedCategory = !categories.find(
		({ id, assignment }) => id === -1 && assignment === EmployeeType.Employee
	);
	$: noUncategorizedVehicleCategory = !categories.find(
		({ id, assignment }) => id === 0 && assignment === EmployeeType.Vehicle
	);
</script>

{#if canView}
	<li
		bind:this={container}
		class="termin employee-drop {inset && !expandedJobIds.includes(parentId) && !expandAll ? 'hidden' : ''}"
		class:inset
		class:employee-drop--active={drag}
		class:employee-drop--dragover={dragOver}
		data-terminid={id}
	>
		<input type="hidden" name="untertermin-iterators[]" value={identifier} />
		<input type="hidden" name="untertermin-id-{identifier}" value={id} />

		<div class="categoryColor {categoryColor}" />

		<div class="buttons">
			{#if entered}
				<label>
					{translations.available}:

					<!-- prettier-ignore -->
					{#if canChangeAvailability}
						<input type="hidden" name="untertermin-verfuegbar-alt-{identifier}" value="{availability}" />
						<Select
							autoSubmit
							inputClass="verfuegbarkeit"
							class="small stopPropagation untertermin-verfuegbar-{id}"
							name="untertermin-verfuegbar-{identifier}"
							bind:value={availability}
							data={{
								action: 'window-href',
								ajax: 'printHtml',
								group: 'untertermin-verfuegbar-' + id,
								package: 'TerminSeiteUntertermine',
								remove: 'false',
								target: 'termindetails'
							}}
							options={[
								{ label: translations.availableNA, value: 'NULL', data: { color: '', toggle: 'true' } },
								{ label: translations.availableYes, value: 1, data: { color: 'green', toggle: 'false' } },
								{ label: translations.availableNo, value: 0, data: { color: 'red', toggle: 'true' } },
							]}
						/>
					{:else if availability === 1}
						<span class="green">{translations.availableYes}</span>
					{:else if availability === 0}
						<span class="red">{translations.availableNo}</span>
					{:else}
						<span class="orange">{translations.availableNA}</span>
					{/if}
				</label>
			{/if}

			{#if status && !canModify}
				<StatusHolder {type} {status} {billed} {canceled} readonly />
			{/if}

			{#if type === EventType.Event && bookingStatus}
				<small class="counter {bookingStatus.color}">{bookingStatus.text}</small>
			{/if}

			{#if canModify}
				{#if inPast}
					<input type="hidden" name="untertermin-abgerechnet-alt-{identifier}" value={billed ? 1 : 0} />

					<!-- prettier-ignore -->
					<Checkbox
						name="untertermin-abgerechnet-{identifier}"
						label={translations.billed}
						checked={billed}
						input={{
							class: 'abgerechnet-change-unterjobs untertermin-abgerechnet' + id,
							'data-group': 'untertermin-abgerechnet' + id,
						}}
					/>
				{/if}

				<input type="hidden" name="untertermin-fertig-alt-{identifier}" value={done ? 1 : 0} />

				<!-- prettier-ignore -->
				<Checkbox
					name="untertermin-fertig-{identifier}"
					label={translations.done}
					checked={done}
					input={{
						class: 'fertig-change-unterjobs untertermin' + id,
						'data-group': 'untertermin' + id,
					}}
				/>

				<!-- prettier-ignore -->
				<StatusHolder
					{type}
					{status}
					billed={billed}
					{canceled}
					input={{
						class: 'status status-change-unterjobs untertermin-status-' + id,
						name: 'untertermin-status-' + identifier,
						'data-group': 'untertermin-status-' + id,
					}}
				/>
			{/if}

			{#if isExpandable()}
				<IconButton
					icon={!expandedJobIds.includes(id) ? Plus : Minus}
					on:click={() => {
						if (expandedJobIds.includes(id)) {
							expandedJobIds.splice(expandedJobIds.indexOf(id));
							expandedJobIds = [...expandedJobIds];
						} else {
							expandedJobIds = [...expandedJobIds, id];
						}
					}}
				/>
			{/if}

			<LazyBubble data={{ package: 'TerminDruckmenu', ajax: 'printHinweisUIAjax', mode: id }} let:open>
				<IconButton icon={Print} title={translations.print} on:click={open} />
			</LazyBubble>

			{#if canModify}
				<LazyBubble
					url="/termin/{id}"
					data={{ package: 'TerminKopieren', ajax: 'printHinweisUIAjax', mode: 'untertermin' }}
					let:open
				>
					<IconButton icon={Copy} title={translations.copy} on:click={open} />
				</LazyBubble>

				{#if canDelete}
					<IconButton
						icon={Times}
						type="submit"
						name="delete-untertermin"
						title={translate('delete')}
						value={id}
						data-action="window-href"
						data-ajax="printHtml"
						data-confirm={translations.confirmDelete}
						data-label={translate('delete')}
						data-package="TerminSeiteUntertermine"
						data-remove="false"
						data-target="termindetails"
					/>
				{/if}
			{/if}
		</div>

		<label class="goto-sub-event">
			<input
				tabindex="-1"
				type="submit"
				class="hidden"
				name="show-untertermin"
				value={id}
				data-action="window-href"
				data-package="Termin"
				data-ajax="printHinweisUI"
				data-target="ajax-content-self"
				data-transition="fade"
			/>

			<strong>
				{#if !groupByDay && !inset}{formattedId} -{/if}
				{title}
			</strong>

			{#if resourceNeeds}
				{@html resourceNeeds}
			{/if}

			{#if recentChanges}
				<span class="recent-changes"
					><ExclamationTriangle class="orange" />
					{translations.recentChanges}</span
				>
			{/if}

			{#each infos as info}
				<p>{info}</p>
			{/each}

			{#if ((groupByDay || inset) && time) || (!groupByDay && !inset && date)}
				<p>
					{#if activity}{activity}:{/if}
					{#if groupByDay && time}{time}{:else}{date}{/if}
					({duration})
				</p>
			{/if}

			{#if isExpandable()}
				<p>
					{@html staffingRequirementsSubjobs ? staffingRequirementsSubjobs : ''}
				</p>
			{/if}

			<table class="small">
				{#if dragOver && (dragType === EmployeeType.Employee || dragType === EmployeeType.Freelancer || dragType === EmployeeType.ServiceProvider) && noUncategorizedCategory}
					<tr class="temp-row">
						<td width={inset ? 150 : 170}>
							<div
								class="category-name"
								class:current={dragOverCategory === -1}
								transition:slide|global={{ duration: 200 }}
							>
								{translations.uncategorized}:
							</div>
						</td>
						<td>
							<div class="employees" transition:slide|global={{ duration: 200 }}>
								<em>-- {translations.categoryUnoccupied} --</em>

								{#if dragOverCategory === -1}
									<DropTarget bind:action={dragOverAction} />
								{/if}
							</div>
						</td>
					</tr>
				{/if}

				{#each categories as { id: categoryId, assignment, name, requiredEmployees, employees, note }}
					<tr
						class={!expandedJobIds.includes(id) && !expandAll ? 'hidden' : ''}
						on:dragover={dragOver &&
						categoryId !== 0 &&
						(dragType === assignment ||
							(assignment === EmployeeType.Employee &&
								(dragType === EmployeeType.Freelancer || dragType === EmployeeType.ServiceProvider)))
							? setDragOverCategory(categoryId)
							: undefined}
						on:dragleave={dragOver &&
						categoryId !== 0 &&
						!(
							dragType === assignment ||
							(assignment === EmployeeType.Employee &&
								(dragType === EmployeeType.Freelancer || dragType === EmployeeType.ServiceProvider))
						)
							? resetDragOverCategory
							: undefined}
					>
						<td width={inset ? 150 : 170}>
							<div
								class="category-name"
								class:current={dragOver && dragOverCategory === categoryId}
								data-hovertitle={note != '' ? note : null}
							>
								{note != '' ? name + '*' : name}
								{requiredEmployees ? `(${employees.length}/${requiredEmployees})` : ''}:
							</div>
						</td>

						<td>
							<div class="employees">
								{#each employees as { entityId, entityType, id: itemId, name, status, workingHours, overlap, noMail, remarks, nameColor }}
									<div class="name-and-icons">
										{#if workingHours}
											{#if workingHours.bubble}
												<IconButton
													icon={Clock}
													iconClass={workingHours.color}
													small
													type="submit"
													name="show-untertermin-zeiten"
													value={itemId}
													data-action="window-href"
													data-ajax="printHinweisUI"
													data-package="Termin"
													data-target="ajax-content-self"
													data-transition="fade"
													data-hover-time="500"
													data-hover-bubble="termin-zeiten-mitarbeiter-{id}-{itemId}"
												/>

												<div
													id="termin-zeiten-mitarbeiter-{id}-{itemId}"
													class="bubble menu autosize"
													data-package="TerminZeitenFuerMitarbeiter"
													data-ajax="printHinweisUIAjax"
													data-mode="untertermin"
													data-url="/kalender/termin/terminzeiten/mitarbeiter/{itemId}"
												/>
											{:else}
												<IconButton
													icon={Clock}
													iconClass={workingHours.color}
													small
													type="submit"
													name="show-untertermin-zeiten"
													value={itemId}
													data-action="window-href"
													data-ajax="printHinweisUI"
													data-package="Termin"
													data-target="ajax-content-self"
													data-transition="fade"
												/>
											{/if}
										{/if}

										<span class="color {nameColor}">
											{name}

											<!-- prettier-ignore -->
											{#if status}
												(<span class="color {status.color}">{status.text}</span>)
											{/if}
										</span>

										{#if overlap}
											<IconButton
												icon={ExclamationTriangle}
												iconClass={overlap}
												title={translations.overlap}
												data-bubble="mitarbeiteranfragen-ueberschneidungen-{id}-{itemId}"
											/>

											<div
												id="mitarbeiteranfragen-ueberschneidungen-{id}-{itemId}"
												class="bubble autosize menu width300"
												data-package="TerminMaUeberschneidungen"
												data-ajax="printHinweisUIAjax"
												data-mode="{id}-{itemId}"
												data-url="/kalender/termin/{id}/{entityType === EmployeeType.Vehicle ? 'fahrzeug' : (entityType === EmployeeType.Room ? 'room' : 'mitarbeiter')}/{entityId}"
											/>
										{/if}

										{#if noMail}
											<div class="no-email" title={translations.noMail}>
												<Envelope />
												<Times class="no-email__times" />
											</div>
										{/if}

										{#if remarks}
											<div class="remarks">
												{remarks}
											</div>
										{/if}
									</div>
								{/each}

								{#if !employees.length || (requiredEmployees && requiredEmployees > employees.length)}
									{#each { length: requiredEmployees ? requiredEmployees - employees.length : 1 } as _}
										<div class="name-and-icons"><em>-- {translations.categoryUnoccupied} --</em></div>
									{/each}
								{/if}

								{#if dragOver && dragOverCategory === categoryId}
									<DropTarget bind:action={dragOverAction} />
								{/if}
							</div>
						</td>
					</tr>
				{/each}

				{#if dragOver && dragType === EmployeeType.Vehicle && noUncategorizedVehicleCategory}
					<tr class="temp-row">
						<td width={inset ? 150 : 170}>
							<div
								class="category-name"
								class:current={dragOverCategory === -1}
								transition:slide|global={{ duration: 200 }}
							>
								{translations.vehicles}:
							</div>
						</td>
						<td>
							<div class="employees" transition:slide|global={{ duration: 200 }}>
								<em>-- {translations.categoryUnoccupied} --</em>

								{#if dragOverCategory === -1}
									<DropTarget bind:action={dragOverAction} />
								{/if}
							</div>
						</td>
					</tr>
				{/if}
			</table>
		</label>

		{#if dragAlready}
			<div class="employee-drop--already" transition:fade|global={{ duration: 200 }}>
				<MinusCircle class="red" />
				<span
					>{@html escape(translations.employeeAlreadyAdded).replace(
						'{0}',
						'<strong>' + escape(dragName) + '</strong>'
					)}</span
				>
			</div>
		{/if}
	</li>
{/if}

{#if loadingPast}
	<li class="hinweis height-normal">
		<Spinner />
	</li>
{:else if pastEvents}
	<li class="hinweis height-normal {!expandedJobIds.includes(id) && !expandAll ? 'hidden' : ''}">
		{translations[pastEvents === 1 ? 'pastEvent' : 'pastEvents'].replace('{0}', String(pastEvents))}
		<Button link inline on:click={loadPast}>{translations.show}</Button>
	</li>
{/if}

{#each subEvents as subEvent, index (subEvent.id)}
	<svelte:self
		{...subEvent}
		inset
		{groupByDay}
		{expandAll}
		{expandedJobIds}
		identifier={`${identifier}-${index}`}
		{canModify}
		{canDelete}
		{showNumbers}
		{translations}
		update={updateSub}
		loadPastSub={loadPastSubSub}
		loadMoreSub={loadMoreSubSub}
	/>
{/each}

{#if loadingMore}
	<li class="hinweis height-normal">
		<Spinner />
	</li>
{:else if moreEvents}
	<li class="hinweis height-normal">
		{translations[moreEvents === 1 ? 'moreEvent' : 'moreEvents'].replace('{0}', String(moreEvents))}
		<Button link inline on:click={loadMore}>{translations.show}</Button>
	</li>
{/if}
