<script lang="ts">
	import Bubble from '../../../components/bubble';

	export let url: string = '/';
	export let data: {
		ajax: string;
		mode?: any;
		package?: string;
	};

	export let forceReload: boolean = undefined;
	export let trapFocus: boolean = undefined;
	export let onBody: boolean = false;

	let element: HTMLDivElement;
	let bubble: Bubble;
	let prevent: boolean = false;

	const open = ({ target }: Event) => {
		if (prevent) {
			return;
		}
		if (!bubble) {
			bubble = new Bubble({
				anchor: (target as Element).closest('button'),
				forceReload,
				lazy: { url, data },
				trapFocus,
				element,
			});
		}
		const unsubscribe = bubble.subscribe(({ opened }) => {
			if (!opened) {
				unsubscribe();
				prevent = true;
				setTimeout(() => (prevent = false), 200);
			}
		});
		bubble.open();
	};
</script>

<slot {open} />

{#if !onBody}
	<div bind:this={element} class="bubble" data-url={url} />
{/if}
