import CategoryFilter from './filters/CategoryFilter.svelte';
import LanguageFilter from './filters/LanguageFilter.svelte';
import RadiusFilter from './filters/RadiusFilter.svelte';
import SelectFilter from './filters/SelectFilter.svelte';
import DepartmentFilter from './filters/DepartmentFilter.svelte';

export const addFilterComponent = ({ type, ...props }: { type: string }) => {
	switch (type) {
		case 'CategoryFilter':
			return { component: CategoryFilter, props };
		case 'LanguageFilter':
			return { component: LanguageFilter, props };
		case 'RadiusFilter':
			return { component: RadiusFilter, props };
		case 'DepartmentFilter':
			return { component: DepartmentFilter, props };	
		case 'SelectFilter':
		case 'Sorter':
			return { component: SelectFilter, props };
		default:
			throw new Error(`Unknown filter (${type})`);
	}
};
