<script lang="ts">
	import { onMount } from 'svelte';
	import { Legend } from '../types';
	export let legend: Legend[];

	let targetDiv: HTMLElement;
	onMount(() => {
		targetDiv = document.querySelector('.event-details__footer');

		legend.forEach((value) => {
			const span = document.createElement('span');
			span.className = value.color;
			span.textContent = value.label;
			targetDiv.appendChild(span);
		});
	});
</script>
