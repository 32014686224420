<script>
	import debounce from 'debounce';
	import Filter from 'icon:filter';
	import Plus from 'icon:plus';
	import Times from 'icon:times';
	import jquery from 'jquery';
	import { afterUpdate, onMount, tick } from 'svelte';
	import { cubicInOut } from 'svelte/easing';
	import { slide } from 'svelte/transition';
	import listen from '../../../utils/listen';
	import { preventSubmit } from '../../actions';
	import Button from '../../components/Button';
	import IconButton from '../../components/IconButton';
	import InfiniteScroll from '../../components/InfiniteScroll';
	import Spinner from '../../components/Spinner';
	import { EmployeeType } from '../types';
	import CreateFreeResult from './CreateFreeResult.svelte';
	import { hasSubscribers, onDragStart } from './drag-employee';
	import Filters from './Filters.svelte';
	import { isRequestable } from './requestable';
	import Result from './Result.svelte';
	import { loadAllStore, isSelectAllBtnDisabledStore } from '../TenderEmail/stores/stores';

	export let tender = false;
	export let employeesWithTenderReceivedArray = [];

	let filtersOpened = false;
	let loading = false;

	let container;
	let scrollContainer;

	let hint;
	let next;
	let results = [];
	let search = '';

	let filters;
	let postParams;
	let translations;
	let showPhoneNumber;

	let filtersComponent;
	let settingFilter = false;

	let contentElement;

  const updateClasses = () => {
	waitForContainer();
    if ( !container )
        return;
    if (tender) {

      const contentElement = document.querySelector('.recipient-main-div .event__employee-request .content');
      if (contentElement) {
        const contentChildren = Array.from(contentElement.children);
        contentChildren.forEach(child => {
          child.classList.remove('disabled-tender');
        });

        const droppedChildrenParent = document.querySelector('.dropped-children-tender-parent');
        const droppedChildren = Array.from(droppedChildrenParent.children);

        if (droppedChildren) {
          droppedChildren.forEach(droppedChild => {
            const droppedChildId = droppedChild.getAttribute('data-ressourceid');

            contentChildren.forEach(contentChild => {
              const contentChildId = contentChild.getAttribute('data-ressourceid');
              if (contentChildId === droppedChildId) {
                contentChild.classList.add('disabled-tender');
              }
            });
          });
        }
      }
    }
  };
 
  onMount(updateClasses);
  afterUpdate(updateClasses);

  $: tender, updateClasses();
  $: results, updateClasses();

	export const setSearch = async(value) => {
		search = value;
	};

	export const setFilters = async filters => {
		settingFilter = true;
		await initialize;
		filtersOpened = true;
		await filtersComponent.setFilters(filters);
		settingFilter = false;
	};

	const initialize = (async () => {
		({ filters, postParams, translations } = await jquery.post(location.href, {
			silent: true,
			package: 'TerminEmployeeRequest',
			ajax: 'initialize',
		}));
		let activeDefaultFilters;
		filtersOpened =
			filtersOpened ||
			filters.extraFilters.length > 0 ||
			(activeDefaultFilters = filters.defaultFilters.filter(({ checked }) => checked)).length !== 1 ||
			activeDefaultFilters[0].name !== 'employeerequest-employees';
		updateResults();
	})();

	onMount(() => {
		waitForContainer();
        if ( !container )
            return;
		scrollContainer = container.parentElement;
	});

	const fetchResults = async (start = 0) => {
		try {
			loading = true;
			const response = await jquery.post(location.href, {
				silent: true,
				package: 'TerminEmployeeRequest',
				ajax: 'fetchResults',
				[postParams.search]: search,
				start,
			});

			({ next } = response);
			return response;
		} catch (error) {
			hint = 'Something went wrong.';
			results = [];
			throw error;
		} finally {
			loading = false;
		}
	};

	const updateResults = async () => {
		if (tender) {
			isSelectAllBtnDisabledStore.set(true);
		}
		if (freeResults.length) {
			const filters = filtersComponent
				.getFilters()
				.default.filter(({ checked }) => checked)
				.map(({ name }) => name);

			const types = {
				[EmployeeType.Employee]: 'employeerequest-employees',
				[EmployeeType.Freelancer]: 'employeerequest-freelancer',
				[EmployeeType.Vehicle]: 'employeerequest-vehicles',
				[EmployeeType.Room]: 'employeerequest-rooms',
			};

			filteredFreeResults = freeResults.filter(({ type }) => filters.includes(types[type]));
		} else {
			filteredFreeResults = [];
		}

		if (settingFilter) {
			return;
		}

		scrollContainer.scrollTop = 0;

		const response = await fetchResults();
		({ hint, results } = response);

		if (results.length == 10) {
			window.setTimeout(function () {
				if (scrollContainer.clientHeight >= scrollContainer.scrollHeight - 10) {
					loadMore();
				}
			}, 500);
		}

		if (tender) {
			isSelectAllBtnDisabledStore.set(false);
		}
	};

	const loadMore = async () => {
		if (!loading && next) {
			const response = await fetchResults(next);
			results = [...results, ...response.results];
		}
	};


	const loadAll = async () => {
		let index = 0;
		results = [];
		while (true) {
			const response = await fetchResults(index);
			if (response.results.length === 0) {
				break;
			}
			results = [...results, ...response.results];
			index += 10;
		}
		loadAllStore.set(false)
	};

	const waitForContainer = async () => {
		let counter = 0;
		while (!container && counter < 10 ) {
			await new Promise(resolve => setTimeout(resolve, 50));
			counter++;
		}
	}

	let runLoadAll = false;

	loadAllStore.subscribe(value => {
		runLoadAll = value;
	});

	$: if (runLoadAll) { loadAll() };


	let freeResults = [];
	let filteredFreeResults = [];
	let showCreateFreeResult = false;
	const addFreeResult = async ({ detail }) => {
		showCreateFreeResult = false;

		const result = { ...detail, id: 0, free: true };
		freeResults = [...freeResults, result];
		filteredFreeResults = [...filteredFreeResults, result];

		await tick;

		waitForContainer();

		const element = container.querySelectorAll('.result')[filteredFreeResults.length - 1];
		if (!element) {
			return;
		}

		const { height } = container.querySelector('.header').getBoundingClientRect();
		scrollContainer.scrollTop = element.offsetTop - scrollContainer.offsetTop - height - 24;
		element.classList.add('result--highlight');
	};
	$: showPhoneNumber = postParams ? postParams.preferences.showPhoneNumber : false;
</script>

<div bind:this={container}>
	{#await initialize}
		<Spinner />
	{:then}
		<div class="header">
			<div class="toolbar">
				<div class="text-field--inputclear">
					<!-- svelte-ignore a11y-autofocus -->
					<input
						autofocus
						class="text-field"
						id="employee-request__search"
						placeholder={translations.search}
						bind:value={search}
						on:input={debounce(updateResults, 100)}
						use:preventSubmit
					/>

					<IconButton
						class="inputclear{search ? ' visible' : ''}"
						data-clearfor="employee-request__search"
						icon={Times}
						on:click={() => {
							search = '';
							updateResults();
						}}
					/>
				</div>

				<IconButton
					active={filtersOpened}
					icon={Filter}
					small
					title={translations.filter}
					on:click={() => {
						filtersOpened = !filtersOpened;
					}}
				/>
			</div>

			<Filters
				bind:this={filtersComponent}
				{tender}
				{filters}
				{postParams}
				opened={filtersOpened}
				{translations}
				on:update={updateResults}
			/>
		</div>

		<!-- svelte-ignore a11y-no-static-element-interactions -->
		<div class="content" class:loading on:dragstart={$hasSubscribers ? onDragStart : undefined}>
			{#if hint}
				<p class="hint">{hint}</p>
			{/if}

			{#if filteredFreeResults.length}
				{#each filteredFreeResults as result}
					<Result
						{tender}
						{employeesWithTenderReceivedArray}
						{translations}
						{showPhoneNumber}
						{...result}
						requestable={$isRequestable(result)}
						on:delete={() => {
							freeResults = freeResults.filter(res => res !== result);
							filteredFreeResults = filteredFreeResults.filter(res => res !== result);
						}}
					/>
				{/each}

				<div class="divider" role="presentation" />
			{/if}

			{#each results as result}
				<Result
					{employeesWithTenderReceivedArray}
					{tender}
					{translations}
					{showPhoneNumber}
					{...result}
					requestable={$isRequestable(result)}
				/>
			{/each}

			{#if loading}
				<Spinner />
			{/if}
		</div>

		<div class="footer">
			{#if showCreateFreeResult}
				<CreateFreeResult
					{translations}
					{showPhoneNumber}
					getFilters={filtersComponent.getFilters}
					on:create={addFreeResult}
					on:cancel={() => (showCreateFreeResult = false)}
				/>
			{:else if !tender}
				<div class="actions" transition:slide|global={{ duration: 200, easing: cubicInOut }}>
					<Button icon={Plus} on:click={() => (showCreateFreeResult = true)}>{translations.createFree}</Button>
				</div>
			{/if}
		</div>

		<InfiniteScroll load={loadMore} root={scrollContainer} margin="80px" />
	{/await}
</div>
