<script lang="ts">
    export let staffBooked: number = 0;
    export let staffNeeds: number = 0;
    export let staffRequested: number = 0;
    export let staffEntered: number = 0;

    let extendedPersonellRequirements = window.__CREW_BRAIN__.config.extendedpersonnelrequirements;

    $: {
        staffBooked = staffBooked ?? 0;
        staffNeeds = staffNeeds ?? 0;
        staffRequested = staffRequested ?? 0;
        staffEntered = staffEntered ?? 0;
    }

</script>
    {#if extendedPersonellRequirements === 0}
    (<span>
            <strong>{staffBooked} / {staffNeeds}</strong>
        </span>)
    {:else if extendedPersonellRequirements === 1}
        (<strong>
            <span class="color green">{staffBooked}</span><span class="color gray">|</span><span class="color orange">{staffRequested}</span><span class="color gray">|</span><span class="color blue">{staffEntered}</span>
            <span class="color gray"> / </span>
            <span class="color {staffBooked >= staffNeeds ? '' : 'red'}">{staffNeeds}</span>
        </strong>)
    {/if}


