<script>
	import { createEventDispatcher } from 'svelte';
	import { cubicInOut } from 'svelte/easing';
	import { slide } from 'svelte/transition';
	import Select from '../../../components/Select';
	import DeleteButton from '../DeleteButton.svelte';

	export let filter;
	export let name;
	export let select;

	export const getData = () => ({
		...filter,
		values: select.selected == 0 ? [] : [[select.name, select.selected]],
	});

	const dispatch = createEventDispatcher();

	const update = () => dispatch('update');
	const remove = () => dispatch('remove');
</script>

<div class="filter filter--select" transition:slide={{ duration: 200, easing: cubicInOut }}>
	<strong>{name}:</strong>

	<Select bind:value={select.selected} options={select.options} on:change={update} />

	<DeleteButton on:click={remove} />
</div>
