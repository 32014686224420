const dec2hex = (dec: number) => (dec < 10 ? '0' + String(dec) : dec.toString(16));

export const randomString = (length: number) => {
	if (window.crypto) {
		const array = new Uint8Array((length || 32) / 2);
		crypto.getRandomValues(array);
		return Array.from(array, dec2hex).join('');
	}

	let string = '';
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charsLength = chars.length;
	for (let i = 0; i < length; i++) {
		string += chars.charAt(Math.floor(Math.random() * charsLength));
	}
	return string;
};
