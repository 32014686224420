const getScrollParent = (element, includeHidden) => {
	const style = getComputedStyle(element);
	const excludeStaticParent = style.position === 'absolute';
	const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

	if (style.position === 'fixed') {
		return document.scrollingElement || document.documentElement;
	}

	while ((element = element.parentElement)) {
		const style = getComputedStyle(element);

		if (excludeStaticParent && style.position === 'static') {
			continue;
		}

		if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
			return element;
		}
	}

	return document.scrollingElement || document.documentElement;
};

export default getScrollParent;
