<script>
	import { createEventDispatcher } from 'svelte';
	import { cubicInOut } from 'svelte/easing';
	import { slide } from 'svelte/transition';
	import Select from '../../../components/Select';
	import DeleteButton from '../DeleteButton.svelte';

	export let filter;
	export let name;
	export let radius;
	export let kind;
	export let zip;
	export let countryCode;

	export const getData = () => ({
		...filter,
		values:
			radius.value == 0
				? []
				: [
						[radius.name, radius.value],
						[kind.name, kind.selected],
						kind.selected == zip.kind && [zip.name, zip.value],
						kind.selected == countryCode.kind && [countryCode.name, countryCode.selected]
				  ].filter(Boolean),
	});

	const dispatch = createEventDispatcher();

	const update = () => dispatch('update');
	const remove = () => dispatch('remove');

	const selectAll = ({ target }) => {
		if (target.select) {
			target.select();
		}
	};
</script>

<div class="filter filter--radius" transition:slide={{ duration: 200, easing: cubicInOut }}>
	<strong>{name}:</strong>

	<label class="filter__radius">
		<input class="text-field" type="text" bind:value={radius.value} on:input={update} on:focus={selectAll} />
		<span>{radius.unit}</span>
	</label>

	<Select bind:value={kind.selected} options={kind.options} on:change={update} />
	
	{#if kind.selected === zip.kind}
		<input
			class="text-field filter__zip"
			type="text"
			placeholder={zip.placeholder}
			bind:value={zip.value}
			on:input={update}
		/>
	{/if}
	{#if kind.selected === countryCode.kind && !countryCode.isGerman }
		<Select bind:value={countryCode.selected} options={countryCode.options} on:change={update} />
	{/if}

	<DeleteButton on:click={remove} />
</div>
