<script lang="ts">
	export let currentViewIdx: number;
    export let stepsArray: Array<string>;
    export let isSingleJob: boolean;

	function goToClickedView(index: number) {
		if(index > currentViewIdx) 
			return	 

		currentViewIdx = index;			
	}

	
</script>

<div class="parent-stepper-wrapper">
	<div class="stepper-wrapper">
		{#each stepsArray as step, index}
            <!-- skip first view if only single job -->
            {#if isSingleJob === false || index !== 0 }
            <div class={currentViewIdx === index || index < currentViewIdx  ? 'stepper-item active-item' : 'stepper-item'}>
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <div on:click={()=> goToClickedView(index)} class="step-circle {index > currentViewIdx ? 'step-circle-cursor-default' : 'step-circle-cursor-pointer'}"></div>			
                <div class="step-name">{step}</div>
              </div>
            {/if}
            
		  
		{/each}
	</div>
</div>
