<script>
    import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
    export let icon;
    export let label;
</script>

<li>
    <button on:click>
        <FontAwesomeIcon {icon}/>
        <span class="margin-left-context-menu">{label}</span>
    </button>
</li>

