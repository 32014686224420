import { AddToBackendData, BackendDataItem, BackendObject, RemoveResource, RequestStatus, SourceNode, KeyAndResource } from '../types';
import { status, isCategoriesCheckedStore, cellsArrayStore, sourceNodeStore, isOnlyFutureJobsViewStore } from '../stores/stores';
import logError from "../../../../utils/log-error";
import { writable } from 'svelte/store';

let isCategoriesChecked: boolean;
let isOnlyFutureJobsViewChecked: boolean;
let cellsArray: any;
let daysOfWeek = [
    window.__CREW_BRAIN__.translations.js_sonntag,
    window.__CREW_BRAIN__.translations.js_montag,
    window.__CREW_BRAIN__.translations.js_dienstag,
    window.__CREW_BRAIN__.translations.js_mittwoch,
    window.__CREW_BRAIN__.translations.js_donnerstag,
    window.__CREW_BRAIN__.translations.js_freitag,
    window.__CREW_BRAIN__.translations.js_samstag
];


//Stores
isCategoriesCheckedStore.subscribe((value: boolean) => {
	isCategoriesChecked = value;
});

isOnlyFutureJobsViewStore.subscribe((value: boolean) => {
	isOnlyFutureJobsViewChecked = value;
});

cellsArrayStore.subscribe(value => {
	cellsArray = value;
});

//Variables
export let backendData: BackendObject[] = [];

//stores
export const staffplanErrorStore = writable(false);
export const staffplanErrorMessage = writable<string | undefined>('');

//Functions
export async function postData(): Promise<{ success: boolean }> {
	let mainjobOrProjectId;
    let isProject;

    sourceNodeStore.subscribe((sourceNode: SourceNode) => {
        mainjobOrProjectId = sourceNode.ID;
		isProject = sourceNode.Subtyp === 'P';
	})

    const url = `${window.location.origin}/api/`+( isProject ? 'projekt' : 'job')+`/${mainjobOrProjectId}/staffplan`;
  
    try {
		const postData = {
            isCategoriesView: isCategoriesChecked,
            isOnlyFutureJobsView: isOnlyFutureJobsViewChecked,
            data: backendData,
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        });

        if (!response.ok) {
			// 400 Bad request save response message to display it for user
            const error = new Error(`HTTP error! status: ${response.status}`);

			const responseBody = await response.text();

			const parser = new DOMParser();
			const doc = parser.parseFromString(responseBody, 'text/html');
			const errorMessage = doc.querySelector('p')?.innerHTML || undefined;

            staffplanErrorStore.set(true)
            staffplanErrorMessage.set(errorMessage);
        }
        
		if (response.ok) {
			backendData = [];
		}

        return { success: response.ok };
    } catch (error) {
        staffplanErrorStore.set(true);
        staffplanErrorMessage.set(error.message);
        return { success: false };
    }
};

export const addToBackendData: AddToBackendData = (
	terminid,
	zugehoerigkeit,
	zugehoerigkeitid,
	kategorieid,
	check,
	temaid,
	data,
	freierMitarbeiter,
	subEmployeeId,
	urlaubskategorieid?,
) => {

    if(subEmployeeId === null && zugehoerigkeit === 'D') {
        subEmployeeId = 0;
    }

    const existingIndex = data.findIndex((item: BackendDataItem) => {
        if (freierMitarbeiter !== null) {
            return item.zugehoerigkeitid === zugehoerigkeitid && item.terminid === terminid && item.zugehoerigkeit === zugehoerigkeit && item.freiermitarbeiter === freierMitarbeiter;
        }   else {
            return item.zugehoerigkeitid === zugehoerigkeitid && item.terminid === terminid && item.zugehoerigkeit === zugehoerigkeit;
        }
    });
    

    // If same employee already exists in data, remove it
    if (existingIndex !== -1 ) {
        if(zugehoerigkeit === 'D') {
            const existingItem = data[existingIndex];
            if(subEmployeeId !== 0 && existingItem.subemployee === subEmployeeId) {
                data.splice(existingIndex, 1); 
            }
        } else {
            if(subEmployeeId !== 0) {
                data.splice(existingIndex, 1); 
            }
        }
    };

	let anfragenvariante = '';
	status.subscribe(value => {
		anfragenvariante = value;
	});

    anfragenvariante = requestStatus2Action(anfragenvariante);

	const newObject: BackendObject = {
		zugehoerigkeit,
		zugehoerigkeitid,
		anfragenvariante,
		terminid,
		kategorieid,
		check,
		temaid
	};

	if (freierMitarbeiter !== null) {
		newObject.freiermitarbeiter = freierMitarbeiter;
	};
	if (subEmployeeId !== null) {
		newObject.subemployee = subEmployeeId;
	};
	if (urlaubskategorieid !== null) {
		newObject.urlaubskategorieid = urlaubskategorieid;
	};


	// find the right "kategorieid" in the case that the "Categories" Checkbox is turned off
	if(kategorieid === null && isCategoriesChecked === false) {
	    const element = cellsArray.find((element: any) => 
	        element.temaid === temaid && 
	        element.terminid === terminid && 
	        element.zugehoerigkeitid === zugehoerigkeitid && 
            element.zugehoerigkeit === zugehoerigkeit &&
	        element.temaid !== 'neu' && 
	        element.kategorieid !== null
	    );

	    if (element) {
	        newObject.kategorieid = element.kategorieid;
	    }
	};

	data.push(newObject);
};

export function requestStatus2Action(status:string) {
    switch (status) {
		case RequestStatus.REQUESTED:
			return 'anfragen';
		case RequestStatus.AVAILABLE:
			return 'eintragen';
		case RequestStatus.BOOKED:
			return 'buchen';
        case RequestStatus.REJECTED:
            return 'ablehnen';
        case RequestStatus.NV:
            return 'nv';
        case RequestStatus.NA:
            return 'na';
        default:
            logError('Unknown action for request status: '+status);
            return '';
	}
};

export function convertDateFormat(dateString: any) {
	let parts = dateString.split(".");
	let date = new Date(parts[2], parts[1] - 1, parts[0]);
	let year = date.getFullYear();
	let month = ('0' + (date.getMonth() + 1)).slice(-2);
	let day = ('0' + date.getDate()).slice(-2);
	return `${year}-${month}-${day}`;
};

export const removeResources: RemoveResource = (
	tableRowElement,
	zugehoerigkeit,
	zugehoerigkeitid,
	anfragenvariante,
	kategorieid,
	freiermitarbeiter,
	subemployee
) => {
	Array.from(tableRowElement.children).forEach(child => {
		let temaidString = child.getAttribute("data-temaid");
		let terminidString = child.getAttribute("data-terminid");

		if(temaidString && temaidString != "neu") {
			let temaid = parseInt(temaidString);
			let terminid = parseInt(terminidString);

			let object = {
				    zugehoerigkeit,
					zugehoerigkeitid,
					anfragenvariante,
					terminid,
					kategorieid,
					check: false,
					temaid,
					freiermitarbeiter,
					subemployee,
			};

			backendData.push(object);
			console.log(backendData);
		} 
	})
};

export const getColorClass = (color: string) => {
    let className = '';
    switch (color) {
        case 'W':
            className = 'white';
            break;
        case 'V':
            className = 'purple';
            break;
        case 'M':
            className = 'magenta';
            break;
        case 'K':
            className = 'dark-magenta';
            break;
        case 'P':
            className = 'pink';
            break;
        case 'E':
            className = 'wine-red';
            break;
        case 'R':
            className = 'red';
            break;
        case 'D':
            className = 'gold';
            break;
        case 'C':
            className = 'salmon';
            break;
        case 'O':
            className = 'orange';
            break;
        case 'Y':
            className = 'yellow';
            break;
        case 'L':
            className = 'light-green';
            break;
        case 'G':
            className = 'green';
            break;
        case 'T':
            className = 'turquoise';
            break;
        case 'H':
            className = 'light-blue';
            break;
        case 'I':
            className = 'mid-blue';
            break;
        case 'B':
            className = 'blue';
            break;
        case 'A':
            className = 'gray';
            break;
        case 'N':
            className = 'brown';
            break;
        case 'S':
            className = 'black';
			break;
		case null:
			className = null;
    }
    if ( className === undefined ) {
        logError(`Color not found: ${color}`)
    }
    return className;
};

export function createCellObject(temaid: string | number, terminid: string | number, zugehoerigkeitid: string | number, zugehoerigkeit: string, kategorieid: string | number | null) {
    let cellObject: any = {
        temaid: temaid,
        terminid: terminid,
        zugehoerigkeitid: zugehoerigkeitid,
        zugehoerigkeit: zugehoerigkeit,
        kategorieid: kategorieid
    };
    return cellObject;
};

export function sortAlphabeticallyByPropertyName(a: KeyAndResource, b: KeyAndResource) {
    if (a[1].Name < b[1].Name) return -1;
    if (a[1].Name > b[1].Name) return 1;
    return 0;
};

export function getDayOfWeek(dateString: string) {
    let date = new Date(dateString.split(".").reverse().join("-"));
    return daysOfWeek[date.getDay()];
};
  