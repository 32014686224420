import { onAfterNavigate, onInit } from './subscriptions';

type Destroy = (() => any) | void;

export type View = (props: { container: Element }) => Destroy;
export type Route = { selector: string; view: View };

const ElementRouter = ({ root = document.body, routes }: { root?: Element; routes: Route[] }) => {
	let current: { route: Route; container: Element; destroy: Destroy };

	const find = () => {
		for (const route of routes) {
			const container = root.querySelector(route.selector);
			if (container) {
				return { route, container };
			}
		}
	};

	const mount = () => {
		const next = find();

		if (current) {
			const { route, container, destroy } = current;
			if (next && next.route === route && next.container === container) {
				return;
			}

			if (destroy) {
				destroy();
			}

			current = undefined;
		}

		if (next) {
			const { route, container } = next;
			current = { route, container, destroy: route.view({ container }) };
		}
	};

	const listeners = [
		onInit(mount),
		onAfterNavigate(({ target }) => {
			if (target !== 'header' && target !== 'subnav') {
				mount();
			}
		}),
	];

	return () => {
		for (const listener of listeners) {
			listener();
		}

		if (current && current.destroy) {
			current.destroy();
		}
	};
};

export default ElementRouter;
