import $ from 'jquery';

var errors = [];

const logError = (error: string, stack?: string): void => {
	if ( $.inArray(error, errors) >= 0 )
		return; // do not log errors multiple times to reduce load on server
	
	$.ajax({
		type: 'POST',
		url: location.href,
		data: {
			ajax: true,
			silent: true,
			package: 'Fehlermeldung',
			action: 'createJsFehlermeldung',
			error,
			stack,
		},
	});
};

export default logError;
