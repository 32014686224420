<script lang="ts">
    import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
    import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
    import { SelectSubEmployee, SubEmployee } from '../types';
    import { backendData, requestStatus2Action } from '../utils/utilsStaffplan';
    export let searchFilterProps;

    const { translations, zugehoerigkeitID, zugehoerigkeit, kategorieID, subEmployees } = searchFilterProps;

    let terminid: string | number;
    let temaid: string;
    let anfragenvariante: string;

    $: selectedSubEmployee = null;

    const selectSubEmployee: SelectSubEmployee = (e) => {
        let selectedId = e.target.value;
        selectedSubEmployee = subEmployees.find((subEmployee: SubEmployee) => subEmployee.id == selectedId);
   
        if (e.target instanceof HTMLElement) {
            const closestTrEl = e.target.closest('tr');
            if (closestTrEl) {
                addDataAttributesToBackendData(closestTrEl);
            }
        }
    };

 
    function addDataAttributesToBackendData(el: HTMLElement) {

        Array.from(el.children).forEach(child => {

            if (child instanceof HTMLElement && child.hasAttribute('data-anfragenvariante')) {
                terminid = child.dataset.terminid;
                temaid = child.dataset.temaid;
                anfragenvariante = child.dataset.anfragenvariante;
                terminid = parseInt(terminid);

                anfragenvariante = requestStatus2Action(anfragenvariante);
                const newObject = createNewObject();
                backendData.push(newObject);
            }
        });
    }

    function createNewObject() {
        return {
            zugehoerigkeit,
            zugehoerigkeitid: zugehoerigkeitID,
            anfragenvariante,
            terminid,
            kategorieid: kategorieID,
            check: true,
            temaid,
            freiermitarbeiter: selectedSubEmployee.title,
            subemployee: selectedSubEmployee.id,
        };
    }
</script>



<div class="select-wrapper width200 dlma-subemployee-selectfield-0" style="display: block;">
    <select name="subemployee-selectfield-0" on:change="{e => selectSubEmployee(e, e.target.value)}">
        <option value="0">{translations.pleaseChoose}...</option> 
		    {#each subEmployees as subEmployee}
                <option value={subEmployee.id} >{subEmployee.title}</option> 
		    {/each}
    </select>
    <FontAwesomeIcon icon={faChevronDown} class="icon icon--chevron-down gray arrow-down"/>
</div>

	




