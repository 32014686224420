<script lang="ts">
	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
	import { faPrint , faXmark} from '@fortawesome/free-solid-svg-icons';
	import { createEventDispatcher, onMount } from 'svelte';
	import { Translations, KategorieID, Key, ClassName, KeyToRemove, SubEmployee, Employee } from '../types';
	import ServiceProvider from './ServiceProvider.svelte';
	import { getColorClass } from '../utils/utilsStaffplan';

	export let translations: Translations;
	export let kategorieID: KategorieID;

	export let key: Key;
	export let resource;
	const dispatch = createEventDispatcher();

	const {
		Name: employee,
		ZugehoerigkeitID: zugehoerigkeitID,
		Zugehoerigkeit: zugehoerigkeit,
		FreierMitarbeiter: freierMitarbeiter,
		SubEmployeeID: subEmployeeId,
		FarbeDienstleister: serviceProviderColor,
	} = resource;

	let className: ClassName;
	let inputEl = document.querySelector('input[name=terminid]') as HTMLInputElement;
	let hauptTerminId = inputEl.value;
	let loading = true;

	function removeEntry(keyToRemove: KeyToRemove) {
		let dispatchObj = { keyToRemove, zugehoerigkeit, zugehoerigkeitID, kategorieID, freierMitarbeiter, subEmployeeId };
		dispatch('remove', dispatchObj);
	}

	onMount(() => {
		className = getColorClass(serviceProviderColor);
	});

	let serviceProviderProps = {
		resource,
		kategorieID,
		translations,
	};

	let searchQuery = '';
	let subEmployees: SubEmployee[] = [];


	async function findSubEmployees() {
     
	 const url = `${window.location.origin}/api/assignmentsearch`;

	 const requestData = {
		 mode: `subcontractoremployees-${zugehoerigkeitID}`,
		 search: searchQuery === '' ? null : searchQuery,
	 };

	 try {
		 const response = await fetch(url, {
			 method: 'POST',
			 headers: {
				 'Content-Type': 'application/json',
			 },
			 body: JSON.stringify(requestData),
		 });

		 if (!response.ok) {
			 throw new Error(`HTTP error! status: ${response.status}`);
		 };

		 const data = await response.json();
		  subEmployees = data.filter((employee: SubEmployee) => employee.type === 'entry');
		  loading = false;
	 } catch (error) {
		 console.error('There was a problem with the fetch-request:', error);
		 loading = false;
	 }
};

findSubEmployees()
</script>

<td>
	<div class="name-and-icons" style="justify-content: space-between;">
		{#if zugehoerigkeit === 'D'}
	
			<ServiceProvider {serviceProviderProps} {className} {subEmployees}/>
		
		{:else}
			<span>{employee}</span>
		{/if}

		<div class="flex-container">
			<button
				on:click={() => removeEntry(key)}
				type="button"
				class="button icon-button delete-ma"
				aria-label={translations.delete}
				data-confirm={translations.confirmRemoveResource}
				data-label={translations.delete}
				>
				<FontAwesomeIcon icon={faXmark} class="icon icon--times"/>
			</button>

			<a
				href={`${window.location.origin}/kalender/termin/${hauptTerminId}/mitarbeiter/${zugehoerigkeitID}`}
				class="button icon-button"
				aria-label={translations.orderConfirmation}
				data-ajax="printHinweisUI"
				data-package="TerminMaAuftragsbestaetigung"
				data-target="ajax-content"
				data-transition="fade"
				data-urlupdate="false"
				>
				<FontAwesomeIcon icon={faPrint} class="icon icon--print"/>
			</a>
		</div>
	</div>
</td>
