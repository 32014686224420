let supportsPassiveEventListener = false;

try {
	window.addEventListener(
		'test',
		null,
		Object.defineProperty({}, 'passive', {
			get: function () {
				supportsPassiveEventListener = true;
			},
		})
	);
} catch (e) {}

export default supportsPassiveEventListener;
