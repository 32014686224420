import $ from 'jquery';
import config from './config';

if (document.documentElement.classList.contains('js-focus-visible')) {
	document.documentElement.classList.remove('no-focus-visible');
}

$.ajaxPrefilter(settings => {
	const method = settings.method || settings.type;
	if (method && method.toUpperCase() === 'POST') {
		if (typeof settings.data === 'string') {
			settings.data += (settings.data ? '&' : '') + 'csrf_token=' + config.csrfToken;
		} else if (settings.data instanceof FormData) {
			settings.data.set('csrf_token', config.csrfToken);
		} else {
			if (!settings.data) {
				settings.data = {};
			}

			settings.data.csrf_token = config.csrfToken;
		}
	}
});
