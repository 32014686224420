import config from '../config';

const changeTheme = (theme: string) => {
	if (!theme || theme === config.currentTheme) {
		return;
	}

	const styleSheet = config.themes[theme];
	const oldStyleSheet = config.themes[config.currentTheme];
	const oldLink = document.querySelector(`link[href$="${oldStyleSheet}"]`) as HTMLLinkElement;

	if (!styleSheet || !oldStyleSheet || !oldLink) {
		return;
	}

	const link = document.createElement('link');
	link.rel = 'stylesheet';
	link.type = 'text/css';
	link.href = oldLink.href.replace(oldStyleSheet, styleSheet);
	link.onload = () => {
		link.onload = null;
		config.currentTheme = theme;
		oldLink.parentElement?.removeChild(oldLink);
	};
	document.head.appendChild(link);
};

export default changeTheme;
