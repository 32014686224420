import debounce from 'debounce';
import listen from './listen';

type Options = {
	target: Element;
	enter?(): any;
	leave?(): any;
	drop?(files: FileList): any;
	destroy?(): any;
};

type Subscriber = () => Options;

const subscribers: Subscriber[] = [];

const onDragFile = (callback: Subscriber) => {
	if (!subscribers.includes(callback)) {
		if (subscribers.push(callback) === 1) {
			addListeners();
		}
	}

	return () => {
		const index = subscribers.indexOf(callback);
		if (index !== 1) {
			subscribers.splice(index, 1);

			if (!subscribers.length) {
				removeListeners();
			}
		}
	};
};

export default onDragFile;

let listeners: (() => any)[] | undefined;
let innerListeners: (() => any)[] | undefined;
let instances: Options[] | undefined;

const terminate = debounce(() => {
	if (innerListeners) {
		for (const listener of innerListeners) {
			listener();
		}

		innerListeners = undefined;
	}

	if (instances) {
		for (const { destroy } of instances) {
			destroy && destroy();
		}

		instances = undefined;
	}
}, 120);

const removeListeners = () => {
	terminate();
	terminate.flush();

	if (listeners) {
		for (const listener of listeners) {
			listener();
		}

		listeners = undefined;
	}
};

const addListeners = () => {
	if (listeners) {
		return;
	}

	let current: Options | undefined;

	listeners = [
		listen(
			document,
			'dragenter',
			event => {
				if (!event.dataTransfer) {
					return;
				}

				event.preventDefault();

				if (innerListeners) {
					event.stopPropagation();
					event.stopImmediatePropagation();

					if (event.target && instances) {
						for (const instance of instances) {
							if (instance.target.contains(event.target as Node)) {
								if (!current || current.target !== instance.target) {
									if (current && current.leave) {
										current.leave();
									}

									current = instance;
									instance.enter && instance.enter();
								}

								event.dataTransfer.dropEffect = 'copy';
								return;
							}
						}
					}

					if (current && current.leave) {
						current.leave();
					}

					current = undefined;
					event.dataTransfer.dropEffect = 'none';
					return;
				}

				// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Recommended_drag_types#file
				if (
					!Array.prototype.some.call(
						event.dataTransfer.types,
						(type: string) => type === 'Files' || type === 'application/x-moz-file'
					)
				) {
					return;
				}

				event.stopPropagation();
				event.stopImmediatePropagation();
				event.dataTransfer.dropEffect = 'none';

				instances = subscribers.map(subscriber => subscriber());
				innerListeners = [
					listen(document, 'dragleave', terminate, true),

					listen(
						document,
						'drop',
						event => {
							event.preventDefault();
							event.stopPropagation();
							event.stopImmediatePropagation();

							if (
								current &&
								current.drop &&
								event.dataTransfer &&
								event.dataTransfer.files &&
								event.dataTransfer.files.length
							) {
								current.drop(event.dataTransfer.files);
							}

							terminate();
							terminate.flush();
						},
						true
					),

					listen(
						document,
						'dragover',
						event => {
							event.preventDefault();
							event.stopPropagation();
							event.stopImmediatePropagation();

							terminate.clear();

							if (event.dataTransfer) {
								event.dataTransfer.dropEffect = current ? 'copy' : 'none';
							}
						},
						true
					),
				];
			},
			true
		),
	];
};
