export type SortedObject = any;
// type = string
export type CssStatus = string;
export type ClassName = string;
export type Status = string;
export type KeyToRemove = string;
export type Key = string;

// type = number
export type Index = number;
export type SearchTimeout = number;
export type KategorieID = number;
export type TerminMaKategorieID = number;

//type = boolean
export type LoadAll = boolean;

export type Translations = { [key: string]: string };
export type Legend = {color: string, label: string};
export type ResourcesEntries = [string, Resource];
export type Category = { ID: number; Bezeichnung: string };
export type Employee = HeadlineEmployee | EntryEmployee;
export type RemoveEvent = CustomEvent<DetailType>;

// type = void
export type SelectSubEmployee = (e: MouseEvent, subEmployee: any) => void;

export type ToggleCategories = () => void;

export type AddToBackendData = (
	terminid: number,
	zugehoerigkeit: string,
	zugehoerigkeitid: number,
	kategorieid: number,
	check: boolean,
	jobResources: any,
	data: any,
	freierMitarbeiter: string | null,
	subEmployeeId: number | null,
	urlaubskategorieid: number | null,
) => void;

export type RemoveEmployee = (
	zugehoerigkeitid: number,
	jobs: [],
	zugehoerigkeit: string,
	kategorieID: number,
	backendData: [],
	name: string,
	freierMitarbeiter: string,
	subEmployeeId: number
) => void;

export type RemoveResource = (
	tableRowElement: HTMLElement,
	zugehoerigkeit: string,
	zugehoerigkeitid: number,
	anfragenvariante: string,
	kategorieid: number,
	freiermitarbeiter: string | null,
	subemployee: number | null
) => void;

//
export type JobResource = {
	ZugehoerigkeitID: number;
	ID: number;
};

export type BackendDataItem = {
	zugehoerigkeitid: number;
    zugehoerigkeit: string;
	terminid: number;
	check: boolean;
	freiermitarbeiter: string;
};

export type KategorieResources = {
	ID: number;
	KategorieID: number;
	Bezeichnung: string;
	Anzahl: number;
};

export type JobResourcesStatus = {
	CSS: string;
	Color: string;
	Text: string;
	TextKurz: string;
};

export type SubEmployee = {
	type: string;
	title: string;
	subtitle: string;
	id: number;
};

export type Detail = {
	keyToRemove: string;
	zugehoerigkeit: string;
	zugehoerigkeitID: number;
	kategorieID: number;
	freierMitarbeiter: string | null;
	subEmployeeId: number | null;
};

export type BackendData = {
	terminid: number;
	zugehoerigkeit: string;
	zugehoerigkeitid: number;
	kategorieid: number;
	check: boolean;
	jobResources: any;
	data: any;
	anfragenvariante: string,
	freierMitarbeiter: string | null;
	subEmployeeId: number | null;
};


export type NewObjectType = {
    zugehoerigkeit: string,
    zugehoerigkeitid: number,
    anfragenvariante: string,
    terminid: number,
    kategorieid: number,
    check: boolean,
    temaid: any,
    SubEmployeeID: number,
    freiermitarbeiter?: string, 
};

export type BackendObject = {
	zugehoerigkeit: string;
	zugehoerigkeitid: number;
	anfragenvariante: string;
	terminid: number;
	kategorieid: number;
	check: boolean;
	temaid: number;
	freiermitarbeiter?: string;
	subemployee?: number;
	urlaubskategorieid?: number;
};

export type Resource = {
	FarbeDienstleister: string | null;
	FreierMitarbeiter: string | null;
	Jobs: number[];
	Kategorien: KategorieResources[];
	Krankmeldungen: any[];
	Name: string;
	SubEmployeeID: number | null;
	Urlaube: object;
	Zugehoerigkeit: string;
	ZugehoerigkeitID: number;
	index: number;
	key: number;
};

export type KeyAndResource = [
	string,
	Resource
]

export type Job = {
	Titel: string;
	DatumVon: string;
	DatumBis: string;
	ZeitVon: string;
	ZeitBis: string;
	ID: number;
	Kategorien: KategorieResources[];
	Resources: JobResources[];
};

export type JobResources = {
	FreierMitarbeiter: string | null;
	Gebucht: number;
	ID: number;
	ResourceKey: string;
	Status: JobResourcesStatus;
	SubEmployeeID: number | null;
	TerminMaKategorieID: number;
	Verfuegbar: number;
	Zugehoerigkeit: string;
	ZugehoerigkeitID: number;
};

export type Holiday = {
	Grund: string;
	Farbe: string;
	KategorieTyp: string;
	KalenderAnzeigen: number;
	Icon: string;
	Tagwert: number;
	Zeitwert: null | string;
	Von: string;
	Bis: string;
	ID: number;
	Zugehoerigkeit: string;
	ZugehoerigkeitID: number;
	Genehmigt: number;
	GenehmigtDatum: string;
	Absence: Holiday[];
};

export type HolidayEntry = {
    Farbe: string;
};

export type SourceNode = {
	ID: number,
	Subtyp: string,
};

export enum RequestStatus {
	REQUESTED = 'angefragt', 
    AVAILABLE = 'verfuegbar',
	BOOKED = 'gebucht', 
	REJECTED = 'abgelehnt',
    NV = 'nv',
    NA = 'na',
};

/*export enum RequestAction {
	REQUEST = 'anfragen', 
	ENTER = 'eintragen',
	BOOK = 'buchen', 
	REJECT = 'ablehnen', 
};*/

type DetailType = {
	keyToRemove: string;
	zugehoerigkeit: string;
	zugehoerigkeitID: number;
	kategorieID: number | null;
	freierMitarbeiter: string | null;
	subEmployeeId: number | null;
};

type HeadlineEmployee = {
	type: 'headline';
	title: string;
};

type EntryEmployee = {
	type: 'entry';
	title: string;
	subtitle: string;
	id: number;
};


export type CellObject = {
	temaid: number | string, 
	terminid: number, 
	kategorieid: number, 
	zugehoerigkeitid: number, 
	zugehoerigkeit: string
}