<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import type { SkillLevel } from './types';

	export let level: SkillLevel = 0;

	const dispatch = createEventDispatcher();

	const setLevel = (newLevel: SkillLevel) => {
		level = level == newLevel ? 0 : newLevel;
		dispatch('change', { level });
	};
</script>

<div class="skill-selector" data-level={level} aria-label="Skill selector ({level} / 3)">
	<button type="button" class="skill-selector__level" aria-label="1 / 3" on:click={() => setLevel(1)} />
	<button type="button" class="skill-selector__level" aria-label="2 / 3" on:click={() => setLevel(2)} />
	<button type="button" class="skill-selector__level" aria-label="3 / 3" on:click={() => setLevel(3)} />
</div>
