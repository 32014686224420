<script lang="ts">
	import Times from 'icon:times';
	import translate from '../../../i18n';
	import IconButton from '../../components/IconButton';

	let className: string = undefined;
	export { className as class };
</script>

<svelte:options immutable />

<IconButton class={className} icon={Times} small title={translate('delete')} on:click />
