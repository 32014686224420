import listen from '../../utils/listen';

const preventSubmit = (node: HTMLInputElement, action?: () => any) => {
	const unlisten = listen(node, 'keydown', event => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (typeof action === 'function') {
				action();
			}
		}
	});

	return {
		update(newAction?: () => any) {
			action = newAction;
		},

		destroy() {
			unlisten();
		},
	};
};

export default preventSubmit;
