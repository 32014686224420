import $ from 'jquery';
import language from '../i18n';
import { sendForm, sendFormData } from '../legacy';

let z;
let type;

const FORM_ACTION = 'moveTaskOrTasklist';
const FORM_PACKAGE = 'Aufgaben';
const FORM_AJAX = 'printSplitviewNavigation,printSplitviewContent';
const FORM_AJAXPACKAGE = 'Aufgaben,Aufgaben';
const FORM_TARGET = 'subnav,content';

const TYPE_TASK = 'task';
const TYPE_TASKLIST = 'tasklist';
const TYPE_FOLDER = 'folder';

const LI_TASKS = 'ul.listview.tasks li';
const LI_TASKLISTS = 'ul.sub-navigation__list li.tasklist';
const LI_MAGICLISTS = 'ul.sub-navigation__list li.magiclist';
const LI_FOLDERS = 'ul.sub-navigation__list li.folder';

$(document).on('dragstart', LI_TASKS + ', ' + LI_TASKLISTS + ', ' + LI_FOLDERS, function (e) {
	e.stopPropagation();
	if ($(this).hasClass('tasklist')) {
		type = TYPE_TASKLIST;
		e.originalEvent.dataTransfer.setData('type', TYPE_TASKLIST);
		e.originalEvent.dataTransfer.setData('tasklistid', $(this).attr('data-postvalue'));
	} else if ($(this).hasClass('folder')) {
		type = TYPE_FOLDER;
		e.originalEvent.dataTransfer.setData('type', TYPE_FOLDER);
		e.originalEvent.dataTransfer.setData('folderid', $(this).attr('data-postvalue'));
	} else {
		type = TYPE_TASK;
		e.originalEvent.dataTransfer.setData('type', TYPE_TASK);
		e.originalEvent.dataTransfer.setData('taskid', $(this).attr('data-taskid'));
		e.originalEvent.dataTransfer.setData('zugehoerigkeit', $(this).attr('data-zugehoerigkeit'));
		z = $(this).attr('data-zugehoerigkeit');
	}
	$(this).css('opacity', '0.3');
});

$(document).on('dragend', LI_TASKS + ', ' + LI_TASKLISTS + ', ' + LI_FOLDERS, function (e) {
	e.stopPropagation();
	$(this).css('opacity', '1');
	z = undefined;
	type = undefined;
});

$(document).on('dragover', LI_TASKS, function (e) {
	e.stopPropagation();
	e.preventDefault();
	if (z != $(this).attr('data-zugehoerigkeit') || !$(this).hasClass('dropable')) {
		return;
	}
	if (isMouseAboveHalfOf(e, this)) {
		$(this).removeClass('dragborder');
		$(this).prev().addClass('dragborder');
	} else {
		$(this).addClass('dragborder');
		$(this).prev().removeClass('dragborder');
	}
});
$(document).on('dragleave', LI_TASKS, function (e) {
	e.stopPropagation();
	e.preventDefault();
	$(this).removeClass('dragborder');
});

$(document).on('drop', LI_TASKS, function (e) {
	const ul = $(this).parent();
	$('li', ul).removeClass('dragborder');

	const id = e.originalEvent.dataTransfer.getData('taskid');
	if ( id == undefined || id == '' )
		return;
		
	const task = $('[data-taskid=' + id + ']', $(this).parent());
	const zugehoerigkeit = e.originalEvent.dataTransfer.getData('zugehoerigkeit');

	if (zugehoerigkeit != $(this).attr('data-zugehoerigkeit') || !$(this).hasClass('dropable')) {
		return;
	}

	let changed = false;
	if (isMouseAboveHalfOf(e, this)) {
		if ($(this).prev()[0] != $(task)[0]) {
			$(task).insertBefore(this);
			changed = true;
		}
	} else {
		if ($(this).next()[0] != $(task)[0]) {
			$(task).insertAfter(this);
			changed = true;
		}
	}

	if (changed) {
		$('li[data-zugehoerigkeit=A-' + id + ']', ul).each(function () {
			$(this).insertAfter(task);
		});

		let positions = [];
		$('li', ul).each(function () {
			if ($('.position', this).length > 0) {
				z = $(this).attr('data-zugehoerigkeit');
				if (positions[z] === undefined) {
					positions[z] = 0;
				}
				positions[z]++;
				$('.position', this).val(positions[z]);
			}
		});

		let order = $('select.order', $(this).parent());
		if ($(order).length > 0 && $(order).val() != 'position-asc') {
			$(order).val('position-asc');
		}
		sendForm($(this).parents('form'), $('input[type=checkbox]', this));
	}
});

$(document).on('dragover', LI_TASKLISTS, function (e) {
	e.stopPropagation();
	e.preventDefault();
	if (type == TYPE_TASK) {
		$(this).addClass('dragover');
	} else if (type == TYPE_TASKLIST) {
		if (isMouseAboveHalfOf(e, this)) {
			$(this).removeClass('dragborder');
			$(this).prev().addClass('dragborder');
		} else {
			$(this).addClass('dragborder');
			$(this).prev().removeClass('dragborder');
		}
		highlightParentFolder(this);
	} else if (type == TYPE_FOLDER) {
		let inset = parseInt($(this).attr('data-inset'));
		let prev = this;
		while ($(prev).length > 0) {
			let prevInset = parseInt($(prev).attr('data-inset'));
			if (prevInset != inset || $(prev).hasClass('folder')) {
				$(prev).addClass('dragborder');
				break;
			}
			prev = $(prev).prev();
		}
		highlightParentFolder(this);
	}
});

$(document).on('dragover', LI_MAGICLISTS, function(e) {
	e.stopPropagation();
	e.preventDefault();
	if (type == TYPE_TASK) {
		$(this).addClass('dragover');
	}
});

function highlightParentFolder(li) {
	let inset = parseInt($(li).attr('data-inset')) - 1;
	let folder = $(li).prev();
	while ($(folder).length > 0) {
		if ($(folder).hasClass('folder') && $(folder).attr('data-inset') == inset) {
			$(folder).first().addClass('draghighlight');
			break;
		}
		folder = $(folder).prev();
	}
}

$(document).on('dragover', LI_FOLDERS, function (e) {
	e.stopPropagation();
	e.preventDefault();
	if (type == TYPE_FOLDER && !isMouseInCenterOf(e, this)) {
		if (isMouseAboveHalfOf(e, this)) {
			$(this).removeClass('dragborder');
			$(this).prev().addClass('dragborder');
			$(this).removeClass('draghighlight');
			highlightParentFolder(this);
		} else {
			$(this).addClass('dragborder');
			$(this).prev().removeClass('dragborder');
			if ($(this).hasClass('expanded')) {
				$(this).addClass('draghighlight');
			} else {
				highlightParentFolder(this);
				$(this).removeClass('draghighlight');
			}
		}
		$(this).removeClass('dragover');
	} else {
		$(this).addClass('dragover');
		$(this).removeClass('dragborder');
		$(this).removeClass('draghighlight');
	}
});
$(document).on('dragleave', LI_TASKLISTS + ', ' + LI_FOLDERS + ', ' + LI_MAGICLISTS, function (e) {
	e.stopPropagation();
	e.preventDefault();
	$('li', $(this).parent()).removeClass('dragover');
	$('li', $(this).parent()).removeClass('dragborder');
	$('li', $(this).parent()).removeClass('draghighlight');
});

$(document).on('drop', LI_MAGICLISTS, function(e) {
	const li = this;
	const id = e.originalEvent.dataTransfer.getData('taskid');
	const tasklistid = $(li).attr('data-postvalue');

	let formData = new FormData();
	formData.append('ajax', true);
	formData.append('action', FORM_ACTION);
	formData.append('package', FORM_PACKAGE);
	formData.append('taskid', id);
	formData.append('tasklistid', tasklistid);
	
	sendFormData(
		$(li).closest('form'),
		li,
		location.href,
		'POST',
		formData,
		undefined,
		FORM_AJAX,
		FORM_AJAXPACKAGE,
		undefined,
		FORM_TARGET,
		undefined,
		undefined,
		undefined,
		'false',
		true
	);	
});

$(document).on('drop', LI_TASKLISTS + ', ' + LI_FOLDERS, function (e) {
	const li = this;
	const ul = $(li).parent();
	$('li', ul).removeClass('dragover');
	$('li', ul).removeClass('dragborder');
	$('li', ul).removeClass('draghighlight');

	let formData = new FormData();
	formData.append('ajax', true);
	formData.append('action', FORM_ACTION);
	formData.append('package', FORM_PACKAGE);

	const type = e.originalEvent.dataTransfer.getData('type');

	if (type == TYPE_TASK) {
		const id = e.originalEvent.dataTransfer.getData('taskid');
		const zugehoerigkeit = e.originalEvent.dataTransfer.getData('zugehoerigkeit').split('-');
		const tasklistid = $(li).attr('data-postvalue');

		if (zugehoerigkeit[0] !== undefined && zugehoerigkeit[0] != 'TL' && zugehoerigkeit[0] != '') {
			let confirmtext = language('js_confirmtasklist_' + zugehoerigkeit[0]);
			$('#main').append(`
				<div class="ajax-content" style="z-index:${$.topZIndex()}">
					<div class="hinweis">
						<form
							class="hinweis__surface"
							action="window-href"
							method="post"
							data-ajax="${FORM_AJAX}"
							data-package="${FORM_AJAXPACKAGE}"
							data-target="${FORM_TARGET}"
							data-urlupdate="false"
							data-remove="hinweis"
						>
							<input type="hidden" name="action" value="${FORM_ACTION}" />
							<input type="hidden" name="package" value="${FORM_PACKAGE}" />
							<input type="hidden" name="taskid" value="${id}" />
							<input type="hidden" name="tasklistid" value="${tasklistid}" />
							<div class="header">${language('js_hinweis')}</div>
							<div class="content">${confirmtext}</div>
							<div class="footer">
								<button type="button" class="button close">${language('js_abbrechen')}</button>
								<button
									type="submit"
									class="button button--primary"
									name="command"
									value="${language('js_copy')}"
								>
									${language('js_copy')}
								</button>
								<button
									type="submit"
									class="button button--primary"
									name="command"
									value="${language('js_move')}"
								>
									${language('js_move')}
								</button>
							</div>
						</form>
					</div>
				</div>
			`);
			return;
		}

		formData.append('taskid', id);
		formData.append('tasklistid', tasklistid);
	} else if (type == TYPE_TASKLIST) {
		const id = e.originalEvent.dataTransfer.getData('tasklistid');
		const liId = $(li).attr('data-postvalue');
		const list = $('li.tasklist[data-postvalue=' + id + ']', $(li).parent());
		if ($(li).hasClass('tasklist')) {
			if (id == liId) {
				return;
			}

			formData.append('tasklistid', id);
			if (isMouseAboveHalfOf(e, li)) {
				formData.append('insertBefore', liId);
				$(list).insertBefore(li);
			} else {
				formData.append('insertAfter', liId);
				$(list).insertAfter(li);
			}

			setInsetFor(list, $(li).attr('data-inset'));
		} else if ($(li).hasClass('folder')) {
			formData.append('tasklistid', id);
			formData.append('folderid', liId);

			if ($(li).hasClass('expanded')) {
				// only move list into folder when it is expaned
				let folderInset = parseInt($(li).attr('data-inset')) + 1;
				if (parseInt($(li).next().attr('data-inset')) != folderInset) {
					// Folder has no children
					$(list).insertAfter(li);
				} else {
					let next = $(li).next();
					let firstList = undefined;
					while (parseInt($(next).attr('data-inset')) >= folderInset) {
						if (
							$(next).hasClass('tasklist') &&
							parseInt($(next).attr('data-inset')) == folderInset &&
							firstList == undefined
						) {
							firstList = next;
							break;
						}
						next = $(next).next();
					}
					if (firstList != undefined) {
						$(list).insertBefore(firstList);
					} else {
						next = $(li).next();
						while ($(next).length > 0) {
							if (parseInt($(next).attr('data-inset')) < folderInset) {
								$(list).insertBefore(next);
								break;
							}
							next = $(next).next();
						}
					}
				}
				setInsetFor(list, folderInset);
			} else {
				$(list).remove();
			}
		}
	} else if (type == TYPE_FOLDER) {
		const id = e.originalEvent.dataTransfer.getData('folderid');
		const liId = $(li).attr('data-postvalue');
		const list = $('li.folder[data-postvalue=' + id + ']', $(li).parent());
		let clone = $(list).clone();
		formData.append('folderid', id);
		if ($(this).hasClass('folder')) {
			if (isMouseInCenterOf(e, this)) {
				formData.append('insertInto', liId);
				$(clone).insertAfter(this);
				setInsetFor(clone, parseInt($(this).attr('data-inset')) + 1);
			} else if (isMouseAboveHalfOf(e, this)) {
				formData.append('insertBefore', liId);
				$(clone).insertBefore(this);
				setInsetFor(clone, parseInt($(this).attr('data-inset')));
			} else if ($(this).hasClass('expanded')) {
				formData.append('insertInto', liId);
				$(clone).insertAfter(this);
				setInsetFor(clone, parseInt($(this).attr('data-inset')) + 1);
			} else {
				formData.append('insertAfter', liId);
				$(clone).insertAfter(this);
				setInsetFor(clone, parseInt($(this).attr('data-inset')));
			}
		} else {
			formData.append('insertLastIntoTasklistParent', $(this).attr('data-postvalue'));
			let inset = parseInt($(this).attr('data-inset'));
			let target = undefined;
			let prev = this;
			while ($(prev).length > 0) {
				let prevInset = parseInt($(prev).attr('data-inset'));
				if (prevInset != inset || $(prev).hasClass('folder')) {
					target = prev;
					break;
				}
				prev = $(prev).prev();
			}
			$(clone).insertAfter(target);
			setInsetFor(clone, parseInt($(this).attr('data-inset')));
		}
		moveChildrenForFolder(list, clone);
		$(clone).css('opacity', 1);
		$(list).remove();
	}

	sendFormData(
		$(li).closest('form'),
		li,
		location.href,
		'POST',
		formData,
		undefined,
		FORM_AJAX,
		FORM_AJAXPACKAGE,
		undefined,
		FORM_TARGET,
		undefined,
		undefined,
		undefined,
		'false',
		true
	);
});

function moveChildrenForFolder(original, clone) {
	let originalInset = parseInt($(original).attr('data-inset'));
	let newInset = parseInt($(clone).attr('data-inset'));
	let diff = newInset - originalInset;
	let next = $(original).next();
	let last = undefined;
	while ($(next).length > 0) {
		if (parseInt($(next).attr('data-inset')) <= originalInset) {
			break;
		}
		if ($(next)[0] == $(clone)[0]) {
			break; // no circles!
		}
		let current = next;
		next = $(next).next();
		if (last == undefined) {
			$(current).insertAfter(clone);
		} else {
			$(current).insertAfter(last);
		}
		setInsetFor(current, parseInt($(current).attr('data-inset')) + diff);
		last = current;
	}
}

function setInsetFor(li, inset) {
	$(li).removeClass('sub-navigation__item--inset' + $(li).attr('data-inset'));
	$(li).addClass('sub-navigation__item--inset' + inset);
	$(li).attr('data-inset', inset);
}

function isMouseAboveHalfOf(e, elem) {
	let top = $(elem).offset().top + $(elem).height() / 2;
	let mouseTop = e.pageY;
	return mouseTop < top;
}

function isMouseInCenterOf(e, elem) {
	let center = $(elem).offset().top + $(elem).height() / 2;
	let mouseTop = e.pageY;
	return mouseTop > center - 5 && mouseTop < center + 5;
}
