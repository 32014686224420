const createSpinner = () => `
	<div class="spinner">
		<div class="spinner__container">
			<div class="spinner__layer">
				<div class="spinner__circle-clipper spinner__left">
					<div class="spinner__circle"></div>
				</div>

				<div class="spinner__circle-clipper spinner__right">
					<div class="spinner__circle"></div>
				</div>
			</div>
		</div>
	</div>
`;

export default createSpinner;
