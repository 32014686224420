<script lang="ts">
	import { randomString } from '../../../utils/random';
	import Button from '../Button';
	import { EventType } from '../../Event';
	import { getStatus, getStatuses } from './utils';

	export let type: EventType = EventType.Event;
	export let status: number = 0;
	export let billed: boolean = undefined;
	export let canceled: boolean = undefined;
	export let readonly: boolean = false;
	export let input: Record<string, string> = undefined;

	const randomId = 'status' + randomString(16);

	$: currentStatus = $getStatus(type, status, billed, canceled);
</script>

<div class="status-holder">
	{#if readonly}
		<span class="button status {currentStatus.color}" aria-label={currentStatus.name} />
	{:else}
		{#if input}<input {...input} type="hidden" bind:value={status} />{/if}

		<Button class="status {currentStatus.color}" title={currentStatus.name} data-bubble={randomId} />

		<div class="bubble menu autosize" id={randomId}>
			<ul>
				{#each $getStatuses(type) as { id, name, color }}
					<li>
						<Button class="close status-choice" on:click={() => (status = id)}>
							<span class="status {color}" />
							{name}
						</Button>
					</li>
				{/each}
			</ul>
		</div>
	{/if}
</div>
