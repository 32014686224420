<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import translate from '../../../i18n';
	import Button from '../../components/Button';
	import Dialog from '../../components/Dialog';
	import Checkbox from '../../components/Checkbox';

	export let translationsOverlap: Record<string, string>;
	// export let id: Number;
	export let overlappingAppointments: Array<any>;

	const dispatch = createEventDispatcher();

	let checked = Object.assign({}, ...overlappingAppointments.map(x => ({ [x.id]: true })));
	let cancelIds = Object.keys(checked)
		.map(x => (checked[x] ? parseInt(x) : 0))
		.filter(x => x);

	const checkboxChange = () => {
		cancelIds = Object.keys(checked)
			.map(x => (checked[x] ? parseInt(x) : 0))
			.filter(x => x);
	};

	const reject = () => dispatch('reject');
	const cancelOther = () => dispatch('resolve', cancelIds);
	const insertAnyway = () => dispatch('resolve', []);
</script>

<Dialog class="overlap-prompt" dark onClose={reject}>
	<div class="content">
		<p
			>{overlappingAppointments.length > 1
				? translationsOverlap.overlappingAppointmentMore
				: translationsOverlap.overlappingAppointmentOne}</p
		>
		<div>
			<table>
				{#each overlappingAppointments as appointment}
					<tr>
						<td>
							<Checkbox bind:checked={checked[appointment.id]} on:change={() => checkboxChange()} />
						</td>
						<td>
							<a href={appointment.link} target="_blank">{appointment.title}</a>
							&nbsp;<small> {@html appointment.overlapIcon} </small>
							<br />
							<small>{appointment.date}</small>
						</td>
						<td><small class="counter {appointment.statusCSS}">{appointment.statusText}</small></td>
					</tr>
				{/each}
			</table>
		</div>
	</div>

	<div class="footer">
		<Button on:click={reject}>{translate('cancel')}</Button>
		<Button class="red" on:click={cancelOther}>{translate('cancelOther')}</Button>
		<Button primary on:click={insertAnyway}>{translate('insertAnyway')}</Button>
	</div>
</Dialog>
