<script lang="ts">
  export let header: string = undefined;
  export let content: string  = undefined;
  export let footer: string  = undefined;
  export let shouldFadeOut: boolean = true;
  export let isHtmlContent: boolean = false;
  export let store: any = null;
  
  let visible = true;
  let opacity = 1;

  if(shouldFadeOut) {
	setTimeout(() => {
    opacity = 0;
      setTimeout(() => {
        visible = false
      }, 3000);
  }, 2000);
  }

</script>


{#if visible}
  <div class="hinweis small" style="z-index: 5000;">
    <div class="hinweis__surface" style={`transition: opacity 5s; opacity: ${opacity};`}>
      <div class="header row">{header}</div>
      <div class="content row">
		{#if isHtmlContent}
			{@html content}
		{:else}
			{content}
		{/if}
	  </div>
      <div class="footer row">
        <a class="close" on:click={() => store && store.set(false)}>{footer}</a>
      </div>
    </div>
  </div>
{/if}

