<script lang="ts">
	import { v4 as uuidv4 } from 'uuid'; 
	import { faSort } from '@fortawesome/free-solid-svg-icons';
	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
	import {
		Job,
		AddToBackendData,
		KategorieID,
		BackendData,
		Key,
		Index,
		TerminMaKategorieID,
		CssStatus,
		HolidayEntry,
		Translations,
		SourceNode,
		RequestStatus
	} from '../types';
	import { status, contextMenuVisibleStore, sourceNodeStore, isCategoriesCheckedStore, isOnlyFutureJobsViewStore } from '../stores/stores';
	import ContextMenu from './ContextMenu.svelte';
	import { convertDateFormat } from "../utils/utilsStaffplan";
	
	export let index: Index;
	export let job: Job;
	export let jobs;
	export let addToBackendData: AddToBackendData;
	export let kategorieID: KategorieID;
	export let check: boolean;
	export let backendData: BackendData;
	export let key: Key;
	export let resource;
	export let translations : Translations;
	export let timeoffcategories;
	export let hasCategory;

	const { ID: terminID, Resources: jobResources, DatumVon: startDate, DatumBis } = job;
	const {
		ZugehoerigkeitID: zugehoerigkeitID,
		Zugehoerigkeit: zugehoerigkeit,
		FreierMitarbeiter: freierMitarbeiter,
		SubEmployeeID: subEmployeeId,
		Urlaube: holiday,
		Kategorien: resourcesCategories,
		Krankmeldungen: Krankmeldungen,
	} = resource;

	let terminMaKategorieID: TerminMaKategorieID;
	let foundResourceCategory;
	let foundResource: any;
	let className = 'center ';
	let cssStatus: CssStatus;
	let contextMenuId = uuidv4();
	let contextMenuVisible: boolean;
	let holidayEntries = Object.entries(holiday)
	let lastJobPerDay = new Map();
	let anfragenvarianten = '';
	let temaid : number | 'neu';
	let isProject: boolean;
	let mainjobOrProjectId;
	let classesArray = [
        RequestStatus.REQUESTED, RequestStatus.AVAILABLE, RequestStatus.BOOKED, 
        RequestStatus.REJECTED, RequestStatus.NV, RequestStatus.NA
    ];
	let contextMenuProps = {
	timeoffcategories,
	translations,
	zugehoerigkeit,
	closeContextMenu,
	changeClassNameAndAddToBackendData
	}

    $: temaid = foundResource ? foundResource.ID : 'neu';
	status.subscribe(value => {
		anfragenvarianten = value;
	});

	sourceNodeStore.subscribe((sourceNode: SourceNode) => {
		mainjobOrProjectId = sourceNode.ID;
		isProject = sourceNode.Subtyp === 'P';
	})

	if (kategorieID && resourcesCategories) {
		foundResourceCategory = Object.values(job.Kategorien).find(resource => resource.KategorieID === kategorieID);

		if (foundResourceCategory) {
			terminMaKategorieID = foundResourceCategory.ID;
		}

		foundResource = jobResources.find(
			resource => resource.ResourceKey === key && resource.TerminMaKategorieID === terminMaKategorieID
		);


		if (foundResource) {
			if(foundResource.Status.CSS === "eingetragen") {
				cssStatus = "verfuegbar";
				className += "verfuegbar";
			} else {
				cssStatus = foundResource.Status.CSS;
				className += foundResource.Status.CSS;
			}
		}
	} else {
		foundResource = jobResources.find(resource => resource.ResourceKey === key);

		if (foundResource) {
			if($isCategoriesCheckedStore) {
				if(hasCategory || foundResource.TerminMaKategorieID === 0) {
    				if(foundResource.Status.CSS === "eingetragen") {
      					cssStatus = "verfuegbar";
      					className += "verfuegbar";
    				} else {
      					cssStatus = foundResource.Status.CSS;
      					className += foundResource.Status.CSS;
   					}
 		 }
			} else {
				if (foundResource.Status.CSS === "eingetragen") {
        			cssStatus = "verfuegbar";
        			className += "verfuegbar";
    			} else {
        			cssStatus = foundResource.Status.CSS;
        			className += foundResource.Status.CSS;
    			}
			}
	}
	};

	$: {
	// Add CSS classes for holidays
    	holidayEntries.forEach(([key, val]: [string, HolidayEntry]) => {
    	    let keyInNewFormat = key.split('-').reverse().join('.');
    	    let color = val.Farbe;
    	    if (startDate == keyInNewFormat) {
    	        className = color + ' na center';
    	        check = true;
    	    }
    	});

	// Add CSS classes for sick notice
    	let convertedDate = convertDateFormat(startDate);
    	Object.keys(Krankmeldungen).forEach(krankmeldung => {
    	    if (krankmeldung === convertedDate) {
    	        className = "center na U staffplan-cell";
    	    }
    	});
	}

	async function checkForDuplicates(currentElement: any) {
        let selector = `td[data-terminid='${terminID}'][data-zugehoerigkeitid='${zugehoerigkeitID}'][data-zugehoerigkeit='${zugehoerigkeit}']`;

		if (freierMitarbeiter !== null) {
    		selector += `[data-freierMitarbeiter='${freierMitarbeiter}']`;
		}

		let sameTerminIdElements = document.querySelectorAll(selector);

		if(zugehoerigkeit !== 'D') {
			sameTerminIdElements.forEach(element => {
			classesArray.forEach(cls => {
				if(element !== currentElement && element.classList.contains(cls)) {
				className = 'center '
                element.classList.remove(cls);
            }
			})      
        });
		}
       
    }
	
	function changeClassName(color: string, urlaubskategorieid: number) {
		if (className === 'center ' + anfragenvarianten || anfragenvarianten === '') {
			className = 'center ';
			check = false;
		} else {
			className = 'center ' + anfragenvarianten;
			if (color) {
            	className = 'center na ' + color;
        	}
			check = true;
		}

		if(urlaubskategorieid === undefined) {
			urlaubskategorieid = null;
		}
	}

	
	async function changeClassNameAndAddToBackendData(currentElement: any, urlaubskategorieid?: number, color?: string) {	
		let subEmployee;

		let trElement = currentElement.parentNode;
		let firstTdElement = trElement.querySelector('td');
		let div = firstTdElement ? firstTdElement.querySelector('.select-wrapper') : null;
		let selectEl = div ? div.querySelector('select') : null;
	

		if(selectEl) {
		    subEmployee = selectEl.value;
		} else {
		    subEmployee = subEmployeeId;
		} 
	
		await checkForDuplicates(currentElement);
		changeClassName(color, urlaubskategorieid);
		addToBackendData(
			terminID,
			zugehoerigkeit,
			zugehoerigkeitID,
			kategorieID,
			check,
			temaid,
			backendData,
			freierMitarbeiter,
			subEmployee,
			urlaubskategorieid
		);
	}

	for (let i = 0; i < jobs.length; i++) {
		const currentJob = jobs[i];
		lastJobPerDay.set(currentJob.DatumVon, currentJob);
	}

	// ContextMenu Logic:
	function openContextMenu(event : MouseEvent) {
    	event.preventDefault();
		closeContextMenu();
		contextMenuVisibleStore.set(contextMenuId);
  	}

  	function closeContextMenu() {
		contextMenuVisibleStore.set(null);
  	}

	$: {
        contextMenuVisibleStore.subscribe(value => {
            contextMenuVisible = value === contextMenuId;
        });
    }


	function setClassNameBasedOnBackendData(data: any, terminid: number, kategorieid: number, zugehoerigkeitid: number, zugehoerigkeit: string) {
    const matchingItem = data.find((item: BackendData) => {
      return item.terminid === terminid && item.kategorieid === kategorieid && item.zugehoerigkeitid === zugehoerigkeitid && item.zugehoerigkeit === zugehoerigkeit;
    });
    if (matchingItem) {
		switch(matchingItem.anfragenvariante) {
			case 'anfragen':
			className = 'angefragt';
			break;
			case 'buchen':
			className = 'gebucht';
			break;
			case 'eintragen':
			className = 'verfuegbar';
			break;
			case 'ablehnen':
			className = 'abgelehnt';
			break;
			case 'nv':
			className = 'nv';
			break;
		} 
    } 
  	};

  	setClassNameBasedOnBackendData(backendData, terminID, kategorieID, zugehoerigkeitID, zugehoerigkeit);

	  
    let currentDate = new Date();
</script>



{#if !$isOnlyFutureJobsViewStore || (new Date(DatumBis.split(".").reverse().join("-")) > currentDate)}
	{#if index === 0}
		<td class="dragndrop icon">
			<FontAwesomeIcon icon={faSort} class="icon icon--sort blue" />
		</td>
		<td
			data-temaid={temaid}
			class={className + " staffplan-cell"}
			data-terminid={terminID}
			data-zugehoerigkeit={zugehoerigkeit}
			data-zugehoerigkeitid={zugehoerigkeitID}
			data-anfragenvariante={cssStatus}
			data-index={index}
			data-kategorieid={kategorieID}
			data-freierMitarbeiter={freierMitarbeiter}
			on:click={(e) =>changeClassNameAndAddToBackendData(e.currentTarget, null)}
			on:contextmenu={openContextMenu}
			style="position:relative"
		>
			<!-- svelte-ignore a11y-label-has-associated-control -->
			<label class="active" />
		{#if contextMenuVisible}
			<ContextMenu {...contextMenuProps} />
		{/if}
		</td>

		{#if !isProject}
			<td class="space" />
			<td class="space black" />
			<th class="space" />
		{/if}
	{:else}
		<td
			data-temaid={temaid}
			class={className + " staffplan-cell"}
			data-terminid={terminID}
	        data-zugehoerigkeit={zugehoerigkeit}
			data-zugehoerigkeitid={zugehoerigkeitID}
			data-anfragenvariante={cssStatus}
			data-index={index}
			data-kategorieid={kategorieID}
			data-freierMitarbeiter={freierMitarbeiter}
			on:click={(e) =>changeClassNameAndAddToBackendData(e.currentTarget,null)}
			on:contextmenu={openContextMenu}
			style="position:relative"
		>
			<!-- svelte-ignore a11y-label-has-associated-control -->
			<label class="active" />
		{#if contextMenuVisible}
			<ContextMenu {...contextMenuProps} />
		{/if}
		</td>

		{#if job === lastJobPerDay.get(job.DatumVon)}
			<td class="space black" />
		{/if}
	{/if}
	{/if}