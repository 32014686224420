<script lang="ts">
	//utils
	import { onDestroy, onMount } from 'svelte';
	import type { Resource, Category, Job, Legend, Translations, CellObject, JobResources } from './types';
	import { addToBackendData, backendData, postData, createCellObject, sortAlphabeticallyByPropertyName, staffplanErrorStore, staffplanErrorMessage } from './utils/utilsStaffplan';
	import { jobResourcesJS, resetStores, displaySelectStatus, useNewStaffplanStore, sourceNodeStore, isCategoriesCheckedStore, cellsArrayStore, isOnlyFutureJobsViewStore } from './stores/stores';
	import { addEventListenersForSelection, removeEventListenersForSelection } from './utils/utilsSelection';
	//components
	import SelectStatus from "./components/SelectStatus.svelte";
	import Legende from './components/Legende.svelte';
	import Jobs from './components/Jobs.svelte';
	import StatusCheckbox from './components/StatusCheckbox.svelte';
	import Tablerow from './components/Tablerow.svelte';
	import CategoryTablerow from './components/CategoryTablerow.svelte';
	import AlertInfo from '../../components/AlertInfo/AlertInfo.svelte';
	//props
    export let sourceNode;
	export let translations: Translations;
	export let legend: Legend[];
	export let jobs: Job[];
	export let categories: Category[];
	export let resources: Resource[];
	export let timeoffcategories;
	export let isCategoriesView: boolean;
	export let isOnlyFutureJobsView;

	isCategoriesCheckedStore.set(isCategoriesView);
	isOnlyFutureJobsViewStore.set(isOnlyFutureJobsView);

	useNewStaffplanStore.set(true);
    sourceNodeStore.set(sourceNode);
	let resourcesEntries = Object.entries(resources);
	let check: boolean;
	let cellObjects: CellObject[] = [];
	let tablerowContentProps = {
		addToBackendData,
		backendData,
		jobs,
		check,
		translations,
		resourcesEntries,
		timeoffcategories
	};
	
	jobResourcesJS.set(jobs);
	resetStores();


	onMount(() => {
		const form: HTMLFormElement = document.querySelector('form.hinweis__surface');
		let shouldHandleSubmit = true;
		if (form) {
			const onSubmitListener = async (event: SubmitEvent) => {
				if (!shouldHandleSubmit) {
					return;
				}

				/**
				 * Add data-svelte attribute which is used to prevent the form from being submitted by jquery
				 * Ref: assets/src/js/legacy/index.js - Function: sendForm()
				*/
				form.setAttribute('data-svelte', 'true')
				// prevent the default form behaviour: reloading the page
				event.preventDefault()
				
				// await our postData function to finish before submitting the form
				const { success } = await postData();
				
				// remove the attribute to allow form submission agian
				form.removeAttribute('data-svelte');
				
				// if postData is successful, submit the form by looking for the clicked submit button
				if (success) {
					const submitButton = document.querySelector('[data-clicked=true]') as HTMLButtonElement;
					shouldHandleSubmit = false;
					submitButton.click();
				}
			}

			form.addEventListener('submit', onSubmitListener);


			return () => {
				// remove the event listener when the component is destroyed
				form.removeEventListener('submit', onSubmitListener);
			}	
		}

	})
	

	// Iterates over all resources and jobs to create and collect cell objects.
	resourcesEntries.forEach((resourceValue) => {
	    let resource = resourceValue[1];
	    let zugehoerigkeitid = resource.ZugehoerigkeitID;
	    let zugehoerigkeit = resource.Zugehoerigkeit;
	    let resourceCategories = resource.Kategorien;

	    jobs.forEach((job) => {
	        let terminid = job.ID;
	        let jobResources: JobResources[] = job.Resources;
	        let jobResource = jobResources.find((resource: JobResources) => resource.Zugehoerigkeit === zugehoerigkeit && resource.ZugehoerigkeitID === zugehoerigkeitid)
	        let temaid = jobResource ? jobResource.ID : 'neu';

	        resourceCategories.forEach(resourceCategory => {
	            let kategorieid = resourceCategory === 0 ? null : resourceCategory.KategorieID;
	            let cellObject = createCellObject(temaid, terminid, zugehoerigkeitid, zugehoerigkeit, kategorieid);
	            cellObjects.push(cellObject);
	        })
	    })
	});

	$: if (cellObjects.length > 0) {cellsArrayStore.set(cellObjects)};

	resourcesEntries.sort(sortAlphabeticallyByPropertyName);
	
	addEventListenersForSelection();

	onDestroy(() => { 
  		removeEventListenersForSelection();
	});
</script>


{#if $staffplanErrorStore}
	<AlertInfo header="Fehler" content={$staffplanErrorMessage} footer="Ok" shouldFadeOut={false} isHtmlContent={true} store={staffplanErrorStore}/>
{/if}



{#if $displaySelectStatus}
	<SelectStatus {translations}/>
{/if}


<table class="scroll-fixed scroll-x uebersichtskalender planung personalplan" data-dnd="mitarbeiter" data-dnd-scrollcontainer="eventdetails-content" data-dnd-scrollareasizetop="200">
	<thead>
		<tr>
			<StatusCheckbox {translations} />
			<Jobs {jobs} />
		</tr>
	</thead>

	<tbody>
		{#if isCategoriesView}
			<Tablerow {...tablerowContentProps} loadAll={false} />
			<CategoryTablerow {...tablerowContentProps} {categories} {jobs} />
		{:else}
			<Tablerow {...tablerowContentProps} loadAll={true} />
		{/if}
	</tbody>
</table>


<Legende {legend} />
