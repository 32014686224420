<script lang="ts">
	import {faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
	import NewButton from "../../components/NewButton/NewButton.svelte";
	import {   getAllJobs } from './utils/apis';
	import { createMainJobObject, createSubjobData } from "./utils/utils";
	import { selectedJobsStore, selectedCategoriesStore, nextViewButtonEnabledStore, resourcesSelectedStore, resetStores, isProjectStore } from './stores/stores';
	import { Translations, JobData, Views } from './types';
	import Header from './components/Header.svelte';
	import Subjobs from './pages/Subjobs.svelte';
	import Categories from './pages/Categories.svelte';
	import Recipient from './pages/Recipient.svelte';
	import Review from './pages/Review.svelte';
	import AlertError from "../../components/AlertError/AlertError.svelte";
	import logError from "../../../utils/log-error";

	export let translations: Translations;
	export let sourceNode;

	const isProject = sourceNode.Typ === 'PR';
	isProjectStore.set(isProject);

	const views = [Views.VIEW_SUBJOBS, Views.VIEW_CATEGORIES, Views.VIEW_RECIPIENT, Views.VIEW_REVIEW ];
	const viewsTranslation = views.map(view => getViewName(view));

	$: currentViewIdx = 0;

	let inputEl = document.querySelector('input[name=terminid]') as HTMLInputElement;
	let mainJobId = Number(inputEl.value);
	let jobsData: JobData[] = [];
	let error: boolean = false;
    let isSingleJob = false;
	let mainJobOrProjectObject : any;
	let dataLoaded: boolean = false;

	function getViewName(view: any) {
		switch(view) {
			case Views.VIEW_SUBJOBS:
			 	return	translations.subjobs;
			case Views.VIEW_CATEGORIES:
			 	return	translations.categories;
			case Views.VIEW_RECIPIENT:
			 	return	translations.recipient;
			case Views.VIEW_REVIEW:
			 	return	translations.check;
		}
	}

	resetStores(false);
	
	$: {
		let currentView = views[currentViewIdx];
		if (currentView === Views.VIEW_SUBJOBS && $selectedJobsStore.length > 0) {
			nextViewButtonEnabledStore.set(true);
		} else if (currentView === Views.VIEW_CATEGORIES && $selectedCategoriesStore.length > 0) {
			nextViewButtonEnabledStore.set(true);
		} else if (currentView === Views.VIEW_RECIPIENT && $resourcesSelectedStore) {
			nextViewButtonEnabledStore.set(true);
		} else {
			nextViewButtonEnabledStore.set(false);
		}
	}

	function nextView() {
    if (currentViewIdx < views.length - 1) {
		currentViewIdx++;
    }
  	}

  	function previousView() {
    if (currentViewIdx > 0) {
		currentViewIdx--;
    }
  	}

	async function fetchJobs(mainJobId: number) {
		try {
			let allJobsData = await getAllJobs(mainJobId, isProject);
			allJobsData = Object.values(allJobsData);
			allJobsData = allJobsData[0];

			if (isProject) {
				mainJobOrProjectObject = createSubjobData(allJobsData.Subjobs);
				jobsData = mainJobOrProjectObject;
				dataLoaded = true;
			} 

			if(!isProject) {
				mainJobOrProjectObject = createMainJobObject(mainJobId, allJobsData);
				if(allJobsData.Subjobs) {
					mainJobOrProjectObject.subjobData = createSubjobData(allJobsData.Subjobs);
					jobsData.push(mainJobOrProjectObject);
				}
				if(!allJobsData.Subjobs) {
					// check if there is only one main job with no sub-jobs
					selectedJobsStore.set([mainJobOrProjectObject]);
					isSingleJob = true;
            		nextView();  
				}
				dataLoaded = true;
			}
		 // Triggers rerendering
         jobsData = jobsData;
	}
    catch (err) {
		logError(`Error fetching jobs, mainJobId: ${mainJobId}`, err.stack);

        error = true;
        throw err;
    }
	};

  	 fetchJobs(mainJobId);
</script>

 
  {#if error}
  	<AlertError message={translations.notFoundResource} type="error"/>
  {:else}

  <Header {translations} bind:currentViewIdx  {viewsTranslation} {isSingleJob}/>

  {#if views[currentViewIdx] === Views.VIEW_SUBJOBS}
	<Subjobs {translations} {jobsData} {dataLoaded}/>
  {:else if views[currentViewIdx] === Views.VIEW_CATEGORIES}
	<Categories {translations} {mainJobId} bind:currentViewIdx  />
  {:else if views[currentViewIdx] ===  Views.VIEW_RECIPIENT}
	<Recipient {translations} {mainJobId} />
  {:else if views[currentViewIdx] ===  Views.VIEW_REVIEW}
	<Review {translations} {mainJobId} />
  {/if}
  
  
  {#if ! (views[currentViewIdx] === Views.VIEW_SUBJOBS || ( views[currentViewIdx] === Views.VIEW_CATEGORIES && isSingleJob ) )}
  	<!--Back Button -->
	<NewButton type={"tertiary"} leadingIcon={faAngleLeft} text={translations.previous} buttonClass={"tender-bottom-left-btn"} iconClass={"tender-previous-view-icon"} on:click={previousView}/>
  {/if}
  
  {#if views[currentViewIdx] !== Views.VIEW_REVIEW}
  	<!--Next Button -->
	<NewButton type={"tertiary"} trailingIcon={faAngleRight} text={translations.next} buttonClass={`tender-bottom-right-btn tender${getViewName(views[currentViewIdx])}`} iconClass={"tender-next-view-icon"} disabled={!$nextViewButtonEnabledStore} on:click={nextView} />
  {/if}
{/if}
