<script lang="ts">
	import { faBriefcase, faCheckToSlot, faCalendarDays, faCalendarXmark, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
	import { status } from '../stores/stores';
	import { RequestStatus, Translations } from '../types';
	import { contextMenuVisibleStore } from '../stores/stores';
	export let translations: Translations;
	export let timeoffcategories : any[];
	export let zugehoerigkeit: string;
	export let closeContextMenu : () => void;
	export let changeClassNameAndAddToBackendData : (currentElement : any ,urlaubskategorieid?: number, color?: string) => void;

	import ContextMenuButton from './ContextMenuButton.svelte';

	async function updateStatusAndData(event: MouseEvent, statusChoice: string, urlaubskategorieid?: number, color?: string) {
		event.stopPropagation();
		event.preventDefault();
		status.set(statusChoice);
		await changeClassNameAndAddToBackendData(event.target, urlaubskategorieid, color);
		contextMenuVisibleStore.set(null);
		status.set("angefragt");
	}
</script>
<div class="contextmenu">
	<ul>
		<ContextMenuButton on:click={(event) => updateStatusAndData(event, RequestStatus.REQUESTED)} icon={faBriefcase} label={translations.requested}/>
		<ContextMenuButton on:click={(event) => updateStatusAndData(event, RequestStatus.AVAILABLE)} icon={faCheckToSlot} label={translations.enter}/>
		<ContextMenuButton on:click={(event) => updateStatusAndData(event, RequestStatus.BOOKED)} icon={faCalendarDays} label={translations.book}/>
        <ContextMenuButton on:click={(event) => updateStatusAndData(event, RequestStatus.REJECTED)} icon={faCalendarDays} label={translations.rejected}/>
		<hr>
		<ContextMenuButton on:click={(event) => updateStatusAndData(event, "nv")} icon={faCalendarXmark} label={translations.absent}/>

	{#if !['F', 'RO'].includes(zugehoerigkeit) }
		{#each timeoffcategories as category}
			{#if category.Typ === "U"}
				<ContextMenuButton on:click={ (event) => updateStatusAndData(event, "nv", category.ID, category.Farbe)} icon={faCalendarXmark} label={category.Bezeichnung}/>
			{/if}
		{/each}
		<hr>
	{/if}

		<!-- Sick notice (only for employees) -->
	{#if zugehoerigkeit === "M"}
		{#each timeoffcategories as category}
			{#if category.Typ === "K"}
				<ContextMenuButton on:click={ (event) => updateStatusAndData(event, "nv", category.ID, category.Farbe)} icon={faUserDoctor} label={translations.sickNotice}/>
			{/if}
		{/each}
	{/if}
	</ul>
</div>

<svelte:window on:click={closeContextMenu} />
