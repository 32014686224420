<svelte:options immutable />

<script lang="ts">
	import ChevronDown from 'icon:chevron-down';
	import dataAttributes from '../../../utils/data-attributes';

	const className: string = '';
	export { className as class };
	export const autoSubmit: boolean = false;
	export let name: string = undefined;
	export let value: string | number = undefined;
	export let selectClass: string = undefined;
	export let showOptgroupName: boolean = undefined;
	export let data: Record<string, string> = undefined;
	export let options: any[] = [];

	const noNumberRegex = /[^0-9]/;

	$: getOptgroupName = () => {
		for (const opt of Object.values(options)) {
			if (Object.keys(opt).includes('group')) {
				for (let i = 0; i < opt.options.length; i++) {
					if (opt.options[i].value == value) {
						return opt.group;
					}
				}
			}
		}
		return '';
	};

	$: if (typeof value === 'string' && value.length && !noNumberRegex.test(value)) {
		value = Number(value);
	}
</script>

<div class={'select-wrapper' + (getOptgroupName() != '' ? ' optgroup-name--visible' : '')}>
	{#if showOptgroupName && getOptgroupName() != ''}
		<small> {getOptgroupName()} </small>
	{/if}

	<!-- svelte-ignore a11y-no-onchange -->
	<select
		{...dataAttributes(data)}
		{...$$restProps}
		class={selectClass + (showOptgroupName ? ' optgroup-name' : '')}
		{name}
		bind:value
		on:change
	>
		{#each Object.values(options) as opt, i}
			<!-- subdivision -->
			{#if Object.keys(opt).includes('group')}
				<optgroup label={opt.group}>
					<!-- categories in subdivision -->
					{#each Object.values(opt.options) as { disabled, indent, label, value, data }, i}
						<option {...dataAttributes(data)} {disabled} value={value || value == 0 ? value : undefined}>
							{@html indent > 0 ? '&nbsp;'.repeat(indent) : ''}{label}
						</option>
					{/each}
				</optgroup>
				<!-- categories without subdivision -->
			{:else}
				<option {...dataAttributes(opt.data)} value={opt.value || opt.value == 0 ? opt.value : undefined}>
					{@html opt.indent > 0 ? '&nbsp;'.repeat(opt.indent) : ''}{opt.label}
				</option>
			{/if}
		{/each}
	</select>

	<ChevronDown />
</div>
