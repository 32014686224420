<script lang="ts">
	import PersonellRequirements from "../../Event/TenderEmail/components/PersonellRequirements.svelte";
import { PersonnelRequirements } from "../../Event/TenderEmail/types";

	export let checked: boolean = false;
	export let disabled: boolean = false;
	export let label: string = undefined;
    export let personnelRequirements: PersonnelRequirements = undefined;
	export let subLabel: string = undefined;
	export let name: string = undefined;
	export let value: string | number = undefined;
	export let input: Record<string, string> = {};
	export let indexProp : number = null;

	export let group: any[] = undefined;
	export let mixedState = false;
	

	$: if (group) updateCheckbox(group)
  	$: if (group) updateGroup(checked)
	
	function updateCheckbox(group : any) {
    	checked = group.includes(indexProp);
  	}

  	function updateGroup(checked : any) {
  	  if (checked) {
  	    if (!group.includes(indexProp)) {
  	      group.push(indexProp);
  	      group = [...group];
  	    }
  	  } else {
  	    if (group.includes(indexProp)) {
  	      group = group.filter(id => id !== indexProp);
  	    }
  	  }
  	}
</script>

<label class="checkbox">
	<input type="checkbox" on:click on:change bind:checked={checked} {disabled} {name} bind:value {...input} />

	<div>
		{#if !mixedState}
		<div class="mark" role="presentation">
			<svg viewBox="0 0 12 10">
				<polyline points="1.5 6 4.5 9 10.5 1" />
			</svg>
		</div>
		{:else}
		<div class="mark minus" id="minus" role="presentation">
			<svg class="minus-svg" viewBox="0 0 12 2">
				<line x1="0" y1="1" x2="12" y2="1" />
			</svg>
		</div>
		{/if}

		{#if label}
			<span>
                {#if personnelRequirements} 
                    {label} <PersonellRequirements 
                        staffBooked={personnelRequirements.staffBooked} 
                        staffNeeds={personnelRequirements.staffNeeds} 
                        staffRequested={personnelRequirements.staffRequested} 
                        staffEntered={personnelRequirements.staffEntered}
                    />
                {:else }
                    {label} 
                {/if}

                {#if subLabel }
                    <small class="sublabel"> {subLabel} </small>
                {/if}
            </span>
		{/if}
	</div>
</label>


