export type Translations = Record<string, string>;
export type CheckedSubjob = number;
export type EmployeeName = string;

export type DataValues = {
	KundeProjektHauptjob: string;
	JobID: number;
	Titel: string;
	ID: number;
	StaffBooked: number;
	StaffEntered: number;
	StaffNeeds: number;
	StaffRequested: number;
};

export type JobData = {
	jobId: number;
	title: string;
    finished: boolean,
    dateTimespan: string,
	subjobData?: JobData[];
	staffBooked?: number;
	staffEntered?: number;
	staffNeeds?: number;
	staffRequested?: number;
};

export type ReviewData = {
	TenderText: string;
	recipients: ReviewDataRecipients[];
};

export type ReviewDataRecipients = { id: number; name: string, overlapIcon: string, overlapType: string };

export type ReviewStore = {
	TenderText: string;
	recipients: ReviewDataRecipients[];
};

export type CategoryData = {
	ID: number;
	Description: string;
	Color: string;
};

export type Category = {
	ID: number;
	Description: string;
	Color: string;
};
export type AvailableEmployee = {
	EmployeeID: number;
	Type: string;
	ResourceKey: string;
};

export type AvailableEmployees = {
	[key: string]: AvailableEmployee[];
};

export type Job = {
	ID: number;
	Titel: string;
	DatumVon: string;
	DatumBis: string;
	ZeitVon: string;
	ZeitBis: string;
	categories: any[];
	tenderJobEmployees: any[];
	jobEmployees: any[];
	availableEmployees: AvailableEmployees;
};

export type EmployeeType = {
	EmployeeID: number;
	Type: string;
	FullName: string;
	FirstName: string;
	LastName: string;
	Skilllevel: string | null;
	ResourceKey: string;
};

type FilterProps = {
	default: string[];
	extra: {
		type: string;
		props: {
			category: {
				selected: string;
			};
		};
	}[];
};

export type EmployeeRequestType = {
	setFilters: (props: FilterProps) => void;
};

export type PersonnelRequirements = {
    staffBooked: number;
	staffEntered: number;
	staffNeeds: number;
	staffRequested: number;
}

export enum Views {
	VIEW_SUBJOBS, 
	VIEW_CATEGORIES, 
	VIEW_RECIPIENT, 
	VIEW_REVIEW
}