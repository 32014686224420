<script lang="ts">
	import { onDestroy } from 'svelte';
	import Button from '../../components/Button';
	import InfiniteScroll from '../../components/InfiniteScroll';
	import Spinner from '../../components/Spinner';
	import Event from './Event.svelte';
	import Select from '../../components/Select';

	import {
		deleteRequestableModifier,
		getEvent,
		getPastSubEvents,
		getSubEvents,
		mergeEvent,
		updateRequestableModifier,
		unique,
	} from './helpers';
	import type { Events, LoadSubEvents, UpdateEvent } from './types';
	import EventGroup from './EventGroup.svelte';

	export let canModify: boolean = false;
	export let canDelete: boolean = false;
	export let showNumbers: boolean = false;
	export let groupByDay: boolean = false;
	export let expandAll: boolean = false;

	export let id: number;
	export let events: Events;
	export let pastEvents: number;
	export let moreEvents: number;
	export let loadedPast: number = 0;
	export let translations: Record<string, string>;

	let loadingPast: boolean = false;
	let loadingMore: boolean = false;
	$: loadedMore = events.map(e => e.id).filter(unique).length;

	let currentDay: string;
	export let expandedJobIds: number[];
	expandedJobIds = !expandedJobIds ? [] : expandedJobIds;

	export let availabilityAll: string;
	export let canChangeAvailability: boolean = false;
	$: canChangeAvailability = events.map(e => e['canChangeAvailability'] === true).filter(Boolean).length > 0;

	export const groupBy = key => array =>
		array.reduce((objectsByKeyValue, obj) => {
			const value = obj[key];
			objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
			return objectsByKeyValue;
		}, {});

	const update: UpdateEvent = async ({ eventId, path }) => {
		try {
			const updatedEvent = await getEvent(eventId);
			if (updatedEvent) {
				events = mergeEvent(events, eventId, path, event => ({ ...event, ...updatedEvent }));
			}
		} catch (error) {
			if (__DEV__) {
				console.error('Event could not be updated', error);
			}
		}
	};

	const loadPastSub: LoadSubEvents = async ({ eventId, start, path }) => {
		const response = await getPastSubEvents({ eventId, start });
		if (response) {
			events = mergeEvent(events, eventId, path, event => ({
				...event,
				pastEvents: response.pastEvents,
				loadedPast: event.loadedPast + response.events.length,
				subEvents: [...response.events, ...event.subEvents],
			}));
		}
	};

	const loadMoreSub: LoadSubEvents = async ({ eventId, start, path }) => {
		const response = await getSubEvents({ eventId, start });
		if (response) {
			events = mergeEvent(events, eventId, path, event => ({
				...event,
				moreEvents: response.moreEvents,
				subEvents: [...event.subEvents, ...response.events],
			}));
		}
	};

	const loadPast = async () => {
		if (!loadingPast && pastEvents) {
			try {
				loadingPast = true;

				const response = await getPastSubEvents({ eventId: id, start: loadedPast });
				if (response) {
					currentDay = undefined;
					pastEvents = response.pastEvents;
					loadedPast += response.events.length;
					events = [...response.events, ...events];
				}
			} catch (error) {
				throw error;
			} finally {
				loadingPast = false;
			}
		}
	};

	const loadMore = async () => {
		if (!loadingMore && moreEvents) {
			try {
				loadingMore = true;

				const response = await getSubEvents({ eventId: id, start: loadedMore });
				if (response) {
					currentDay = undefined;
					moreEvents = response.moreEvents;
					events = [...events, ...response.events];
				}
			} catch (error) {
				throw error;
			} finally {
				loadingMore = false;
			}
		}
	};

	$: updateRequestableModifier(events);

	onDestroy(deleteRequestableModifier);
</script>

<ul
	class="listview"
	data-sendform="termin"
	data-ajax="printSeiteUebersicht"
	data-package="TerminSeiteUntertermine"
	data-target="resultlist"
	data-urlupdate="true"
	data-remove="false"
	data-action="window-href"
>
	{#if loadingPast}
		<li class="hinweis height-normal">
			<Spinner />
		</li>
	{:else if pastEvents}
		<li class="hinweis height-normal">
			{translations[pastEvents === 1 ? 'pastEvent' : 'pastEvents'].replace('{0}', String(pastEvents))}
			<Button link inline on:click={loadPast}>{translations.show}</Button>
		</li>
	{/if}

	{#if groupByDay}
		{#each Object.entries(groupBy('day')(events)) as [day, events], index (day)}
			<EventGroup
				{day}
				{events}
				{groupByDay}
				{expandAll}
				{expandedJobIds}
				{canModify}
				{canDelete}
				{showNumbers}
				{translations}
				{update}
				{loadPastSub}
				{loadMoreSub}
			/>
		{/each}
	{:else}
		{#each events as { day, ...event }, index (event.id)}
			<Event
				{...event}
				{groupByDay}
				{expandAll}
				{expandedJobIds}
				identifier={event.id}
				{canModify}
				{canDelete}
				{showNumbers}
				{translations}
				{update}
				{loadPastSub}
				{loadMoreSub}
			/>
		{:else}
			<li class="hinweis">{translations.noItems}</li>
		{/each}
	{/if}
</ul>

{#if loadingMore}
	<Spinner />
{/if}

{#if moreEvents}
	<InfiniteScroll load={loadMore} margin="80px" />
{/if}
