<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import { Job, KategorieID, RemoveEvent } from '../types';
	import Employee from './Employee.svelte';
	import JobStatusField from './JobStatusField.svelte';

	export let translations;
	export let jobs: Job[];
	export let resource;
	export let addToBackendData;
	export let backendData;
	export let check;
	export let key;
	export let kategorieID: KategorieID = null;
	export let timeoffcategories;
	export let hasCategory: boolean;

	let employeeProps = {
		translations,
		kategorieID,
		key,
		resource,
	};
	let jobStatusFieldProps = {
		addToBackendData,
		backendData,
		kategorieID,
		key,
		check,
		jobs,
		resource,
		translations,
		timeoffcategories
	};

	const dispatch = createEventDispatcher();

	function handleRemove(event: RemoveEvent) {
		dispatch('remove', event.detail);
	}
</script>

<Employee {...employeeProps} on:remove={handleRemove} />
{#each jobs as job, index}
	<JobStatusField {...jobStatusFieldProps} {job} {index} {hasCategory}/>
{/each}
