import $ from 'jquery';
import { derived, readable } from 'svelte/store';
import { EventType } from '../../Event';

export type Status = {
	id: number;
	name: string;
	color: string;
};

let requested = false;

export const statuses = readable<{
	[EventType.Event]: Status[]; 
	[EventType.Project]: Status[];
	billed: Status;
	canceled: Status;
}>(
	{
		[EventType.Event]: [],
		[EventType.Project]: [],
		billed: { id: 0, name: 'Billed', color: 'blue' },
		canceled: { id: 0, name: 'Canceled', color: 'black' },
	},
	set => {
		if (!requested) {
			requested = true;
			$.post('/event-statuses', {
				silent: true,
				package: 'TerminHelper',
				ajax: 'getStatuses',
			}).then(set);
		}
	}
);

export const getStatuses = derived(statuses, statuses => (type: EventType) => (statuses[type] && statuses[type]) || []);

export const getStatus = derived(
	statuses,
	statuses => (type: EventType, status: number, billed: boolean = false, canceled: boolean = false) =>
		billed
			? statuses.billed
			: canceled
			? statuses.canceled
			: (statuses[type] && statuses[type].find(({ id }) => id === status)) || { id: -1, name: 'Unknown', color: '' }
);
