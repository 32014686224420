<script lang="ts">
	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
	import { faExclamationTriangle, faUsers } from '@fortawesome/free-solid-svg-icons';
	import { onMount, onDestroy } from 'svelte';
	import jQuery from 'jquery';
	import { Translations, EmployeeRequestType, DataValues } from '../types';
	import { createTinyeditor } from '../../../../legacy';
	import EmployeeRequest from '../../EmployeeRequest/EmployeeRequest.svelte';
	import FooterRecipient from '../components/FooterRecipient.svelte';
	import Headline from "../components/Headline.svelte";
	import AlertError from "../../../components/AlertError/AlertError.svelte";
	import CheckboxIcon from "../../../components/CheckboxIcon/CheckboxIcon.svelte";
	import { processRecipients } from "../utils/utils";
	import { createResourceChip } from '../utils/chipElementCreator';
	import { getEmployeesWithTenderReceived, getSubjobs } from '../utils/apis';
	import { selectedJobsStore, selectedCategoriesStore, reviewStore, resourcesSelectedStore,  isProjectStore } from '../stores/stores';
	import logError from "../../../../utils/log-error";
	import { svgMailTenderIcon } from "../utils/utils";

	export let translations: Translations;
	export let mainJobId: number;

    let isProject: boolean;
	let employeesWithTenderReceivedArray: number[];
	let employeeRequest: EmployeeRequestType;
	let tender = true;
	let textarea: HTMLTextAreaElement;
	let isTextAreaVisible: boolean = false;
	let observer : MutationObserver;
	let nextBtn : HTMLButtonElement;
	let error: boolean = false;
	let alreadyRequestedRecipientsCheckedValue = true;
	let overlapRecipientsCheckedValue = true;
	let restPeriodsRecipientsCheckedValue = true;
	let savedAlreadyRequestedElements: HTMLElement[] = [];
	let savedOverlapElements: HTMLElement[] = [];
	let savedRestPeriodsElements: HTMLElement[] = [];
	let recipientQuantity = 0;

	isProjectStore.subscribe(val => {
		isProject = val;
	});

	onMount(() => {
		employeeRequest.setFilters({
			default: ['employeerequest-employees'],
			extra: [ {
				type: 'CategoryFilter',
				props: {
					category: {
						selected: $selectedCategoriesStore[0].ID.toString(),
					},
				},
			}
			],
		});
	});

	onMount(() => {
		createTinyeditor(textarea);
	});

	onMount(() => {
		nextBtn = document.querySelector('.tender-bottom-right-btn');
    	nextBtn.addEventListener('click', goToReviewPage);
	});

	onDestroy(() => {
    	if (nextBtn) {
    	    nextBtn.removeEventListener('click', goToReviewPage);
    	}
	});

	onMount(() => {
    	observer = new MutationObserver(() => {
    	  const recipientParentDiv = document.querySelector('.dropped-children-tender-parent');
    	  if (recipientParentDiv) {
    	    resourcesSelectedStore.set(recipientParentDiv.children.length > 0);
    	  }
    	});

    	const recipientParentDiv = document.querySelector('.dropped-children-tender-parent');
    	if (recipientParentDiv) {
    	  observer.observe(recipientParentDiv, { childList: true });
    	}
  	});

	onMount(() => {
	  function updateRecipientQuantity() {
		recipientQuantity = document.querySelector('.dropped-children-tender-parent').children.length;
	  };
  
	  updateRecipientQuantity();
  
	  const observer = new MutationObserver(updateRecipientQuantity);
	  observer.observe(document.querySelector('.dropped-children-tender-parent'), { childList: true });
  
	  return () => observer.disconnect();
	});

  	onDestroy(() => {
    	if (observer) {
    		observer.disconnect();
    	}
		resourcesSelectedStore.set(false);
  	});

	function onDrop(event: DragEvent) {
		event.preventDefault();
		const data = JSON.parse(event.dataTransfer.getData('text/plain'));
		const name = data.name;
		const id = data.id;
		const icon = data.icon;
		const type = data.type;

		createResourceChip(name, id, employeesWithTenderReceivedArray, icon, type);
	};

	function onDragOver(event: DragEvent) {
		event.preventDefault();
	};

	const toggleTextArea: () => void = () => {
		isTextAreaVisible = !isTextAreaVisible;
	};

	async function fetchEmployeesWithTenderReceived(mainjobOrProjectId: number) {

        if ( !isProject ) {
			const result = await getTenderJobEmployeesEmployeeIdsByMainjobId(mainjobOrProjectId);
            employeesWithTenderReceivedArray = result.employeesWithTenderReceived;
        } else {
            employeesWithTenderReceivedArray = [];

            const mainJobsData = await getSubjobs(mainjobOrProjectId, true);
            let mainJobsDataValues: DataValues[] = Object.values(mainJobsData);
			
            mainJobsDataValues.map(async job => {
                if ( !$selectedJobsStore.map( (selectedJob) => selectedJob.jobId ).includes(job.ID) )
                    return;
					const result = await getTenderJobEmployeesEmployeeIdsByMainjobId(job.ID);
               		employeesWithTenderReceivedArray = [...employeesWithTenderReceivedArray, ...(result.employeesWithTenderReceived)];
            });
        }
        
        employeesWithTenderReceivedArray = [...new Set(employeesWithTenderReceivedArray)];

       	// go back from "review"-page to "recipient"-page and create chips for selected Recipients
		   if($reviewStore.recipients.length > 0) {
            $reviewStore.recipients.forEach(recipient => {
                createResourceChip(recipient.name,recipient.id,employeesWithTenderReceivedArray, recipient.overlapIcon, recipient.overlapType)
            });
        }
    }

    async function getTenderJobEmployeesEmployeeIdsByMainjobId(mainJobId : number) {
        let employeesWithTenderReceived: Set<number> = new Set();
		let allEmployeesWithTenderReceived: Set<number> = new Set();

        try {
            const data = await getEmployeesWithTenderReceived(mainJobId);
            for (let key in data) {
                if (key === 'Subjobs') {
                    for (let subjobKey in data[key]) {
                        for (let subjob of data[key][subjobKey]) {
							allEmployeesWithTenderReceived.add(subjob.EmployeeID);
                            for (let selectedJob of $selectedJobsStore) {
                                if (subjob.JobID === selectedJob.jobId) {
                                    employeesWithTenderReceived.add(subjob.EmployeeID);
                                }
                            }
                        }
                    }
                } else {
                    let job = data[key];
                    for (let selectedJob of $selectedJobsStore) {
                        if (job.JobID === selectedJob.jobId) {
                            employeesWithTenderReceived.add(job.EmployeeID);
                        }
                    }
                }
            }
            return {
            employeesWithTenderReceived: Array.from(employeesWithTenderReceived),
            allEmployeesWithTenderReceived: Array.from(allEmployeesWithTenderReceived)
        	};

        } catch (err) {
            logError(`Error getting Employees With Tender Received, mainJobId: ${mainJobId}`, err.stack)
            error = true;
            throw err;
        }
        
    }

	fetchEmployeesWithTenderReceived(mainJobId);

	function getTenderText() {
		if(jQuery(textarea).data('tinyeditor')) {
			jQuery(textarea).data('tinyeditor').post();
			let inhaltNeu = textarea.value;
			return inhaltNeu;
		}
	}

  	function goToReviewPage() {
    	reviewStore.update(storeValue => {
      		storeValue.TenderText = getTenderText();
      		return storeValue;
    	});
  	}

	function removeDisabledClass(e : Event) {
    	const targetElement = e.currentTarget as Element;
    	const id = targetElement.getAttribute('data-ressourceid');
    	let contentElement = document.querySelector('#tenderemail > div > div > div.recipient-main-div > div.event__employee-request.recipient-divider-div.recipient-right-side > div > div.content');
    	let matchingChild = contentElement.querySelector(`[data-ressourceid="${id}"]`);
		if (matchingChild && matchingChild.classList.contains('disabled-tender')) {
        	matchingChild.classList.remove('disabled-tender');
   		}
	}


	onMount(() => {
    const parentElement = document.querySelector('.dropped-children-tender-parent');

    const observer = new MutationObserver((mutationsList, observer) => {
        for(let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                const children = Array.from(parentElement.children);

                children.forEach(child => {
                    child.addEventListener('click', removeDisabledClass);
                });
            }
        }
    });

    observer.observe(parentElement, { childList: true });
	});

	onDestroy(processRecipients)

	// toggleAlreadyRequestedCheckbox & toggleOverlapCheckbox && toggleRestPeriodsCheckbox need to be in this file and in own files, otherwise the specific array doesnt get updated globally
	function toggleAlreadyRequestedCheckbox() {
    	const parentDiv = document.querySelector('.dropped-children-tender-parent');
    	if (!alreadyRequestedRecipientsCheckedValue) {
    	    savedAlreadyRequestedElements.forEach(element => {
    	        parentDiv.appendChild(element);
    	    });
    	    savedAlreadyRequestedElements = [];
    	    savedAlreadyRequestedElements.length = 0;
    	} else {
    	    const elementsToRemove = parentDiv.querySelectorAll('.tender-already-requested');

    	    elementsToRemove.forEach(element => {
    	        savedAlreadyRequestedElements.push(element as HTMLElement);
    	        parentDiv.removeChild(element);
    	    });
    	}
	};

	function toggleOverlapCheckbox() {
		const parentDiv = document.querySelector('.dropped-children-tender-parent');
    	if (!overlapRecipientsCheckedValue) {
    	    savedOverlapElements.forEach(element => {
    	        parentDiv.appendChild(element);
    	    });
    	    savedOverlapElements = [];
    	    savedOverlapElements.length = 0;
    	} else {
    	    const elementsToRemove = parentDiv.querySelectorAll('.tender-overlap');

    	    elementsToRemove.forEach(element => {
    	        savedOverlapElements.push(element as HTMLElement);
    	        parentDiv.removeChild(element);
    	    });
    	}
	};

	function toggleRestPeriodsCheckbox() {
		const parentDiv = document.querySelector('.dropped-children-tender-parent');

		if (!restPeriodsRecipientsCheckedValue) {
			savedRestPeriodsElements.forEach(element => {
	            parentDiv.appendChild(element);
	        });
	        savedRestPeriodsElements = [];
	        savedRestPeriodsElements.length = 0;
	    } else {
	        const elementsToRemove = parentDiv.querySelectorAll('.rest-periods');

	        elementsToRemove.forEach(element => {
	            savedRestPeriodsElements.push(element as HTMLElement);
	            parentDiv.removeChild(element);
	        });
	    }
	}
</script>


{#if error}
	<AlertError message={translations.notFoundResource} type="error"/>
{:else}

<div class="recipient-main-div">
	<div class="recipient-divider-div recipient-left-side border-right-line">
		<Headline text={translations.recipient}{translations.list.toLowerCase()}/>
		<div class="recipient-buttons-parent"> 
			<div>
				<CheckboxIcon icon={svgMailTenderIcon} className="mail-tender-icon-header" fontAwesomeIconAvailable={false} hoverTitle={translations.alreadyRequestedSingular} bind:checked={alreadyRequestedRecipientsCheckedValue} on:change={toggleAlreadyRequestedCheckbox}/>
				<CheckboxIcon icon={faExclamationTriangle} className="color-icon-red" hoverTitle={translations.overlap} bind:checked={overlapRecipientsCheckedValue} on:change={toggleOverlapCheckbox}/>
				<CheckboxIcon icon={faExclamationTriangle} className="color-icon-yellow" hoverTitle={translations.restTimes} bind:checked={restPeriodsRecipientsCheckedValue} on:change={toggleRestPeriodsCheckbox}/>
			</div>

			<div class="selected-recipients-quantity-div">
				<FontAwesomeIcon icon={faUsers}/>
				<span>{recipientQuantity}</span>
			</div>
		</div>

		<div
			class="drop-div-tender {isTextAreaVisible ? 'height-20' : 'height-100'}"
			role="presentation"
			on:dragover={event => onDragOver(event)}
			on:drop={event => onDrop(event)}
		>
			<div class="dropped-children-tender-parent"></div>
		</div>
		<div class="tinyeditor-tender {isTextAreaVisible ? 'visible' : ''}">
			<textarea
				bind:this={textarea}
				class="tinyeditor"
				name="emailtext"
				id="emailtext"
				placeholder="Nachricht schreiben..."
				style="width: 100%; display: none;"
			></textarea>
		</div>
	</div>
	<div class="event__employee-request recipient-divider-div recipient-right-side"
		><EmployeeRequest bind:this={employeeRequest} {tender} {employeesWithTenderReceivedArray} />
	</div>
</div>

<FooterRecipient {toggleTextArea} {employeesWithTenderReceivedArray} {translations} />
{/if}