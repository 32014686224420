const createSubscribable = <T extends (...args: any[]) => any>() => {
	const subscriptions: T[] = [];

	const subscribe = (callback: T) => {
		if (!subscriptions.includes(callback)) {
			subscriptions.push(callback);
		}

		return () => {
			const index = subscriptions.indexOf(callback);
			if (index !== 1) {
				subscriptions.splice(index, 1);
			}
		};
	};

	subscribe.notify = (...args: any[]) => {
		for (const subscriber of subscriptions) {
			subscriber.apply(undefined, args);
		}
	};

	return subscribe;
};

export const onInit = createSubscribable<(doc: JQuery<Document>, onLogin?: true) => any>();

export const onLogin = createSubscribable<() => any>();

export const onNavigate = createSubscribable<
	(params: { url: string; ajax?: string; mode?: string; pack?: string; target?: string }) => any
>();

export const onAfterNavigate = createSubscribable<(params: { target?: string }) => any>();
