<script lang="ts">
	import Event from './Event.svelte';
	import type { Events, LoadSubEvents, UpdateEvent } from './types';


export let day: string;
//export let event: Event;

export let events: Events;

export let groupByDay: boolean;
export let expandAll: boolean;
export let expandedJobIds: number[];

export let canModify: boolean = false;
export let canDelete: boolean = false;
export let showNumbers: boolean = false;
export let translations: Record<string, string>;

export let update: UpdateEvent;
export let loadPastSub: LoadSubEvents;
export let loadMoreSub: LoadSubEvents;

let show = (day !== undefined && day !== 'undefined');

</script>

{#if show }
	<li class="headline">{day}</li>
	
	{#each events as { day, ...event }, index (event.id)}
	    <Event
	        {...event}
	        {groupByDay}
	        {expandAll}
	        {expandedJobIds}
	        identifier={event.id}
	        {canModify}
	        {canDelete}
	        {showNumbers}
	        {translations}
	        {update}
	        {loadPastSub}
	        {loadMoreSub}
	    />
	
	{/each}
{/if}