import $ from 'jquery';
import createSpinner from '../components/spinner';
import { onAfterNavigate } from '../utils/subscriptions';
import { sendForm } from './index';

$(() => {
	create($(document.body));
});

onAfterNavigate(({ target }) => {
	if (target && (target = target instanceof $ ? target : $('#' + target)).length) {
		setTimeout(() => create(target), 500);
	}
});

const create = target => {
	if (target.attr('data-infinitescroll') != undefined) {
		target = target.parent();	
	}
	
	if (!target.find('[data-infinitescroll][data-sendform]:visible').length) {
		return;
	}

	const check = () => {
		if (!document.body.contains(target[0])) {
			return;
		}

		const elements = target.find('[data-infinitescroll][data-sendform]:visible');
		if (!elements.length) {
			return;
		}

		setTimeout(check, 120);

		elements.each(function () {
			const $this = $(this);
			let height;
			let top;
			let parentId;

			if (this.getAttribute('data-usepagescroll') === 'true') {
				const $document = $(document);
				height = $document.height();
				top = $document.scrollTop() + $(window).height();
			} else if ((parentId = this.getAttribute('data-useparentscroll'))) {
				const $parent = $('#' + parentId);
				if (!$parent[0]) {
					return;
				}

				height = $parent[0].scrollHeight;
				top = $parent.scrollTop() + $parent.height();
			} else {
				height = this.scrollHeight;
				top = $this.scrollTop() + $this.height();
			}

			if (height - top < 100 && $(`input[name="${this.getAttribute('data-infinitescroll')}"]`).length > 0) {
				const form = this.getAttribute('data-sendform');
				if (form) {
					const isTbody = this.tagName.toUpperCase() === 'TBODY';
					let spinnerTr;
					if (isTbody) {
						spinnerTr = $('<tr class="hinweis"><td colspan="1337">' + createSpinner() + '</td></tr>');
						$this.append(spinnerTr);
					} else {
						$this.after(createSpinner());
					}

					var $form = $(`form[name="${form}"]`);
					if ($form == undefined || $form.length == 0) {
						$form = $(`[data-form="${form}"]`);
					}

					sendForm($form, $this, () => {
						if (isTbody) {
							spinnerTr.remove();
						} else {
							$this.next().remove();
						}
					});
				}
			}
		});
	};

	setTimeout(check, 120);
};
