<script lang="ts">
	import { createEventDispatcher, tick } from 'svelte';
	import { cubicInOut } from 'svelte/easing';
	import { slide } from 'svelte/transition';
	import translate from '../../../i18n';
	import Button from '../../components/Button';
	import Select from '../../components/Select';
	import TextField from '../../components/TextField';
	import { EmployeeType } from '../types';

	export let translations: Record<string, string> & { types: Record<string, string> };
	export let showPhoneNumber: boolean;
	export let getFilters: () => { default: { checked: boolean; name: string }[] };

	const types = [
		{
			label: translations.types[EmployeeType.Employee],
			value: EmployeeType.Employee,
		},
		{
			label: translations.types[EmployeeType.Freelancer],
			value: EmployeeType.Freelancer,
		},
		{
			label: translations.types[EmployeeType.Vehicle],
			value: EmployeeType.Vehicle,
		},
	];

	let type = (() => {
		let type = EmployeeType.Employee;

		const filters = getFilters()
			.default.filter(({ checked }) => checked)
			.map(({ name }) => name);

		if (!filters.includes('employeerequest-employees')) {
			if (filters.includes('employeerequest-freelancer')) {
				type = EmployeeType.Freelancer;
			} else if (filters.includes('employeerequest-vehicles')) {
				type = EmployeeType.Vehicle;
			} else if (filters.includes('employeerequest-rooms')) {
				type = EmployeeType.Room;
			}
		}

		return type;
	})();

	let name = '';
	let employeePhone = '';
	let vehicleSeats = '';
	let vehiclePayload = '';
	let roomNumberPeople = '';

	let nameInput: TextField;

	const dispatch = createEventDispatcher();

	const cancel = () => dispatch('cancel');
	const create = async () => {
		const trimmedName = name.trim();
		if (!trimmedName) {
			name = trimmedName;
			await tick;
			nameInput.reportValidity();
			return;
		}

		dispatch(
			'create',
			type === EmployeeType.Vehicle
				? { image: '/dokument/profilbild/10/0.jpg', requestable: true, showPhoneNumber, type, name, employeePhone, vehicleSeats, vehiclePayload }
				: (type === EmployeeType.Room 
					? { image: '/dokument/profilbild/P/0.jpg', requestable: true, showPhoneNumber, type, name, employeePhone, vehicleSeats, vehiclePayload, roomNumberPeople }
					: { image: '/dokument/profilbild/P/0.jpg', requestable: true, showPhoneNumber, type, name, employeePhone })
		);
	};

	const validateVehicleSeats = (s: string) => {
		if (s !== '' && isNaN(Number(s))) {
			vehicleSeats = '';
		}
	};

	const validateVehiclePayload = (p: string) => {
		if (p !== '' && isNaN(Number(p.replace(',', '.')))) {
			vehiclePayload = '';
		}
	};
	const validateRoomNumberPeople = (p: string) => {
		if (p !== '' && isNaN(Number(p.replace(',', '.')))) {
			roomNumberPeople = '';
		}
	};

	$: validateVehicleSeats(vehicleSeats);
	$: validateVehiclePayload(vehiclePayload);
	$: validateRoomNumberPeople(roomNumberPeople);
</script>

<div class="create-free-result" transition:slide={{ duration: 200, easing: cubicInOut }}>
	<div>
		<Select options={types} bind:value={type} on:change={nameInput && nameInput.focus} />

		<TextField
			bind:this={nameInput}
			placeholder={translations.name}
			autofocus
			required
			bind:value={name}
			preventSubmit={create}
		/>
		{#if [EmployeeType.Employee, EmployeeType.ServiceProvider].includes(type) && showPhoneNumber }
			<TextField placeholder={translations.employeePhone} bind:value={employeePhone} preventSubmit={create} />
		{/if}
	</div>

	{#if type === EmployeeType.Vehicle}
		<div transition:slide={{ duration: 200, easing: cubicInOut }}>
			<TextField placeholder={translations.vehicleSeats} bind:value={vehicleSeats} preventSubmit={create} />
			<TextField placeholder={translations.vehiclePayload} bind:value={vehiclePayload} preventSubmit={create} />
		</div>
	{/if}

	<div class="actions">
		<Button on:click={cancel}>{translate('cancel')}</Button>
		<Button primary on:click={create}>{translations.create}</Button>
	</div>
</div>
