<script lang="ts">
	import { onMount } from 'svelte';
	import getScrollParent from '../../../utils/get-scroll-parent';

	export let load: () => any;
	export let root: Element | undefined = undefined;
	export let margin: string | undefined = undefined;
	export let threshold: number | number[] | undefined = undefined;

	let container: HTMLDivElement;

	onMount(() => {
		const observer = new IntersectionObserver(
			entries => {
				for (const entry of entries) {
					if (entry.isIntersecting) {
						load();
						break;
					}
				}
			},
			{
				root: root || getScrollParent(container),
				rootMargin: margin,
				threshold,
			}
		);

		observer.observe(container);

		return () => {
			observer.disconnect();
		};
	});
</script>

<div bind:this={container} />
