<script lang="ts">
	import cx from '../../../utils/class-names';

	let className: string = undefined;
	export { className as class };
	export let value: string = undefined;
	export let autofocus: boolean = false;
	export let preventSubmit: boolean | (() => any) = false;

	let input: HTMLInputElement;

	export const focus = () => input && input.focus();
	export const reportValidity = () => input && input.reportValidity();

	$: autofocus && input && input.focus();
	$: onKeyPress = preventSubmit
		? (event: KeyboardEvent) => {
				if (event.key === 'Enter') {
					event.preventDefault();
					if (typeof preventSubmit === 'function') {
						preventSubmit();
					}
				}
		  }
		: undefined;
</script>

<input
	bind:this={input}
	type="text"
	class={cx('textfield', className)}
	bind:value
	{...$$restProps}
	on:keypress={onKeyPress}
/>
