<script lang="ts">
	import filesize from 'filesize';
	import Times from 'icon:times';
	import { cubicInOut } from 'svelte/easing';
	import { slide } from 'svelte/transition';
	import translate from '../../../i18n';
	
	import IconButton from "../IconButton";
	import Item from './Item.svelte';
	import type { FileUploadOptions } from './types';

	export let accept: string[] | undefined = undefined;
	export let multiple = false;
	export let options: FileUploadOptions;

	type Error = string | undefined;

	let files: File[] = [];

	let successFiles: File[] = [];
	let successFilesSize = 0;
	let failedFiles: [File, Error][] = [];

	$: update(files);

	const update = (files: File[]) => {
		let count = 0;
		let size = 0;
		const success: File[] = [];
		const failed: [File, Error][] = [];

		for (const file of files) {
			const error = checkFile(file, { count, size });
			if (error) {
				failed.push([file, error]);
			} else {
				count++;
				size += file.size;
				success.push(file);
			}
		}

		successFiles = success;
		successFilesSize = size;
		failedFiles = failed;
	};

	const checkFile = (file: File, { count, size }: { count: number; size: number }): Error => {
		if (!file.type
				&& file.name.substring(file.name.length - 4) != '.msg'
				&& file.name.substring(file.name.length - 4) != '.csc'
				&& file.name.substring(file.name.length - 4) != '.bpt'
				&& file.name.substring(file.name.length - 5) != '.dbpr' ) {
			return translate('FileUpload_UnknownFiletype');
		}

		if (count && (!multiple || options.maxFiles === 1)) {
			return translate('FileUpload_OnlyOneFileAllowed');
		}

		if (options.maxFiles !== undefined && options.maxFiles <= count) {
			return translate('FileUpload_OnlyXFilesAllowed', options.maxFiles);
		}

		if (options.maxFileSize && file.size > options.maxFileSize) {
			return translate('FileUpload_MaxFileSizeExceeded', filesize(options.maxFileSize));
		}

		if (options.availableSpace !== undefined && size + file.size > options.availableSpace) {
			return translate('FileUpload_AvailableSpaceExceeded', filesize(options.availableSpace));
		}

		if (options.maxSize && size + file.size > options.maxSize) {
			return translate('FileUpload_MaxUploadSizeExceeded', filesize(options.maxSize));
		}

		// all types allowed
		if ( !accept ) {
			return;
		}

		const fileName = file.name.toLowerCase();
		const fileType = file.type.toLowerCase();
		
		for (const type of accept) {

			if ( !type ) {
				continue;
			}
			if ( type.startsWith('.') && fileName.endsWith(type.toLowerCase()) ) {
				return;
			}
			const slashIndex = type.indexOf('/');
			if (slashIndex > 0) {
				if (type.length === slashIndex + 2 && type[slashIndex + 1] === '*') {
					if (fileType.indexOf(type.substr(0, slashIndex).toLowerCase()) === 0) {
						return;
					}
				} else if ( fileType === type.toLowerCase() ) {
					return;
				}
			}
		}
	
		return translate('FileUpload_FiletypeNotAllowed');
		
	};

	export const addFiles = (addedFiles: FileList) => {
		files = [...files, ...addedFiles];
	};

	export const getFiles = () => successFiles;

	const clearFiles = () => {
		files = [];
	};

	const deleteFile = (file: File) => {
		files = files.filter(f => f !== file);
	};
</script>

{#if files.length}
	<ul class="file-upload__list">
		<li class="file-upload__list__title" transition:slide|global={{ duration: 200, easing: cubicInOut }}>
			{successFiles.length === 1 ? translate('FileUpload_OneFileSelected') : translate('FileUpload_XFilesSelected', successFiles.length)}
			<small>{filesize(successFilesSize)}</small>
			<IconButton small icon={Times} title={translate('deleteAll')} on:click={clearFiles} />
		</li>

		{#each successFiles as file (file)}
			<Item {file} on:delete={() => deleteFile(file)} />
		{/each}

		{#each failedFiles as [file, error] (file)}
			<Item {file} {error} on:delete={() => deleteFile(file)} />
		{/each}
	</ul>
{/if}
