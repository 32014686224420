<script>
	import { createEventDispatcher } from 'svelte';
	import { cubicInOut } from 'svelte/easing';
	import { slide } from 'svelte/transition';
	import SelectOptGroup from '../../../components/SelectOptGroup';
	import Select from '../../../components/Select';
	import SkillSelector from '../../../components/SkillSelector';
	import DeleteButton from '../DeleteButton.svelte';

	export let filter;
	export let name;
	export let category;
	export let skill;

	export const getData = () => ({
		...filter,
		values:
			category.selected == 0
				? []
				: [
						[category.name, category.selected],
						[skill.name, skill.value],
				  ],
	});

	const dispatch = createEventDispatcher();

	const update = () => dispatch('update');
	const remove = () => dispatch('remove');
</script>

<div class="filter filter--category" transition:slide={{ duration: 200, easing: cubicInOut }}>
	<strong>{name}:</strong>
	{#if category.showOptgroupName }
	<SelectOptGroup bind:value={category.selected} 
		options={category.options} 
		selectClass={category.selectClass} 
		showOptgroupName={category.showOptgroupName}
		autoSubmit={category.autoSubmit}
		on:change={update} />
		
	{:else}
		<Select bind:value={category.selected} options={category.options} on:change={update} />
	{/if}

	<SkillSelector bind:level={skill.value} on:change={update} />

	<DeleteButton on:click={remove} />
</div>
