import { sendForm } from '../../legacy';
import { EmployeeRequest } from '../../svelte';
import ElementRouter, { View } from '../../utils/element-router';
import listen from '../../utils/listen';
import EmployeesView from './employees';
import StaffPlanView from './staff-plan';

const EventView: View = ({ container }: { container: HTMLDivElement }) => {
	let employeeRequest: EmployeeRequest;

	const destroyers = [
		ElementRouter({
			root: container,
			routes: [
				{ selector: '.event-details.event__employees', view: EmployeesView },
				{ selector: '.event-details.staff-plan', view: StaffPlanView },
			],
		}),

		() => {
			if (employeeRequest) {
				employeeRequest.$destroy();
			}
		},
	];

	const employeeRequestToggle = container.querySelector('.button[data-event-employee-request]') as HTMLButtonElement;
	if (employeeRequestToggle) {
		const renderEmployeeRequest = () =>
			new Promise(resolve => {
				sendForm(container.querySelector('form[name="termin"]'), employeeRequestToggle, () => {
					employeeRequestToggle.classList.add('button--pink');
					container.classList.add('event__employee-request--opened');
					const target = container.querySelector('.event__employee-request');
					try {
						employeeRequest = new EmployeeRequest({ target });
					} catch (error) {
						// Might error, if job dialog was closed again
					}
					resolve();
				});
			});

		const destroyEmployeeRequest = () => {
			employeeRequest.$destroy();
			employeeRequest = undefined;
			container.classList.remove('event__employee-request--opened');
			employeeRequestToggle.classList.remove('button--pink');
		};

		destroyers.push(
			listen(employeeRequestToggle, 'click', () => {
				if (employeeRequest) {
					destroyEmployeeRequest();
				} else {
					renderEmployeeRequest();
				}
			}),
			listen(container, 'click', async event => {
				const button = (event.target as Element).closest(
					'.button[data-event-employee-request-category]'
				) as HTMLButtonElement;
				if (!button) {
					return;
				}

				if (!employeeRequest) {
					await renderEmployeeRequest();
				}
				
				employeeRequest.setSearch('');

				employeeRequest.setFilters({
					default: button.getAttribute('data-event-employee-request-dfilter').split(';'),
					extra: [
						{
							type: 'CategoryFilter',
							props: {
								category: {
									selected: button.getAttribute('data-event-employee-request-category'),
								},
								skill: {
									value: button.getAttribute('data-skilllevel'),
								},
							},
						},
					],
				});
			})
		);
	}

	return () => {
		for (const destroyer of destroyers) {
			destroyer();
		}
	};
};

export default EventView;
