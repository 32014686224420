<script lang="ts">
	import cx from '../../../utils/class-names';
	import Button from '../Button';

	let className: string = undefined;
	export { className as class };
	export let icon: typeof import('icon:*').default;
	export let small: boolean = false;
</script>

<svelte:options immutable />

<Button {icon} class={cx('icon-button', small && 'icon-button--small', className)} {...$$restProps} on:click>
	<slot />
</Button>
