import { derived, writable } from 'svelte/store';
import { EmployeeType } from '../types';

type Employee = { id: number; type: EmployeeType; name: string; requestable: boolean };
type RequestableModifier = (employee: Employee) => boolean;

const requestableModifiers: RequestableModifier[] = [];
const requestableModifiersChanged = writable<{}>({});

export const addRequestableModifier = (modifier: RequestableModifier) => {
	if (!requestableModifiers.includes(modifier)) {
		requestableModifiers.push(modifier);
		requestableModifiersChanged.set({});
	}

	return {
		updated() {
			requestableModifiersChanged.set({});
		},

		delete() {
			const index = requestableModifiers.indexOf(modifier);
			if (index !== -1) {
				requestableModifiers.splice(index, 1);
				requestableModifiersChanged.set({});
			}
		},
	};
};

export const isRequestable = derived(requestableModifiersChanged, _ => (employee: Employee) => {
	for (const modifier of requestableModifiers) {
		const result = modifier(employee);
		if (result === false) {
			return result;
		}
	}

	return employee.requestable;
});
