import { handleResponseError } from "./utils";

async function fetchAPI(endpoint: string, method: string, body?: any) {
	const url =  `${window.location.origin}${endpoint}`;
	const response = await fetch(`${window.location.origin}${endpoint}`, {
		method,
		headers: {
			'Content-Type': 'application/json',
		},
		body: body ? JSON.stringify(body) : null,
	});
	handleResponseError(response, url);

	return await response.json();
}

export async function getMainJobData(mainJobId: number) {
	return fetchAPI( `/api/job/${mainJobId}`, 'GET')
}

export async function getMainJobOrProject(mainJobId: number) {
	return fetchAPI(`/api/projektoderjob/${mainJobId}`,'GET');
}

export function getSubjobs(mainJobId: number, isProject: boolean = false) {
	return fetchAPI(`/api/${isProject ? 'projekt' : 'job'}/suche`, 'POST', { ParentID: mainJobId });
}

export function getAllJobs(mainJobId: number, isProject: boolean = false) {
	return fetchAPI(`/api/${isProject ? 'projekt' : 'job'}/${mainJobId}/structure`,'GET');
}
export function getCategories(mainJobId: number, jobIds: number[], isProject: boolean = false) {
	return fetchAPI(`/api/${isProject ? 'projekt' : 'job'}/${mainJobId}/tendercategories`, 'POST', { JobIDs: jobIds });
}

export function getEmployeesWithTenderReceived(mainJobId: number) {
	return fetchAPI(`/api/job/${mainJobId}/tenderjobemployees`, 'GET');
}

export async function sendEmail(
	mainjobOrProjectId: number,
	TenderText: string,
	AttachInformation: boolean,
	EmployeeIDs: number[],
	JobIDs: number[],
	CategoryIDs: number[],
    isProject: boolean,
): Promise<any> {
	const jsonResponse = await fetchAPI(`/api/${isProject ? 'projekt' : 'job'}/${mainjobOrProjectId}/sendtender`, 'POST', {
		TenderText,
		AttachInformation,
		EmployeeIDs,
		JobIDs,
		CategoryIDs,
	});

	return jsonResponse;
}


