// import $ from 'jquery';
import listen from '../utils/listen';
import { onInit } from '../utils/subscriptions';
import supportsPassiveEventListener from '../utils/supports-passive-event-listener';

interface HelpBubble extends HTMLElement {
	above?: boolean;
	anchor?: Element;
}

const ARROW_HEIGHT = 8;
const CLASS = 'help';
const CLASS_ABOVE = 'help--above';
const CLASS_ACTIVE = 'help--active';
const HELP_FOR_ATTRIBUTE = 'data-helpfor';

let openedHelpBubbles: HTMLElement[] = [];
let listeners: undefined | (() => void)[];

onInit(doc => {
	doc.on('click', `a.${CLASS}`, function (this: HTMLElement, event) {
		event.preventDefault();

		const helpBubble = document.querySelector(`div.${CLASS}[${HELP_FOR_ATTRIBUTE}="${this.getAttribute('id')}"]`);
		if (helpBubble) {
			showHelpBubble(helpBubble as HelpBubble, this);
		} else {
			for (const helpBubble of document.querySelectorAll(`div.${CLASS}`)) {
				showHelpBubble(helpBubble as HelpBubble);
			}
		}

		const unlisten = listen(
			document,
			'click',
			event => {
				for (const helpBubble of openedHelpBubbles) {
					if (helpBubble.contains(event.target as Node)) {
						return;
					}
				}

				unlisten();
				event.preventDefault();

				if (this.contains(event.target as Node)) {
					event.stopPropagation();
				}

				if (listeners) {
					for (const unlisten of listeners) {
						unlisten();
					}

					listeners = undefined;
				}

				for (const helpBubble of openedHelpBubbles) {
					helpBubble.classList.remove(CLASS_ACTIVE);
				}

				openedHelpBubbles = [];
			},
			true
		);
	});
});

const getAnchor = (helpBubble: HelpBubble): Element => {
	if (helpBubble.anchor) {
		return helpBubble.anchor;
	}

	const anchorId = helpBubble.getAttribute(HELP_FOR_ATTRIBUTE);
	if (!anchorId) {
		throw new Error(`\`${HELP_FOR_ATTRIBUTE}\` not set`);
	}

	const anchor = document.querySelector(`#${anchorId}`);
	if (!anchor) {
		throw new Error('Anchor #' + anchorId + ' not found');
	} 

	helpBubble.anchor = anchor;
	return anchor;
};

const position = (helpBubble: HelpBubble, anchor: Element = getAnchor(helpBubble)): void => {
	const { top, height, left, width } = anchor.getBoundingClientRect();
	const { height: bubbleHeight } = helpBubble.getBoundingClientRect();
	const { innerHeight } = window;
	const { above, style, classList } = helpBubble;

	let y;
	let x;

	if (classList.contains('on-top')) {
		if (height > innerHeight) {
			const maxTop = Math.max(top, 0);
			y = `calc(${maxTop + (innerHeight - maxTop) / 2}px - 50%)`;
		} else {
			y = `calc(${top + height / 2}px - 50%)`;
		}

		x = `calc(${left + width / 2}px - 50%)`;
	} else {
		y = top + height + ARROW_HEIGHT;
		if (y + bubbleHeight > innerHeight) {
			y = top - bubbleHeight - ARROW_HEIGHT + 'px';
			if (!above) {
				helpBubble.above = true;
				classList.add(CLASS_ABOVE);
			}
		} else {
			y = y + 'px';
			if (above) {
				helpBubble.above = false;
				classList.remove(CLASS_ABOVE);
			}
		}

		if (classList.contains('left')) {
			x = left + 'px';
		} else if (classList.contains('right')) {
			x = `calc(${left + width}px - 100%)`;
		} else {
			x = `calc(${left + width / 2}px - 50%)`;
		}
	}

	style.transform = `translate(${x}, ${y})`;
};

const addListeners = (): void => {
	const options = supportsPassiveEventListener ? { passive: true, capture: true } : true;
	const reposition = () => {
		for (const helpBubble of openedHelpBubbles) {
			position(helpBubble);
		}
	};

	listeners = [listen(window, 'scroll', reposition, options), listen(window, 'resize', reposition, options)];
};

const showHelpBubble = (helpBubble: HelpBubble, anchor?: Element): void => {
	if (anchor) {
		helpBubble.anchor = anchor;
	}

	position(helpBubble, anchor);

	if (openedHelpBubbles.push(helpBubble) === 1) {
		addListeners();
	}

	const { /*style,*/ classList } = helpBubble;

	// style.zIndex = $.topZIndex() as unknown as string;
	classList.add(CLASS_ACTIVE);
};
