import listen from '../../utils/listen';

const selectOnFocus = (node: HTMLInputElement) => {
	if (!node.select) {
		return;
	}

	if (document.activeElement === node) {
		node.select();
	}

	const unlisten = listen(node, 'focus', () => {
		node.select();
	});

	return {
		destroy() {
			unlisten();
		},
	};
};

export default selectOnFocus;
