import { selectedCellsStore, displaySelectStatus } from "../stores/stores";

export let isMouseDown = false;
export let box : HTMLDivElement;
export let startX : number, startY : number;



export function getElementsInBox(box : HTMLDivElement) {
  const boxRect = box.getBoundingClientRect();
  const elements = document.querySelectorAll('.staffplan-cell'); 
  let elementsInBox = [];

  for (const element of elements) {
    const elementRect = element.getBoundingClientRect();
      if (
      (elementRect.left <= boxRect.right && elementRect.right >= boxRect.left) &&
      (elementRect.top <= boxRect.bottom && elementRect.bottom >= boxRect.top)
  ) {
      elementsInBox.push(element);
  }
  

  }
  elementsInBox = elementsInBox.filter(element => !element.classList.contains('na'));
  return elementsInBox;
}

  
  export function handleMouseDown(e: MouseEvent) {
    if (e.button !== 0) return;

    if (!(e.target as Element).closest('.event__employee-request')  && !(e.target as Element).closest('.name-and-icons')) {
    isMouseDown = true;
    startX = e.clientX;
    startY = e.clientY;

    box = document.createElement('div');
    box.style.position = 'fixed';
    box.style.border = '1px solid black';
    box.style.background = 'rgba(0, 0, 0, 0.5)';
    box.style.pointerEvents = 'none';
    box.style.zIndex = "5000";
    document.body.style.cursor = 'move';
    document.body.appendChild(box);
    box.style.left = `${startX}px`;
    box.style.top = `${startY}px`;
  }
  }
  
  let selectedElements: Element[] = [];

  export function handleMouseMove(e: MouseEvent) {
    if (e.button !== 0) return;

    if (!isMouseDown) return;
    box.style.width = `${Math.abs(e.clientX - startX)}px`;
    box.style.height = `${Math.abs(e.clientY - startY)}px`;
    box.style.left = `${Math.min(e.clientX, startX)}px`;
    box.style.top = `${Math.min(e.clientY, startY)}px`;
  
  // Add the selected-cell class to the elements in the field when dragging
    const elementsInBox = getElementsInBox(box);
    elementsInBox.forEach(element => {
      if (!element.classList.contains("selected-cell")) {
        element.classList.add("selected-cell");
        selectedElements.push(element);
      }
    });
  
  // Remove the "selected-cell" class from the elements that were pulled out of the box
    selectedElements = selectedElements.filter(element => {
      if (!elementsInBox.includes(element)) {
        element.classList.remove("selected-cell");
        return false;
      }
      return true;
    });
  }
  
  export function handleMouseUp(e: MouseEvent) {
    if (e.button !== 0) return;

    isMouseDown = false;
    document.body.style.cursor = 'default';
 
    if(box) {
      if (box.offsetWidth > 3 && box.offsetHeight > 3) {
        const elementsInBox = getElementsInBox(box);
      
        if(elementsInBox.length > 0) {
          elementsInBox.forEach(element => element.classList.add("selected-cell"))
          selectedCellsStore.set(elementsInBox)
        }
        
        if(elementsInBox.length > 0) {
          displaySelectStatus.set(true);
        }
      }

      box.remove();
    }
  }

  export function handleRightMouseDown(e: MouseEvent) {
    // Check whether the right mouse button was pressed
    if (e.button !== 2) return;
  
    // Remove the box if it exists
    if(box) {
      box.remove();
    }
  }
 


  export function addEventListenersForSelection() {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousedown', handleRightMouseDown); 
  }
  
  export function removeEventListenersForSelection() {
    document.removeEventListener('mousedown', handleMouseDown);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousedown', handleRightMouseDown); 
  }