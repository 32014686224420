import { writable } from 'svelte/store';
import { ReviewStore, CheckedSubjob, Views } from '../types';

export function resetStores(view: any) {
	if(!view || view === Views.VIEW_SUBJOBS) {
		checkedSubjobsStore.set([]);
		selectedJobsStore.set([]);
		showSubjobs.set(new Map());

	}
	if(!view || view === Views.VIEW_CATEGORIES) {
		checkedCategoriesStore.set([]);
		selectedCategoriesStore.set([]);
	}
	if(!view || view === Views.VIEW_RECIPIENT) {
		attachInformationStore.set(true);
		resourcesSelectedStore.set(false);
		loadAllStore.set(false);
		reviewStore.set({
			TenderText: '',
			recipients: [],
		});
	}
	if(!view || view === Views.VIEW_REVIEW) {
		displayAlertInfo.set(false);	
	
	}
	if (!view) {
		nextViewButtonEnabledStore.set(true);
	}
};




export const displayAlertInfo = writable(false);

export const isSelectAllBtnDisabledStore = writable(true);

export const showSubjobs = writable(new Map());

export const loadAllStore = writable(false);

export const checkedSubjobsStore = writable<CheckedSubjob[]>([]);

export const checkedCategoriesStore = writable<CheckedSubjob[]>([]);

export const selectedJobsStore = writable([]);

export const selectedCategoriesStore = writable([]);

export const attachInformationStore = writable(true)

export const reviewStore = writable<ReviewStore>({
	TenderText: '',
	recipients: [],
});

export const nextViewButtonEnabledStore = writable(true);

export const resourcesSelectedStore = writable(false);

export const isProjectStore = writable<boolean>();


let previousSubjobsStoreValue : CheckedSubjob[] = [];
let previousCheckedCategoriesStoreValue: CheckedSubjob[] = [];

function arraysAreEqual(a: Array<number>, b: Array<number>) {
    return a.length === b.length && a.every((val, index) => val === b[index]);
}

checkedSubjobsStore.subscribe(value => {
    if (!arraysAreEqual(value, previousSubjobsStoreValue)) {
		[Views.VIEW_CATEGORIES, Views.VIEW_RECIPIENT, Views.VIEW_REVIEW].forEach(view => {
			resetStores(view)
		})
    }

    previousSubjobsStoreValue = value.slice();
});

checkedCategoriesStore.subscribe(value => {
    if (!arraysAreEqual(value, previousCheckedCategoriesStoreValue)) {
		[Views.VIEW_RECIPIENT, Views.VIEW_REVIEW].forEach(view => {
			resetStores(view)
		})
    }

    previousCheckedCategoriesStoreValue = value.slice();
});



