<script>
    export let span;
    export let name;
    export let checked = false;
    export let className = undefined;
</script>

<label class="radio {className}" data-testid="gebucht">
    <input on:click type="radio" name={name} checked={checked}/>
    <div>
        <div class="mark" role="presentation" />
        <span>{span}</span>
    </div>
</label>