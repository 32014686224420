import { displayAlertInfo } from "./Event/TenderEmail/stores/stores";
import { displayServiceProviderQuantityStore} from "./Event/Staffplan/stores/stores";

const translations = window.__CREW_BRAIN__.translations;

// AlertInfo Component
function printAlertInfoSvelteComponent() {
    (window.__CREW_BRAIN__RENDER__ = window.__CREW_BRAIN__RENDER__ || []).push({
        component: 'AlertInfo',
        target: 'main',
		props: { 
			header: `${translations.js_hinweis}`,
			content: `${translations.tenderSent}`,
			footer: "Ok",
		}
    });
};
  
displayAlertInfo.subscribe(value => {
    if (value) {
        printAlertInfoSvelteComponent();
    }
});


// ServiceProviderQuantity Component
function printServiceProviderQuantityComponent() {
    (window.__CREW_BRAIN__RENDER__ = window.__CREW_BRAIN__RENDER__ || []).push({
        component: 'ServiceProviderQuantity',
        target: 'main',
        props: { 
			content: `${translations.howManyWorkers}`,
            close: `${translations.cancel}`,
            save: `${translations.save}`,
		}
    });
};



displayServiceProviderQuantityStore.subscribe(value => {
    if (value) {
        printServiceProviderQuantityComponent();
    } 
});
