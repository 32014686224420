import debounce from 'debounce';
import { writable } from 'svelte/store';
import listen from '../../../utils/listen';
import { EmployeeType } from '../types';

type EmployeeOrVehicle = {
	id: number;
	type: EmployeeType;
	free: boolean;
	name: string;
	employeePhone: string;
	vehicleSeats: string;
	vehiclePayload: string;
	roomNumberPeople: string;
	isOverlapping: boolean;
};

type Options = {
	target?: Element;
	enter?(): any;
	leave?(): any;
	drop?(event: DragEvent): any;
	destroy?(): any;
};

type Subscriber = (employeeOrVehicle: EmployeeOrVehicle) => Options | false;

const subscribers: Subscriber[] = [];

const { set: setHasSubscribers, subscribe } = writable(false);
export const hasSubscribers = { subscribe };

const onDragEmployee = (callback: Subscriber) => {
	if (!subscribers.includes(callback)) {
		if (subscribers.push(callback) === 1) {
			setHasSubscribers(true);
		}
	}

	return () => {
		const index = subscribers.indexOf(callback);
		if (index !== -1) {
			subscribers.splice(index, 1);

			if (!subscribers.length) {
				setHasSubscribers(false);
			}
		}
	};
};

export default onDragEmployee;

export const onDragStart = (event: DragEvent & { target: Element }) => {
	const { target } = event;
	const element = target && target.closest && target.closest('.result');
	if (!element) {
		return;
	}

	const employeeOrVehicle: EmployeeOrVehicle = {
		id: Number(target.getAttribute('data-ressourceid')),
		type: target.getAttribute('data-ressourcetype') as EmployeeType,
		free: target.getAttribute('data-ressourcefree') === 'true',
		name: target.getAttribute('data-ressourcename'),
		employeePhone: target.getAttribute('data-ressourceemployeephone'),
		vehicleSeats: target.getAttribute('data-ressourcevehicleseats'),
		vehiclePayload: target.getAttribute('data-ressourcevehiclepayload'),
		roomNumberPeople: target.getAttribute('data-ressourceroomnumberpeople'),
		isOverlapping: target.getAttribute('data-ressourceoverlapping') === 'true',
	};

	const instances = subscribers.map(subscriber => subscriber(employeeOrVehicle)).filter(Boolean) as Options[];
	if (!instances.length) {
		return;
	}

	const terminate = debounce(() => {
		if (listeners) {
			for (const listener of listeners) {
				listener();
			}
		}

		if (instances) {
			for (const { destroy } of instances) {
				destroy && destroy();
			}
		}
	}, 120);

	let current: Options | undefined;
	const listeners = [
		listen(document, 'dragenter', event => {
			event.preventDefault();

			if (event.target) {
				for (const instance of instances) {
					if (instance.target?.contains(event.target as Node)) {
						if (!current || current.target !== instance.target) {
							if (current && current.leave) {
								current.leave();
							}

							current = instance;
							instance.enter && instance.enter();
						}

						if (event.dataTransfer) {
							event.dataTransfer.dropEffect = 'copy';
						}

						return;
					}
				}
			}

			if (current && current.leave) {
				current.leave();
			}

			current = undefined;
			if (event.dataTransfer) {
				event.dataTransfer.dropEffect = 'none';
			}
		}),

		listen(document, 'dragleave', terminate, true),

		listen(
			document,
			'drop',
			event => {
				event.preventDefault();

				if (current && current.drop) {
					current.drop(event);
				}
				terminate();
				terminate.flush();
			},
			true
		),

		listen(
			document,
			'dragover',
			event => {
				event.preventDefault();

				terminate.clear();

				if (event.dataTransfer) {
					event.dataTransfer.dropEffect = current ? 'copy' : 'none';
				}
			},
			true
		),
	];
};
