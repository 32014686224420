<script lang="ts">
	import createFocusTrap from 'focus-trap';
	import jquery from 'jquery';
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import cx from '../../../utils/class-names';
	import listen from '../../../utils/listen';

	let className: string = undefined;
	export { className as class };
	export let dark: boolean = false;
	export let trapFocus: boolean = true;
	export let closeOnEscape: boolean = true;
	export let closeOnOutsideClick: boolean = false;
	export let onClose: () => any = undefined;

	let container: HTMLDivElement;

	onMount(() => {
		jquery(container).topZIndex();

		const destroyers: (() => any)[] = [];

		if (trapFocus) {
			const focusTrap = createFocusTrap(container, {
				escapeDeactivates: false,
			});

			focusTrap.activate();
			destroyers.push(() => {
				focusTrap.deactivate();
			});
		}

		if (closeOnEscape) {
			destroyers.push(
				listen(
					document,
					'keydown',
					event => {
						if (event.key === 'Escape') {
							event.stopPropagation();
							event.stopImmediatePropagation();
							onClose && onClose();
						}
					},
					true
				)
			);
		}

		if (closeOnOutsideClick) {
			destroyers.push(
				listen(container, 'click', event => {
					if (onClose && event.target === container) {
						onClose();
					}
				})
			);
		}

		return () => {
			for (const destroyer of destroyers) {
				destroyer();
			}
		};
	});
</script>

<div bind:this={container} class={cx('ajax-content', className)} class:dark transition:fade|global={{ duration: 200 }}>
	<div class="hinweis">
		<div class="hinweis__surface">
			<slot />
		</div>
	</div>
</div>
