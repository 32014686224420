<script lang="ts">
    import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
    export let icon;
    export let className;
    export let hoverTitle;
    export let checked: boolean = false;
    export let disabled: boolean = false;
    export let fontAwesomeIconAvailable: boolean = true;
</script>

<label class="checkbox checkbox--icon" data-hovertitle={hoverTitle}>
    <input type="checkbox" on:click on:change bind:checked={checked} {disabled}>
    <div>
        <div class="mark" role="presentation">
            <svg viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </svg>
        </div>

        {#if fontAwesomeIconAvailable}
            <span>
                <FontAwesomeIcon icon={icon} class={className}/>
            </span>
        {:else}
            <span class={className}>
                {@html icon}
            </span>
        {/if}
    </div>
</label>
